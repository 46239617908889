import * as React from 'react';
import {
    ShowBase,
    TextField,
    ReferenceField,
    ReferenceManyField,
    ReferenceArrayField,
    SingleFieldList,
    ChipField,
    useShowContext,
    EditButton,
    Datagrid,
    DateField,
    SimpleList,
    Pagination,
    RecordContextProvider
} from 'react-admin';
import { Box, Card, CardContent, Typography, Link, List, ListItem, ListItemText, ListItemIcon, IconButton } from '@mui/material';
import '../crm.css';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import UpdateIcon from '@mui/icons-material/Update';
import DeleteIcon from '@mui/icons-material/Delete';
import { stages } from '../deals/stages';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'; // Import circle icons\
import { endpoint } from '../App';
import { Link as RouterLink } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

const VillageShow = () => (
    <ShowBase>
        <VillageShowContent />
    </ShowBase>
);

const VillageShowContent = () => {
    const { record, isLoading } = useShowContext();
    const navigate = useNavigate();
    if (isLoading || !record) return null;

    const getStatusColor = (stageId) => {
        switch (stageId) {
            case 'sale':
                return 'green'; // Green circle for 'sale'
            case 'reserved':
            case 'dealing':
                return 'yellow'; // Yellow circle for 'reserved' or 'dealing'
            case 'sold':
                return 'red'; // Red circle for 'sold'
            default:
                return 'grey'; // Default color for unknown status
        }
    };

    return (
        <Box mt={2} display="flex">
            <Box flex="1">
                 { localStorage.getItem('role_id') <= 2 ? <EditButton label="Редактировать поселок" /> : null}
                <Card>
                    <CardContent>

                        <Box display="flex">
                            <Box flex="1">
                                <Box display="flex" flexDirection="row">
                                    <IconButton
                                        onClick={() => navigate(-1)}
                                        size="small"
                                        >
                                        <KeyboardBackspaceIcon />
                                    </IconButton>
                                    <Typography variant="h5">
                                        {record.name}
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>

                         {record.id ? <Box display="flex" style={{alignItems: 'center'}} flex="1" mt={2} >
                            <Box>
                                <Typography variant="body2">id поселка (для интеграций):</Typography>
                            </Box>
                            <Box ml={1}>
								<Typography variant="body1">{record.id}</Typography>
                            </Box>
                         </Box> : null}

                         {record.total_size ? <Box display="flex" style={{alignItems: 'center'}} flex="1" mt={2} >
                            <Box>
                                <Typography variant="body2">Общая площадь:</Typography>
                            </Box>
                            <Box ml={1}>
								<Typography variant="body1">{record.total_size}</Typography>
                            </Box>
                         </Box> : null}

						 {record.cadastre ? <Box display="flex" style={{alignItems: 'center'}} flex="1" mt={2} >
                            <Box>
                                <Typography variant="body2">Кадастровые данные:</Typography>
                            </Box>
                            <Box ml={1}>
								<Typography variant="body1">{record.cadastre}</Typography>
                            </Box>
                         </Box> : null}

						 {record.address ? <Box display="flex" style={{alignItems: 'center'}} flex="1" mt={2} >
                            <Box>
                                 <Typography variant="body2">Адрес:</Typography>
                            </Box>
                            <Box ml={1}>
								<Typography variant="body1">{record.address}</Typography>
                            </Box>
                         </Box> : null}

                         {record.description ? <Box display="flex" style={{alignItems: 'center'}} flex="1" mt={2} >
                            <Box>
                                 <Typography variant="body2">Описание:</Typography>
                            </Box>
                            <Box ml={1}>
								<Typography variant="body1">{record.description}</Typography>
                            </Box>
                         </Box> : null}

                         {record.number ? <Box display="flex" style={{alignItems: 'center'}} flex="1" mt={2} >
                            <Box>
                                 <Typography variant="body2">Номер (для документов):</Typography>
                            </Box>
                            <Box ml={1}>
								<Typography variant="body1">{record.number}</Typography>
                            </Box>
                         </Box> : null}

                         {record.category ? <Box display="flex" style={{alignItems: 'center'}} flex="1" mt={2} >
                            <Box>
                                 <Typography variant="body2">Категория земель:</Typography>
                            </Box>
                            <Box ml={1}>
								<Typography variant="body1">{record.category}</Typography>
                            </Box>
                         </Box> : null}

                         {record.legal_use ? <Box display="flex" style={{alignItems: 'center'}} flex="1" mt={2} >
                            <Box>
                                 <Typography variant="body2">Разрешенное использование:</Typography>
                            </Box>
                            <Box ml={1}>
								<Typography variant="body1">{record.legal_use}</Typography>
                            </Box>
                         </Box> : null}

                         {record.property ? <Box display="flex" style={{alignItems: 'center'}} flex="1" mt={2} >
                            <Box>
                                 <Typography variant="body2">Собственность на основании:</Typography>
                            </Box>
                            <Box ml={1}>
								<Typography variant="body1">{record.property}</Typography>
                            </Box>
                         </Box> : null}

                         {record.file_path ? 
                            <Box display="flex" flexDirection="column">
                                <h5>Изображение генплана поселка:</h5>
                                <img src={`${endpoint}/${record.file_path}`} style={{width: '20rem'}} />
                            </Box> 
                         : null}

                        <h3>Участки</h3>

                        <List>
                        {record.estates.map(estate => (
                                <RecordContextProvider key={estate.number} value={estate}>
                                    <ListItem 
                                    button 
                                    component={RouterLink}
                                    to={estate.deal_id ? `/deals/${estate.deal_id}` : `/deals/create?village_id=${record.id}&estate_number=${estate.number}`}>
                                    <Box display="flex" flex={1} justifyContent="start" alignItems="center" sx={{marginRight: '1rem'}}>
                                    <ListItemIcon>
                                        <FiberManualRecordIcon style={{ color: getStatusColor(estate.stage_id) }} />
                                    </ListItemIcon>
                                        <ListItemText
                                            primary={`№${estate.number}`}
                                            secondary={`Площадь: ${estate.size} кв. м.`}
                                        />
                                        </Box>
                                        <Box display="flex" flex={1} justifyContent="start" alignItems="center" sx={{marginRight: '1rem'}}>
                                        <ListItemText
                                            primary={`Статус: ${stages.find(s => s.id === estate.stage_id).name }`}
                                            secondary={`Цена: ${estate.price} руб.`}
                                        />
                                        </Box>
                                        <Box display="flex" flex={1} justifyContent="start" alignItems="center" sx={{marginRight: '1rem'}}>
                                        {estate.deal_id ? 
                                        <ListItemText
                                        primary={<Link href={`${endpoint}/sales/${estate.manager_account_id}`}>{estate.manager_name}</Link>}
                                        />
                                        : null}
                                        </Box>
                                    </ListItem>
                                </RecordContextProvider>
                            ))}
                        </List>

                         {/* localStorage.getItem('role_id') <= 2 ? <Box mt={2}>
                            <Typography variant="h6">История действий</Typography>
                            <ReferenceManyField
                                label="Logs"
                                reference="logs"
                                target="village_id"
                                sort={{ field: 'date_time', order: 'DESC' }}
                                pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100, 1000]} />}
                                perPage={10}
                            >
                                <SimpleList
                                    primaryText={(log) => log.history ? log.history : `${log.action} ${log.table}`}
                                    secondaryText={(log) => new Date(log.date_time).toLocaleString()}
                                    leftIcon={(log) => {
                                        if (log.action === 'Create') {
                                            return <AddCircleIcon style={{ color: 'green' }} />;
                                        } else if (log.action === 'Update') {
                                            return <UpdateIcon style={{ color: '#FF8100' }} />;
                                        } else if (log.action === 'Delete') {
                                            return <DeleteIcon style={{ color: 'red' }} />;
                                        } else {
                                            return null;
                                        }
                                    }}
                                    linkType="show"
                                />
                            </ReferenceManyField>
                        </Box> : null*/}
                           

                    </CardContent>
                </Card>
            </Box>
        </Box>
    );
};

export default VillageShow;