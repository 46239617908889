import * as React from 'react';
import { CreateBase, Form, Toolbar } from 'react-admin';
import { Card, CardContent, Box, Avatar } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import { TaskTypeInputs } from './TaskTypeInputs';

const TaskTypeCreate = () => (
    <CreateBase
        redirect="list"
        transform={(data) => ({
            ...data,
            updated_at: new Date(),
            ap_id: localStorage.getItem('id'),
            ap_name: localStorage.getItem('first_name') + " " + localStorage.getItem('last_name'),
            id: data.id || uuidv4().slice(0, 18),
        })}
    >
        <Box mt={2} display="flex">
            <Box flex="1" mb={10}>
                <Form>
                    <Card>
                        <CardContent>
                            <Box>
                                <Box display="flex">
                                    <TaskTypeInputs />
                                </Box>
                            </Box>
                        </CardContent>
                        <Toolbar />
                    </Card>
                </Form>
            </Box>
        </Box>
    </CreateBase>
);

export default TaskTypeCreate;
