import * as React from 'react';
import { useEffect, useState } from 'react';
import {
    EditBase,
    Form,
    Toolbar,
    useEditContext,
    useDataProvider,
    ShowButton,
    DeleteButton,
    SaveButton,
    useRedirect,
    ReferenceManyField,
    useRecordContext
} from 'react-admin';
import { Card, CardContent, Box, Button, Tab, Tabs, Divider, Typography, useMediaQuery } from '@mui/material';

import { Avatar } from '../contacts/Avatar';
import { RequestInputs } from './RequestInputs';
import '../crm.css';
import { HistoryIterator } from '../requestsHistory/HistoryIterator';
import { NewHistoryComment } from '../requestsHistory/NewHistoryComment';
import { CallsWithContact } from '../calls/CallsWithContact';

const RequestEdit = () => {
    const dataProvider = useDataProvider();
    const redirect = useRedirect();
    
    const [currentContact, setCurrentContact] = useState();
    const handleContactChange = (value) => {
        setCurrentContact(value);
    };

    const [currentManager, setCurrentManager] = useState();
    const handleManagerChange = (value) => {
        setCurrentManager(value);
    };

    const handleDelete = async (id) => {
        // Fetch the existing record to get the data before delete
        const existingRecord = await dataProvider.getOne('requests', { id });

        // Modify the data or perform additional actions
        const modifiedData = {
            ...existingRecord.data,
             ap_id: localStorage.getItem('id'),
             ap_name: localStorage.getItem('first_name') + " " + localStorage.getItem('last_name'),
             table: 'requests'
        };

        // Use dataProvider to send the delete request with modified data
        await dataProvider.create('delete', { data: modifiedData });

        // You can also perform additional actions after delete if needed
        //console.log(`Deleted record with ID: ${id}`);
        redirect(`/requests`);
    };

    return (
        <Box display="flex" flexDirection="column" >
        <EditBase redirect="list" 
             mutationMode="optimistic"
             transform={(data) => ({
                ...data,
                ap_id: localStorage.getItem('id'),
                ap_name: localStorage.getItem('first_name') + " " + localStorage.getItem('last_name'),
                manager_name: `${currentManager.first_name} ${currentManager.last_name}`,
                manager_account_id: currentManager.id,
            })}
        >
            <RequestEditContent onDelete={handleDelete} handleManagerChange={handleManagerChange} />
        </EditBase>

        
        </Box>
    );
}

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (children)}
      </div>
    );
}


const RequestEditContent = ({ onDelete, handleManagerChange }) => {
    const { isLoading, record } = useEditContext();
    const [value, setValue] = useState(0);
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    if (isLoading || !record) return null;

    return (
        <Box display="flex" flexDirection={isSmall ? "column" : "row"} sx={{ overflowY: 'hidden',}}>
            <Box flex="1">
                <Form>
                    <Card>
                        <CardContent>
                            <Box>
                                <Box display="flex">
                                    <RequestInputs onManagerChange={handleManagerChange} />
                                </Box>
                            </Box>
                        </CardContent>
                        <CustomToolbar onDelete={() => onDelete(record.id)}/>
                    </Card>
                </Form>
            </Box>
            <Box flex="2" display="flex" flexDirection="column">
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                    <Tab label="Комментарии и задачи" />
                    <Tab label="Звонки" />
                </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
            <Box flex="1" sx={{display: 'flex', flexDirection: 'column-reverse', paddingLeft: '1rem', height: '85vh', }}>
                {/*<Card sx={{maxHeight: '55%', overflowY: 'auto', display: 'flex', flexDirection: 'column-reverse',}}>
                    <CardContent>*/}
                {/*<NewHistoryComment reference="requests" />*/}
                    <ReferenceManyField
                     target="request_id"
                     reference="requestsHistory"
                     perPage={10000}
                     sort={{ field: 'created_at', order: 'DESC' }}
                            sx={{ overflowY: 'auto', display: 'flex', flexDirection: 'column-reverse',}}
                    >
                        <NewHistoryComment reference="requests" />
                        <HistoryIterator reference="requests" />
                    </ReferenceManyField>
                   {/* </CardContent>
                </Card>*/}
            </Box>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
                <CallsWithContact contact_id={record.contact_id} />
            </CustomTabPanel>
            </Box>
        </Box>
    );
};

const CustomToolbar = ({ onDelete }) => {
    return (
        <Toolbar>
            <SaveButton />
            <Box width={20} component="span" />
            {localStorage.getItem('role_id') < 2 ? <Button style={{ color: 'red' }} onClick={onDelete} >Удалить</Button> : null}
        </Toolbar>
    );
};

export default RequestEdit;