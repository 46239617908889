import * as React from 'react';
import { useState } from 'react';
import {
    TextInput,
    ReferenceInput,
    ReferenceArrayInput,
    AutocompleteInput,
    AutocompleteArrayInput,
    BooleanInput,
    SelectInput, 
    useInput, required, ImageInput, ImageField,
    useRecordContext,
    useListContext,
    NumberInput,
    SimpleFormIterator,
    ArrayInput,
    useEditContext,
} from 'react-admin';
import { Divider, Box, Grid, Typography  } from '@mui/material';
import { stages } from '../deals/stages';
import { endpoint } from '../App';

export const VillageInputs = () => {
    const editContext = useEditContext();

    return (<Box flex="1" mt={-1}>

        <h3>Данные о поселке</h3>

        <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
                <TextInput label="Название поселка" source="name" fullWidth validate={required()} />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextInput label="Категория земель" source="category" fullWidth />
            </Grid>
        </Grid>

        <TextInput label="Информация о поселке" source="description" fullWidth multiline />
        
        <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
                <TextInput label="Адрес поселка" source="address" fullWidth multiline />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextInput label="Кадастровый номер (1 или несколько)" source="cadastre" fullWidth multiline />
            </Grid>
        </Grid>

        <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
                <TextInput label="Общая площадь (с указанием единицы измерения)" source="total_size" fullWidth />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextInput label="Номер поселка (отобразится в номере при формировании документов)" source="number" fullWidth validate={required()} />
            </Grid>
        </Grid>

        <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
                <TextInput label="Разрешенное использование" source="legal_use" fullWidth />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextInput label="Собственность на основании" source="property" fullWidth helperText="Пример: Договора №123 от 12.03.2024" />
            </Grid>
        </Grid>

        <ImageInput source="file" label={editContext.record && editContext.record.file_path ? "Загрузите новое изображение, если хотите поменять" : "Изображение генплана (для документов)"} accept="image/*">
            <ImageField source="src" title="title" />
        </ImageInput>

        {editContext.record && editContext.record.file_path ? 
            <Box display="flex" flexDirection="column">
                <h5>Текущее изображение генплана поселка:</h5>
                <img src={`${endpoint}/${editContext.record.file_path}`} style={{width: '20rem'}} />
            </Box> 
        : null}

        {!editContext.record ? 
        <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
                <NumberInput label="Кол-во участков" source="amount" fullWidth validate={required()}/>
            </Grid>
            <Grid item xs={12} sm={4}>
                <NumberInput label="Площадь участка, кв.м." source="estates_size" fullWidth validate={required()}/>
            </Grid>
            <Grid item xs={12} sm={4}>
                <NumberInput label="Стоимость участка, руб." source="estates_price" fullWidth validate={required()}/>
            </Grid>
        </Grid> : null}

        <Divider />

        <h3>Участки</h3>
        
        {!editContext.record ? 
        <Typography variant="body1">Участки создадутся автоматически, их будет можно редактировать прямо здесь, сразу после создания поселка.</Typography> :
        <ArrayInput label="Участки" source="estates">
            <SimpleFormIterator inline disableClear disableReordering>
                <NumberInput label="Номер участка" source="number" validate={required()} />
                <NumberInput label="Площадь участка, кв.м." source="size" validate={required()} />
                <NumberInput label="Стоимость участка, руб." source="price" validate={required()} />
                 <SelectInput
			         label="Статус участка"
			         source="stage_id"
			         choices={stages}
                     defaultValue={stages[0].id}
                     validate={required()} 
                 />
            </SimpleFormIterator>
         </ArrayInput>}
          
     
    </Box>);
};
