import React, { useState } from 'react';
import { Button, FormControlLabel, FormGroup, Switch, TextField, Card, CardContent, Box, IconButton} from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import {
    useGetIdentity, 
    useRefresh,
    CustomRoutes, 
    defaultTheme, 
    nanoLightTheme, 
    nanoDarkTheme,
    radiantLightTheme, 
    radiantDarkTheme,
    houseLightTheme, 
    houseDarkTheme,
    SelectInput ,
    required,
    SimpleForm,
    useTheme
  } from 'react-admin';
  import { ColorField, ColorInput } from 'react-admin-color-picker';
  import { themes } from './themes';

const ThemeSettings = () => {
    const [primaryColor, setPrimaryColor] = useState('#003801');
    const [secondaryColor, setSecondaryColor] = useState('#FF8100');
    const [theme, setTheme] = useTheme();

    const handlePrimaryColorChange = (event) => {
        setPrimaryColor(event.target.value);
    };

    const handleSecondaryColorChange = (event) => {
        setSecondaryColor(event.target.value);
    };

    const handleThemeChange = (event) => {
        //console.log(event.theme);
        localStorage.setItem('theme', event.theme);
        //localStorage.setItem('theme_primary_color', event.primary_color);
        //localStorage.setItem('theme_secondary_color', event.secondary_color);
        setTheme(themes[event.theme].lightTheme);
        if(theme === 'light'){
            setTheme('dark');
            setTheme('light');
        }else{
            setTheme('light');
            setTheme('dark');
        }
        window.location.reload();
        /*setTheme({
            ...themes[event.theme].lightTheme,
            palette: {
                primary: {
                    main: event.primary_color,
                },
                secondary: {
                    main: event.secondary_color,
                },
            },
        });*/
    };

    return (
        <Card>
            <CardContent>
            <Box display="flex" flexDirection="column">
            <Box display="flex" flexDirection="row">
			<h2>Настройки темы</h2>
            </Box>
            <SimpleForm onSubmit={handleThemeChange}>
            <SelectInput 
            label="Тема" 
            optionText="name" 
            source="theme" 
            defaultValue={localStorage.getItem('theme')}
            choices={themes}
            validate={required()}
            />
           
            {/* <ColorInput label="Основной цвет" source="primary_color" picker="Sketch" defaultValue="#003801" validate={required()} /> <ColorInput label="Дополнительный цвет" source="secondary_color" picker="Sketch" defaultValue="#FF8100" validate={required()} /><TextField
                label="Primary Color"
                type="color"
                value={primaryColor}
                onChange={handlePrimaryColorChange}
            />
            <TextField
                label="Secondary Color"
                type="color"
                value={secondaryColor}
                onChange={handleSecondaryColorChange}
    />*/}
            </SimpleForm>
            </Box>
            </CardContent>
        </Card>
    );
};

export default ThemeSettings;