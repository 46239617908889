import * as React from 'react';
import { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import {
    List as RaList,
    SimpleListLoading,
    ReferenceField,
    TextField,
    EditButton,
    ShowButton,
    useListContext,
    ExportButton,
    SortButton,
    TopToolbar,
    CreateButton,
    Pagination,
    useGetIdentity,
    BulkActionsToolbar,
    BulkDeleteButton,
    BulkExportButton,
    RecordContextProvider,
    BooleanField,
    DateField,
    BooleanInput,
    Datagrid,
    ImageField,
    FilterLiveSearch,
    Form,
    SelectInput,
    required,
    Loading,
} from 'react-admin';
import {
    List,
    ListItem,
    ListItemAvatar,
    ListItemIcon,
    ListItemSecondaryAction,
    ListItemText,
    Checkbox,
    Typography,
    Box,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { formatDistance } from 'date-fns';
import { ru } from 'date-fns/locale';
import { Avatar } from './Avatar';
import { Status } from '../misc/Status';
import { TagsList } from '../tags/TagsList';
import { ContactListFilter } from './ContactListFilter';
import { Contact } from '../types';
import '../crm.css';
import Button from '@mui/material/Button';
import * as XLSX from 'xlsx';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { OnlyMineInput } from '../requests/OnlyMineInput';

// Custom export button component
const CustomExportButton = ({ resource, ...props }) => {
    const {
        data: clients,
        isLoading,
        onToggleItem,
        selectedIds,
    } = useListContext();

  const handleClick = () => {

const reorderedDataToExport = clients.map((client) => {
    const reorderedAccount = { ...client };

    // Handle array of objects (dop_contacts)
    if (client.dop_contacts && Array.isArray(client.dop_contacts)) {
        reorderedAccount.dop_contacts = client.dop_contacts.map((contact) => {
            return Object.keys(contact)
                .map((key) => (Array.isArray(contact[key]) ? contact[key].join(', ') : contact[key]))
                .join(',');
        }).join('\n');
    }

    // Flatten the rest of the object
    Object.keys(reorderedAccount).forEach((field) => {
        if (Array.isArray(reorderedAccount[field])) {
            reorderedAccount[field] = reorderedAccount[field].join(', ');
        }
    });

    return reorderedAccount;
});

    const ws = XLSX.utils.json_to_sheet(reorderedDataToExport);

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Лист 1');
    XLSX.writeFile(wb, "Клиенты - Выгрузка из Владелец CRM.xlsx");
  };

  return (
    <Button onClick={handleClick} label="Выгрузить в Excel" variant="outlined" size="small" color="success" startIcon={<FileUploadIcon />}>
      Выгрузить в Excel
    </Button>
  );
};

const ContactListContent = forwardRef((props, ref) => {
    const { setSort } = useListContext();
    const setSort1 = (field1, order1) => {
        setSort({ field: field1, order: order1 });
    };

    // Expose the setSort function using useImperativeHandle
    useImperativeHandle(ref, () => ({
        setSort1: (field1, order1) => setSort1(field1, order1),
    }));

    const {
        data: contacts,
        isLoading, isFetching,
        onToggleItem,
        selectedIds,
    } = useListContext();
    if (isLoading || isFetching) {
        return <Loading />
    }
    /*if (isLoading) {
        return <SimpleListLoading hasLeftAvatarOrIcon hasSecondaryText />;
    }*/
    const now = Date.now();

    return (
        <>
            {/*<BulkActionsToolbar>
                <BulkDeleteButton />
            </BulkActionsToolbar>*/}
            <List>
                {contacts.map(contact => (
                    <RecordContextProvider key={contact.id} value={contact}>
                        <ListItem
                            button
                            component={ localStorage.getItem('role_id') <= 3 || contact.manager_account_id == localStorage.getItem('id') ? Link : null}
                            to={`/contacts/${contact.id}`}
                        >
                          {/*  <ListItemIcon>
                                <Checkbox
                                    edge="start"
                                    checked={selectedIds.includes(contact.id)}
                                    tabIndex={-1}
                                    disableRipple
                                    onClick={e => {
                                        e.stopPropagation();
                                        onToggleItem(contact.id);
                                    }}
                                />
                            </ListItemIcon>
                            <ListItemAvatar>
                                <Avatar />
                            </ListItemAvatar> */}
                            <Box display="flex" flex={1} justifyContent="start" alignItems="center" sx={{marginRight: '1rem'}}>
                            <ListItemText
                                primary={contact.name}
                                secondary={`${contact.phone ? contact.phone : ''} ${contact.email ? contact.email : ''}`}
                                /*secondary={
                                    <>
                                        {contact.title && contact.title + ','} {' '}
                                        <ReferenceField
                                            source="company_id"
                                            reference="companies"
                                            link={false}
                                        >
                                        <TextField source="name" />
                                        </ReferenceField>
                                        {contact.nb_notes > 0 &&
                                            `${', '}заметок: ${contact.nb_notes} `}
                                        <TagsList />
                                    </>
                                }*/
                            />
                            </Box>
                            <Box display="flex" flex={1} justifyContent="start" alignItems="center" sx={{marginRight: '1rem'}}>
                            <ListItemText
                                primary={contact.manager_name}
                             />
                             </Box>
                             <Box display="flex" flex={1} justifyContent="start" alignItems="center" sx={{marginRight: '1rem'}}>
                            <ListItemText
                                secondary={`Обновлен: ${new Date(contact.updated_at).toLocaleString('ru-RU')}`}
                             />
                             </Box>
                            {/*<ReferenceField source="manager_account_id" reference="sales" link="show" >
                                <TextField source="first_name" />{' '}<TextField source="last_name" />
                            </ReferenceField>*/}
                            {/*<ListItemSecondaryAction class="adaptive-1" >
                                <Typography
                                    variant="body2"
                                    color="textSecondary"
                                    ml={2} 
                                >
                                    последнее действие{' '}
                                    {formatDistance(
                                        new Date(contact.last_seen),
                                        now,  { locale: ru }
                                    )}{' '}
                                    назад <Status status={contact.status} />
                                </Typography>
                            </ListItemSecondaryAction>*/}
                        </ListItem>
                    </RecordContextProvider>
                ))}
            </List>
        </>
    );
});

const ContactListActions = ({filters, currentFilter, handleFilterChange}) => (
    <TopToolbar sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
        <Form className='filterForm'>
            <SelectInput label="Отображение" source="view" choices={filters} defaultValue={currentFilter.id} onChange={handleFilterChange} validate={required()} helperText={null} />
        </Form>  
        {localStorage.getItem('role_id') < 2 ? <CustomExportButton /> : null}
        {/*<CreateButton
            variant="outlined"
            label="Новый клиент"
            sx={{ marginLeft: 2 }}
        />*/}
    </TopToolbar>
);

const ContactList = () => {
    const { identity } = useGetIdentity();
    const filters = [
        {id: 1, name: 'По дате (от новых к старым)', order: 'DESC', field: 'updated_at'}, 
        {id: 2, name: 'По дате (от старых к новым)', order: 'ASC', field: 'updated_at'},
    ];
    const [currentFilter, setCurrentFilter] = useState(filters[0]);
    const listContentRef = useRef();

    const handleFilterChange = (value) => {
        setCurrentFilter(filters.find((item) => item.id === value.target.value));
        if (listContentRef.current) {
            listContentRef.current.setSort1(filters.find((item) => item.id === value.target.value).field, filters.find((item) => item.id === value.target.value).order);
        }
    };

    return identity ? (
        <RaList
            actions={<ContactListActions filters={filters} currentFilter={currentFilter} handleFilterChange={handleFilterChange} />}
            sort={{ field: 'updated_at', order: 'DESC' }}
            filters={localStorage.getItem('role_id') <= 3 ? [<FilterLiveSearch alwaysOn />, <OnlyMineInput alwaysOn />] :  [<FilterLiveSearch alwaysOn />]}
            //filter={{'sales_id_for_requests': localStorage.getItem('id')}} 
            //aside={<ContactListFilter className="adaptive-1" />}
            perPage={25}
            pagination={<Pagination rowsPerPageOptions={[25, 50, 100, 500, 1000]} />}
        >
            <ContactListContent ref={listContentRef} />
        </RaList>
    ) : null;
};

export default ContactList;
