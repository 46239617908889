import * as React from 'react';
import { useState, useEffect, forwardRef, useImperativeHandle, useRef} from 'react';
import {
    useRecordContext,
    useListContext,
    useCreate,
    useUpdate,
    useNotify,
    useGetIdentity,
    Identifier,
    useResourceContext,
    FileInput, FileField,
    Form,
    CreateParams,
    UpdateParams,
    DataProvider,
    fetchUtils,
    CreateBase,
    SaveButton,
    SimpleForm,
    useCreateContext,
    Toolbar,
    TextInput,
    SelectInput,
    required, 
    DateTimeInput,
    DateInput,
    NumberInput,
    ReferenceInput,
    AutocompleteInput,
    useDataProvider,
    useRedirect,
    useRefresh,
} from 'react-admin';
import { Box, TextField, Button, Grid, Typography  } from '@mui/material';
import { types } from './types';
import { StatusSelector } from '../misc/StatusSelector';
import {useFormContext} from "react-hook-form";

export const NewDocument = ({reference}) => {
    const resource = useResourceContext();
    const record = useRecordContext();
    const { refetch } = useListContext();
    const notify = useNotify();
    const { identity } = useGetIdentity();
    const dataProvider = useDataProvider();
    const redirect = useRedirect();
    const [selectedDocumentType, setSelectedDocumentType] = useState(types[0].id);
    const [selectedManager, setSelectedManager] = useState(localStorage.getItem('id'));
    const ref = useRef();

    const handleDocumentTypeChange = (event) => {
        setSelectedDocumentType(event.target.value);
    };

    if (!record || !identity) return null;

    return (
        <Box>
            <CreateBase resource="documents"
                redirect={false}
                mutationMode="optimistic"
                transform={(data) => {
                        return ({
                            ...data,
                            ap_id: localStorage.getItem('id'),
                            ap_name: localStorage.getItem('first_name') + " " + localStorage.getItem('last_name'),
                            deal_id: record.id, 
                            deal_number: record.number,
                            created_at: new Date()
                        });
                }}
                mutationOptions={{
                    onSuccess: () => {
                        try{
                           refetch();
                           notify('Документ создан', { type: 'info', undoable: false });
                           ref.current.resetForm();
                           //window.location.reload();
                        }catch(err){console.log(err)}
                    }}
                }
            > 
            <Form>
            <DocumentForm 
                selectedDocumentType={selectedDocumentType} 
                handleDocumentTypeChange={handleDocumentTypeChange} 
                ref={ref}
            />
            </Form>
            </CreateBase>
        </Box>
    );
};

const DocumentForm = forwardRef(({ selectedDocumentType, handleDocumentTypeChange }, ref) => {
    const { reset, setValue } = useFormContext();

    useImperativeHandle(ref, () => ({
        resetForm() {
            //reset();
            setValue('document_number', '');
            setValue('date', '');
        }
    }));

    return (
        <Box>
            {selectedDocumentType == "comment" ?
            <TextInput
                label="Текст комментария"
                fullWidth
                source="comment"
                multiline
                validate={required()}
            /> : 
            <TextInput
            label="Номер договора (сформируется автоматически если не вводить)"
            fullWidth
            source="document_number"
            />
            }
            {selectedDocumentType == "invoice" || selectedDocumentType == "invoice_luba" ? 
                <NumberInput
                label="Сумма текущего платежа (руб.)"
                fullWidth
                source="current_payment"
                validate={required()}
                />
            : null}
            {selectedDocumentType !== "comment" ?
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <Box flex="1" flexDirection="column">
                        <DateInput label="Дата документа" source="date" validate={required()} />
                    </Box> 
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Box flex="1" flexDirection="column">
                        <SelectInput
                            label="Тип документа"
                            source="document_type"
                            choices={types}
                            defaultValue={types[0].id}
                            validate={required()}
                            fullWidth 
                            onChange={handleDocumentTypeChange}
                        />
                    </Box>
                </Grid>
            </Grid> : 
             <SelectInput
             label="Тип документа"
             source="document_type"
             choices={types}
             defaultValue={types[0].id}
             validate={required()}
             fullWidth 
             onChange={handleDocumentTypeChange}
            />
            }
            <Box display="flex">
                <SaveButton 
                    label={selectedDocumentType !== "comment" ? "Создать документ" : "Добавить комментарий"}
                    icon={false}
                    redirect={false} 
                    submitOnEnter={false}
                />
            </Box>     
        </Box>
    );
});