export const typeChoices = [
    {id: 'cold', name: 'Холодный'},
    {id: 'warm', name: 'Теплый'},
   {id: 'hot', name: 'Горячий'},
];

export const getStatusColor = (status) => {
        switch (status) {
            case 'cold':
                return '#0ca2c0';
            case 'warm':
                return '#FF8100';
            case 'hot':
                return 'red'; 
            default:
                return 'grey'; 
        }
};
