import * as React from 'react';
import { useState, useEffect } from 'react';
import { CreateBase, Form, Toolbar, useDataProvider, } from 'react-admin';
import { Card, CardContent, Box, Avatar } from '@mui/material';

import { TaskInputs } from './TaskInputs';

const TaskCreate = () => {
    const dataProvider = useDataProvider();
    const [selectedManager, setSelectedManager] = useState(localStorage.getItem('id'));

     useEffect(() => {
        handleManagerChange(localStorage.getItem('id'));
    }, []); 

	const handleManagerChange = async(id) => {
		if(id){
			var recordFromDatabase = await dataProvider.getOne('sales', { id });
			setSelectedManager(recordFromDatabase.data);
		}else{
			setSelectedManager({});
		}
	};
    
    return (
    <CreateBase
        redirect="list"
        mutationMode="optimistic"
        transform={(data) => ({
          ...data,
           ap_id: localStorage.getItem('id'),
           ap_name: localStorage.getItem('first_name') + " " + localStorage.getItem('last_name'),
           //request_id: record.id, 
           //request_number: record.number,
           created_at: new Date(),
           task_done: false,
           manager_name: selectedManager.first_name + " " + selectedManager.last_name,
           task_manager_id: selectedManager.id,
           comment_type: 'task',
           //contact_name: record.name,
        })}
    >
        <Box mt={2} display="flex">
            <Box flex="1" mb={10}>
                <Form>
                    <Card>
                        <CardContent>
                            <Box>
                                <Box display="flex">
                                    <TaskInputs selectedManager={selectedManager} handleManagerChange={handleManagerChange} />
                                </Box>
                            </Box>
                        </CardContent>
                        <Toolbar />
                    </Card>
                </Form>
            </Box>
        </Box>
    </CreateBase>
);
}

export default TaskCreate;
