/*import React, { useState, useEffect } from 'react';
import {
    Create,
    SimpleForm,
    TextInput,
    SelectInput,
    NumberInput,
    ReferenceInput,
    AutocompleteInput,
    required,
    useRedirect,
    useDataProvider,
    DateTimeInput,
    useGetList,
    CreateBase, Form, Toolbar,
    useNotify
} from 'react-admin';
import { endpoint, wsEndpoint } from '../App';
import {
    List,
    ListItem,
    ListItemAvatar,
    ListItemIcon,
    ListItemSecondaryAction,
    ListItemText,
    Checkbox,
    Typography,
    Box,
    Icon,
    Paper
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';
import { useSound } from 'use-sound';
import DeleteIcon from '@mui/icons-material/Delete';

const LunchButtons = () => {  
const [lunchInfo, setLunchInfo] = useState();

useEffect(() => {
    fetchData();
    const interval = setInterval(() => {
        fetchData();
    }, 30000);

    return () => clearInterval(interval);
  }, []);

const fetchData = async () => {
    try {
      const token = localStorage.getItem('token'); // Get token from localStorage
      const id = localStorage.getItem('id'); // Get id from localStorage
      const response = await axios.get(
        `${endpoint}/sales/${id}`,
        {
          headers: {
            Authorization: `${token}` // Set Authorization header with token
          },
        }
      );
      // Check if the response data is different from the current lunch info
      const isNewLunchInfo = JSON.stringify(response.data) !== JSON.stringify(lunchInfo) && response.data.length > 0;
      console.log(new Date().toISOString() + " " + JSON.stringify(response.data));
      // Update lunch info only if there are new one
      if (isNewLunchInfo) {
          setLunchInfo(response.data);
      }
    } catch (error) {
      console.error('Fetch failed:', error);
    }
};

const startLunch = async () => {
    try {
        const token = localStorage.getItem('token'); // Get token from localStorage
        const id = localStorage.getItem('id'); // Get id from localStorage
        const response = await axios.put(
            `${endpoint}/sales/${id}`,
            { lunch: true, lunch_start_time: new Date().toISOString() }, // Set lunch to true and set up start time of it
            {
                headers: {
                    Authorization: `${token}` // Set Authorization header with token
                },
            }
        );
        console.log('Lunch status updated:', response.data);
        
        //setLunchInfo(response.data);
    } catch (error) {
        console.error('Error updating lunch status:', error);
    }
};

const endLunch = async () => {
    try {
        const token = localStorage.getItem('token'); // Get token from localStorage
        const id = localStorage.getItem('id'); // Get id from localStorage
        const response = await axios.put(
            `${endpoint}/sales/${id}`,
            { lunch: false, lunch_start_time: '' }, // Set lunch to false and clear time of it
            {
                headers: {
                    Authorization: `${token}` // Set Authorization header with token
                },
            }
        );
        console.log('Lunch status updated:', response.data);
        
        //setLunchInfo(response.data);
    } catch (error) {
        console.error('Error updating lunch status:', error);
    }
};


  return (
  null
  );

};

export default LunchButtons;*/

import React, { useState, useEffect } from 'react';
import {
    Box,
    Button,
    Typography,
    Paper
} from '@mui/material';
import axios from 'axios';
import { endpoint } from '../App'; // Adjust the import according to your project structure

const LunchButtons = () => {  
    const [lunchInfo, setLunchInfo] = useState({});
    const [remainingTime, setRemainingTime] = useState(null);
    const [canStartLunch, setCanStartLunch] = useState(false);

    useEffect(() => {
        localStorage.setItem('lunch_timer_works', 'no');
        fetchData();
        const interval = setInterval(() => {
            fetchData();
        }, 30000);

        return () => {
            clearInterval(interval);
        };
    }, []);

    const fetchData = async () => {
        try {
            const token = localStorage.getItem('token'); // Get token from localStorage
            const id = localStorage.getItem('id'); // Get id from localStorage
            const response = await axios.get(
                `${endpoint}/sales/${id}`,
                {
                    headers: {
                        Authorization: `${token}` // Set Authorization header with token
                    },
                }
            );

            if (response.data) {
                const lunchStartTime = response.data.lunch_start_time ? new Date(response.data.lunch_start_time) : null;

                const today = new Date().toISOString().split('T')[0]; // Get current date in YYYY-MM-DD format
                const lunchStartDate = lunchStartTime ? new Date(lunchStartTime).toISOString().split('T')[0] : null;

                if (lunchStartDate === today) {
                    setCanStartLunch(false); // Disable the button if lunch has already started today
                } else {
                     // Check if today is a working day and it's time to start lunch
                     const dayOfWeek = new Date().getDay(); // 0 (Sunday) to 6 (Saturday)
                     const workingDay = response.data[`${getDayName(dayOfWeek)}_start_time`] !== null && response.data[`${getDayName(dayOfWeek)}`]; // Check if start_time exists for today's day
 
                     if (workingDay) {
                         const currentTime = new Date();
                         const startTime = new Date(response.data[`${getDayName(dayOfWeek)}_start_time`]);
                         const endTime = new Date(response.data[`${getDayName(dayOfWeek)}_end_time`]);
 
                         // Check if current time is within working hours and less than 2 hours before end time
                         const withinWorkingHours = currentTime >= startTime && currentTime <= endTime;
                         const lessThanTwoHoursBeforeEnd = endTime - currentTime < 2 * 60 * 60 * 1000; // 2 hours in milliseconds
 
                         if (withinWorkingHours && !lessThanTwoHoursBeforeEnd && response.data.connectedToLeads) {
                             setCanStartLunch(true); // Enable the button to start lunch
                         } else {
                             setCanStartLunch(false); // Disable the button if conditions are not met
                         }
                     } else {
                         setCanStartLunch(false); // Disable the button if today is not a working day
                     }
                }

                const currentTime = new Date();
                const elapsedTime = currentTime - lunchStartTime;
                const remaining = 3600000 - elapsedTime; // 1 hour in milliseconds

                if (remaining > 0) {
                    setRemainingTime(remaining);
                    if (localStorage.getItem('lunch_timer_works') == 'no') {
                        console.log('timer not working!!!')
                        startTimer(remaining);
                        localStorage.setItem('lunch_timer_works', 'yes');
                    }
                }
            }
            
            setLunchInfo(response.data);
        } catch (error) {
            console.error('Fetch failed:', error);
        }
    };

    const startLunch = async () => {
        try {
            const token = localStorage.getItem('token'); // Get token from localStorage
            const id = localStorage.getItem('id'); // Get id from localStorage
            const response = await axios.put(
                `${endpoint}/sales/${id}`,
                { lunch: true, lunch_start_time: new Date().toISOString() }, // Set lunch to true and set up start time of it
                {
                    headers: {
                        Authorization: `${token}` // Set Authorization header with token
                    },
                }
            );

            setLunchInfo(response.data);
            const remaining = 3600000; // 1 hour in milliseconds
            setRemainingTime(remaining);
            startTimer(remaining);

            console.log('Lunch status updated:', response.data);
        } catch (error) {
            console.error('Error updating lunch status:', error);
        }
    };

    const endLunch = async () => {
        try {
            const token = localStorage.getItem('token'); // Get token from localStorage
            const id = localStorage.getItem('id'); // Get id from localStorage

            const twoHoursAgo = new Date();
            twoHoursAgo.setHours(twoHoursAgo.getHours() - 2);

            const response = await axios.put(
                `${endpoint}/sales/${id}`,
                { lunch: false, lunch_start_time: twoHoursAgo.toISOString() }, // Set lunch to false and lunch_start_time more than hour ago
                {
                    headers: {
                        Authorization: `${token}` // Set Authorization header with token
                    },
                }
            );

            setLunchInfo(response.data);
            setRemainingTime(null);
            setCanStartLunch(false);

            console.log('Lunch status updated:', response.data);
        } catch (error) {
            console.error('Error updating lunch status:', error);
        }
    };

    const startTimer = (remaining) => {
        const id = setInterval(() => {
            setRemainingTime(prev => {
                if (prev <= 1000) {
                    clearInterval(id);
                    endLunch(); // Auto end lunch if time exceeded
                    return null;
                }
                return prev - 1000;
            });
        }, 1000);
    };

    const formatTime = (milliseconds) => {
        const totalSeconds = Math.floor(milliseconds / 1000);
        const minutes = Math.floor(totalSeconds / 60);
        const seconds = totalSeconds % 60;
        return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    };

    // Function to get day name from index
    const getDayName = (dayIndex) => {
        const days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
        return days[dayIndex];
    };

    return (
        <Box position="fixed" bottom="50px" right="50px" zIndex="999999">
            {remainingTime !== null ? (
                <Paper sx={{ padding: '1rem', marginBottom: '1rem' }}>
                    <Typography variant="h6">
                        Перерыв на обед закончится через: {formatTime(remainingTime)}
                    </Typography>
                    <Button variant="contained" color="secondary" onClick={endLunch}>
                        Завершить обед
                    </Button>
                </Paper>
            ) : (
                canStartLunch && (<Button variant="contained" color="primary" onClick={startLunch}>
                    Уйти на обед (1 час)
                </Button>)
            )}
        </Box>
    );
};

export default LunchButtons;
