import * as React from 'react';
import { AppBar, TitlePortal, UserMenu, useUserMenu, Logout, useRedirect, useTheme, LocalesMenuButton, RefreshIconButton } from 'react-admin';
import { MenuItem, ListItemIcon, ListItemText, Button } from '@mui/material';
import Box from '@mui/material/Box';
import Logo from '../resources/logo.png';
import LogoDark from '../resources/logo_dark_theme.png';
import Logo1 from '../resources/logo1.png';
import LogoDark1 from '../resources/logo_dark_theme1.png';
import SettingsIcon from '@mui/icons-material/Settings';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import DisplaySettingsIcon from '@mui/icons-material/DisplaySettings';
import EditNoteIcon from '@mui/icons-material/EditNote';
import MoneyOffIcon from '@mui/icons-material/MoneyOff';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import { themes } from './themes';

const MyAppBar = () => {
    const [theme, setTheme] = useTheme();

    return (
    <AppBar
        userMenu={
            <UserMenu>
                { localStorage.getItem('role_id') <= 2 ? <SettingsMenuItem /> : null}
                { localStorage.getItem('role_id') <= 3 ? <GroupEditMenuItem /> : null}
                { localStorage.getItem('role_id') <= 2 ? <SanctionsMenuItem /> : null}
                <NotificationsMenuItem />
                <DarkLightModeMenuItem />
                <ThemeSettingsMenuItem />
                <Logout />
            </UserMenu>
        }
        toolbar={
            <>
                <RefreshIconButton />
            </>
        }
        color='default'
        >
        <TitlePortal />
        <Box flex={1} /> 
        <div>
            <img src={theme === 'light' ? Logo : LogoDark} alt="CRM Logo" style={{ height: '28px', marginTop: '10px'}} />
        </div> 
        <Box flex={2} /> 
    </AppBar>
    )
};

const SettingsMenuItem = React.forwardRef((props, ref) => {
    const { onClose } = useUserMenu();
    const redirect = useRedirect();

    const handleClick = () => {
      redirect('/settings');
      onClose();
    };

    return (
        <MenuItem
            onClick={handleClick}
            ref={ref}
            {...props}
        >
            <ListItemIcon>
                <SettingsIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Настройки</ListItemText>
        </MenuItem>
    );
});

const GroupEditMenuItem = React.forwardRef((props, ref) => {
    const { onClose } = useUserMenu();
    const redirect = useRedirect();

    const handleClick = () => {
      redirect('/request_group_edit');
      onClose();
    };

    return (
        <MenuItem
            onClick={handleClick}
            ref={ref}
            {...props}
        >
            <ListItemIcon>
                <EditNoteIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Групповое редактирование заявок</ListItemText>
        </MenuItem>
    );
});

const SanctionsMenuItem = React.forwardRef((props, ref) => {
    const { onClose } = useUserMenu();
    const redirect = useRedirect();

    const handleClick = () => {
      redirect('/sanctions');
      onClose();
    };

    return (
        <MenuItem
            onClick={handleClick}
            ref={ref}
            {...props}
        >
            <ListItemIcon>
                <ThumbDownIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Штрафы и санкции</ListItemText>
        </MenuItem>
    );
});

const NotificationsMenuItem = React.forwardRef((props, ref) => {
    const { onClose } = useUserMenu();
    const redirect = useRedirect();

    const handleClick = () => {
      redirect('/notifications');
      onClose();
    };

    return (
        <MenuItem
            onClick={handleClick}
            ref={ref}
            {...props}
        >
            <ListItemIcon>
                <NotificationsActiveIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Уведомления</ListItemText>
        </MenuItem>
    );
});

const DarkLightModeMenuItem = React.forwardRef((props, ref) => {
    const { onClose } = useUserMenu();
    const redirect = useRedirect();
    const [theme, setTheme] = useTheme();

    const handleClick = () => {
      setTheme(theme === 'dark' ? 'light' : 'dark');
    };

    return (
        <MenuItem
            onClick={handleClick}
            ref={ref}
            {...props}
        >
            <ListItemIcon>
                {theme === 'dark' ?
                <Brightness7Icon fontSize="small" /> :
                <Brightness4Icon fontSize="small" />
                }
            </ListItemIcon>
            <ListItemText>{theme === 'dark' ? 'В светлый режим' : 'В темный режим'}</ListItemText>
        </MenuItem>
    );
});

const ThemeSettingsMenuItem = React.forwardRef((props, ref) => {
    const { onClose } = useUserMenu();
    const redirect = useRedirect();

    const handleClick = () => {
      redirect('/theme_settings');
      onClose();
    };

    return (
        <MenuItem
            onClick={handleClick}
            ref={ref}
            {...props}
        >
            <ListItemIcon>
                <DisplaySettingsIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Настройки темы</ListItemText>
        </MenuItem>
    );
});

export default MyAppBar;