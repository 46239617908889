import * as React from 'react';
import {
    TextField,
    DateField,
    FunctionField,
    ReferenceField,
    EditButton,
    ShowButton,
    useRecordContext,
} from 'react-admin';
import { Box, Typography, Divider, Link } from '@mui/material';

import { Company, Sale } from '../types';
import '../crm.css';

interface CompanyAsideProps {
    link?: string;
}

export const CompanyAside = ({ link = 'edit', className }: CompanyAsideProps) => {
    const record = useRecordContext();
    if (!record) return null;

    const encodedAddress = encodeURIComponent(record.city + ' ' + record.address);

    return (
        <Box ml={4} /*width={250}*/ minWidth={250} style={{margin: '1em', maxWidth: '450px'}} class={className}>
            <Box textAlign="center" mb={2}>
                {link === 'edit' ? (
                    <EditButton label="Редактировать компанию" />
                ) : (
                    <ShowButton label="Показать компанию" />
                )}
            </Box>

            <Typography variant="subtitle2">Информация о компании</Typography>
            <Divider />

            <Box mt={2}>
                <Typography variant="body2">
                    Веб-сайт: <Link href={record.website} target="_blank">{record.website}</Link>
                </Typography>
            </Box>

            <Box mt={1}>
                <Typography variant="body2">
                   Соцсеть: <Link href={record.social} target="_blank">{record.social}</Link>
                </Typography>
            </Box>

            <Box mt={1}>
                <Typography variant="body2">
                    Номер телефона: <Link href={"tel:" + record.phone_number}>{record.phone_number}</Link>
                </Typography>
            </Box>

            <Box mt={1}>
                <Typography variant="body2">
                   Реквизиты: <br />{record.requisites}
                </Typography>
            </Box>

            <Box mt={1} mb={3}>
                <Typography variant="body2">
                    Адрес:{' '} 
                <Link href={`https://yandex.com/maps/?text=${encodedAddress}`} target="_blank">{record.address}</Link>
                <br />
                <TextField source="city" /> <TextField source="zipcode" />
                </Typography>
            </Box>

            {/*<Typography variant="subtitle2">Background</Typography>
            <Divider />

            <Box mt={1}>
                <Typography
                    variant="body2"
                    color="textSecondary"
                    component="span"
                >
                    Added on
                </Typography>{' '}
                <DateField
                    source="created_at"
                    options={{ year: 'numeric', month: 'long', day: 'numeric' }}
                    color="textSecondary"
                />
                <br />
                <Typography
                    component="span"
                    variant="body2"
                    color="textSecondary"
                >
                    Followed by
                </Typography>{' '}
                <ReferenceField source="sales_id" reference="sales">
                    <FunctionField
                        source="last_name"
                        render={record =>
                            `${record.first_name} ${record.last_name}`
                        }
                    />
                </ReferenceField>
            </Box>*/}
        </Box>
    );
};
