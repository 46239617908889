import React from 'react';
import {useEffect, useRef, useState} from 'react';
import { Menu, useDataProvider } from 'react-admin';
import { Link } from 'react-router-dom';
import BookIcon from '@mui/icons-material/Book';
import FunnelIcon from '@mui/icons-material/FilterAlt';
import AddLinkIcon from '@mui/icons-material/AddLink';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import { Box, Card, CardContent, Typography, IconButton } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { endpoint } from '../App';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

const MyComponent = () => {
    const viewer = useRef(null);
    const location = useLocation();
    const dataProvider = useDataProvider();
    const [currentDocument, setCurrentDocument] = useState();

    useEffect(() => {
      const queryParams = new URLSearchParams(location.search);
      //const estateId = queryParams.get('estate_id');
      const docPath = queryParams.get('doc_path');
      
      if(docPath){
         setCurrentDocument(`${endpoint}/${docPath}`);
      }
  
      console.log('Doc Path:', docPath);
      //console.log('Village ID:', villageId);
    }, [location.search]);
  
    /*useEffect(() => {
      WebViewer(
        {
          path: '/webviewer/lib',
          enableOfficeEditing: true,
          licenseKey: 'demo:1709340790341:7f29cbcb0300000000cc526e176fd078ad8b45e1b5fc2f19f6b97af64a',
          initialDoc: currentDocument,
        },
        viewer.current,
      ).then((instance) => {
          const { documentViewer } = instance.Core;
          // you can now call WebViewer APIs here...
        });
    }, []);*/
  
    return (
      <div className="MyComponent">
        <div className="webviewer" ref={viewer} style={{height: "100vh"}}></div>
      </div>
    );
  };

const DocumentEditor = () => {
  const navigate = useNavigate();

  return (
    <Box style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}>
      <Card>
      <CardContent>
      <Box display="flex" flexDirection="row">
			<IconButton
				  onClick={() => navigate(-1)}
				  size="small"
				>
				  <KeyboardBackspaceIcon style={{ color: '#444' }} />
			</IconButton>
			<h2>Редактирование документа</h2>
      </Box>
      <MyComponent />
      </CardContent>
      </Card>
    </Box>
  );
};

export default DocumentEditor;