import * as React from 'react';
import { useState, useEffect } from 'react';
import {
	ReferenceField,
    TextInput,
    ReferenceInput,
    ReferenceArrayInput,
    AutocompleteInput,
    AutocompleteArrayInput,
    BooleanInput,
    SelectInput, 
    useInput, required, ImageInput, ImageField,
    useRecordContext,
    useListContext,
	TextField,
	useDataProvider,
	useRedirect,
	useEditContext,
    NumberInput,
    SelectArrayInput ,
	ArrayInput,
	SimpleFormIterator,
	DateInput,
} from 'react-admin';
import { Divider, Box, Grid, Link, Typography, Button, IconButton  } from '@mui/material';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import EditIcon from '@mui/icons-material/Edit';
import { stages } from './stages';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { useNavigate, useLocation } from 'react-router-dom';
import { CallButton} from '../calls/CallButton';

export const DealInputs = ({ onRequestChange }) => {
	const editContext = useEditContext();
	const dataProvider = useDataProvider();
	const redirect = useRedirect();
	const navigate = useNavigate();
	const [selectedRequest, setSelectedRequest] = useState();
	const [selectedVillage, setSelectedVillage] = useState();
	const [preSelectedVillage, setPreselectedVillage] = useState();
    const [estatesPriceSum, setEstatesPriceSum] = useState(0);
    const [estatesSizeSum, setEstatesSizeSum] = useState(0);
	const [discount, setDiscount] = useState(0);
    const [totalPrice, setTotalPrice] = useState(0);
	const paymentTypes = [{id: 'deferred', name: 'Рассрочка'}, /*{id: 'reserve_and_agreement', name: 'Бронь и договор (новые шаблоны)'},*/ {id: 'full', name: 'Оплата 100%'}];
	const [currentPaymentType, setCurrentPaymentType] = useState(paymentTypes[0]);
	/*const location = useLocation();

	useEffect(() => {
		const queryParams = new URLSearchParams(location.search);
		//const estateId = queryParams.get('estate_id');
		const presetVillageId = queryParams.get('village_id');
		if(presetVillageId){
			handleVillageChange(presetVillageId);
			setPreselectedVillage(presetVillageId);
		}

		//console.log('Estate ID:', estateId);
		//console.log('Village ID:', villageId);
	}, [location.search]);*/

	const handlePaymentTypeChange = (event) => {
		setCurrentPaymentType(paymentTypes.find((item) => item.id === event.target.value));
    };

	const handleDiscountChange = (event) => {
		setDiscount(event.target.value * 0.01);
		setTotalPrice(estatesPriceSum - (estatesPriceSum * event.target.value * 0.01));
    };


	const handleRequestChange = async(id) => {
		if(id){
			var recordFromDatabase = await dataProvider.getOne('requests', { id });
			setSelectedRequest(recordFromDatabase.data);
			onRequestChange(recordFromDatabase.data);
		}else{
			setSelectedRequest({});
		}
	};

	const handleVillageChange = async(id) => {
		setEstatesPriceSum(0);
		setEstatesSizeSum(0);
		setTotalPrice(0);
		if(id){
			var recordFromDatabase = await dataProvider.getOne('villages', { id });
			setSelectedVillage(recordFromDatabase.data);
		}else{
			setSelectedVillage({});
		}
	};

	const handleEstatesChange = (event) => {
		calculateEstatesSums(event.target.value);
	}

    const calculateEstatesSums = (estates_ids) => {
		if(estates_ids.length < 1){
			setEstatesPriceSum(0);
			setEstatesSizeSum(0);
			setTotalPrice(0);
		}else{
			if (selectedVillage && selectedVillage.estates) {
				const selectedEstates = selectedVillage.estates.filter(estate => estates_ids.includes(estate.number));
				const priceSum = selectedEstates.reduce((acc, estate) => acc + estate.price, 0);
				const sizeSum = selectedEstates.reduce((acc, estate) => acc + estate.size, 0);
				setEstatesPriceSum(priceSum);
				setEstatesSizeSum(sizeSum);
				setTotalPrice(priceSum - (priceSum * discount));
			}
		}
    };

	useEffect(() => {
        const initialRequest = editContext && editContext.record ? editContext.record.request_id : null;
        handleRequestChange(initialRequest);
		const initialVillage = editContext && editContext.record ? editContext.record.village_id : null;
        handleVillageChange(initialVillage);
		if (editContext.record && editContext.record.estates_ids) {
            calculateEstatesSums(editContext.record.estates_ids);
        }
		if (editContext.record){
			setCurrentPaymentType(paymentTypes.find((item) => item.id === editContext.record.payment_type));
		}
    }, [editContext.record]); 


    return (<Box flex="1" mt={-1}>

        <Box display="flex" flexDirection="row">
			<IconButton
				  onClick={() => navigate(-1)}
				  size="small"
				>
				  <KeyboardBackspaceIcon />
			</IconButton>
			{ editContext.record?.number ? <h3>Сделка №{editContext.record?.document_name ? editContext.record?.document_name : editContext.record?.number}</h3> : <h3>Новая сделка</h3>}
		</Box>

		<Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
			  <Box display="flex" alignItems="center" justifyContent="start">
                { !selectedRequest?.name ? <Box mr={2} mb={2}>
				<IconButton
				  onClick={() => redirect('/requests/create')}
				  color="primary"
				  size="small"
				>
				  <AddShoppingCartIcon />
				</IconButton>
				</Box> : <Box mr={2} mb={2}>
				<IconButton
				  onClick={() => redirect(`/requests/${selectedRequest.id}`)}
				  color="primary"
				  size="small"
				>
				  <EditIcon />
				</IconButton>
				</Box>}
                { !editContext.record ? 
				<ReferenceInput source="request_id" reference="requests" filter={{ 'sales_id_for_requests': localStorage.getItem('id') }}>
                    <AutocompleteInput isLoading={true} label="Заявка (лид) (введите часть имени и подождите 5 секунд)" filterToQuery={search => ({ "q": search })}  optionText={(choice) => `Заявка от ${choice.name} - ${choice.manager_name}`} fullWidth onChange={handleRequestChange} validate={required()} />
                </ReferenceInput> : 
				<ReferenceField source="request_id" reference="requests">
					Заявка от 
					<TextField source="name" ml={1}/>
				</ReferenceField>
				}
				</Box>
            </Grid>
            <Grid item xs={12} sm={6}>
				<ReferenceInput source="contragent_id" reference="contragents">
                    <AutocompleteInput label="Контрагент" optionText={(choice) => `${choice.name}`} fullWidth validate={required()} />
                </ReferenceInput>
                 
            </Grid>
        </Grid>

        <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Box display="flex" alignItems="center" justifyContent="start" mt={1} mb={1}>
			    <Typography variant="body2">Менеджер:</Typography>
			    <Typography variant="body1" ml={2}>{selectedRequest?.manager_name || ''}</Typography>
              </Box>
            </Grid>
	        <Grid item xs={12} sm={6}>
              <Box display="flex" alignItems="center" justifyContent="start" mt={1} mb={1}>
			    <Typography variant="body2">Клиент:</Typography>
			    <Typography variant="body1" ml={2}>{selectedRequest?.name || ''}</Typography>
				{ editContext.record && <CallButton contact_phone={selectedRequest?.phone} />}
              </Box>
            </Grid>
        </Grid>

		<Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
				<ReferenceInput source="village_id" reference="villages">
                    <AutocompleteInput label="Поселок" optionText="name" fullWidth onChange={handleVillageChange} validate={required()} />
                </ReferenceInput>
            </Grid>
            <Grid item xs={12} sm={6}>
            {selectedVillage?.estates ? <SelectArrayInput
                        source="estates_ids"
                        label="Участки (1 или несколько)"
                        choices={editContext.record ? 
							selectedVillage.estates.filter(est => est.stage_id === 'sale' || est.stage_id === 'cancelled' || est.deal_id === editContext.record.id) 
						: selectedVillage.estates.filter(est => est.stage_id === 'sale' || est.stage_id === 'cancelled')}
                        optionValue="number"
                        optionText={(choice) => `№${choice.number} - ${stages.find(s => s.id === choice.stage_id).name }`}
						onChange={handleEstatesChange}
                        fullWidth
                        validate={required()}
            /> : null}
            </Grid>
        </Grid>

		{ !editContext.record ?
        <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Box display="flex" alignItems="center" justifyContent="start" mt={1} mb={1}>
			    <Typography variant="body2">Суммарная стоимость участков:</Typography>
			    <Typography variant="body1" ml={2}>{estatesPriceSum} руб.</Typography>
              </Box>
            </Grid>
	        <Grid item xs={12} sm={6}>
              <Box display="flex" alignItems="center" justifyContent="start" mt={1} mb={1}>
			    <Typography variant="body2">Суммарная площадь:</Typography>
			    <Typography variant="body1" ml={2}>{estatesSizeSum} кв.м.</Typography>
              </Box>
            </Grid>
        </Grid> : null}

		<SelectInput
			         label="Статус сделки"
			         source="stage_id"
			         choices={stages}
                     defaultValue={stages[0].id}
                     validate={required()} 
			         fullWidth 
        />

        <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
                <NumberInput label="Сумма сделки, руб." source="deal_price" fullWidth validate={required()}/>
            </Grid>
            <Grid item xs={12} sm={6}>
				 <NumberInput label="Суммарная площадь сделки, кв.м." source="total_size" fullWidth validate={required()}/>
            </Grid>
        </Grid>

		{ !editContext.record ?
		<Box display="flex" alignItems="center" justifyContent="start" mb={1}>
			    <Typography variant="body2">Итоговая сумма сделки (ввести верную в поле сверху):</Typography>
			    <Typography variant="body1" ml={2}>{totalPrice} руб.</Typography>
        </Box> : null}

		<Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
				<SelectInput label="Способ оплаты" source="payment_type" fullWidth choices={paymentTypes} defaultValue={currentPaymentType.id} onChange={handlePaymentTypeChange} validate={required()} />
            </Grid>
            <Grid item xs={12} sm={6}>
				<NumberInput label="Скидка, %" source="discount_percent" fullWidth onChange={handleDiscountChange} />
            </Grid>
        </Grid>

		
                
		{ currentPaymentType.id === 'deferred' ?
		<ArrayInput label="Платежи по рассрочке" source="payments">
            <SimpleFormIterator inline disableClear disableReordering>
                <NumberInput label="Сумма платежа, руб." source="amount" validate={required()} />
                <DateInput label="Дата платежа" source="date" validate={required()} />
            </SimpleFormIterator>
         </ArrayInput> : null } 

		 { currentPaymentType.id === 'reserve_and_agreement' ?
		<ArrayInput label="Платежи (1 - бронь (сумма и срок), 2 - оплата по ДКП (сумма и срок))" source="payments" helperText="1-ый платеж - бронь, 2-ой платеж - оплата по ДКП">
            <SimpleFormIterator inline disableClear disableReordering>
                <NumberInput label="Сумма платежа, руб." source="amount" validate={required()} />
                <DateInput label="Дата платежа" source="date" validate={required()} />
            </SimpleFormIterator>
         </ArrayInput> : null }

		<Divider />

		{ editContext.record?.created_at ?  
			<Typography variant="body1" gutterBottom>Дата создания: {new Date(editContext.record.created_at).toLocaleString('ru-RU')}</Typography> : null}

		{ editContext.record?.updated_at ?  
			<Typography variant="body1" gutterBottom>Дата обновления: {new Date(editContext.record.updated_at).toLocaleString('ru-RU')}</Typography> : null}

    </Box>);
};
