export const sectors = [
    'Компания',
	'Производство',
    'Пекарня',
    'Кондитерское производство',
    'Хлебопекарное производство',
    'Ресторан',
    'Кофейня',
    'Кафе',
    'Кафе-пекарня',
].map(sector => ({ id: sector, name: sector }));
