import * as React from 'react';
import {
    List as RaList,
    SimpleListLoading,
    ReferenceField,
    TextField,
    EditButton,
    ShowButton,
    useListContext,
    ExportButton,
    SortButton,
    TopToolbar,
    CreateButton,
    Pagination,
    useGetIdentity,
    BulkActionsToolbar,
    BulkDeleteButton,
    BulkExportButton,
    RecordContextProvider,
    BooleanField,
    DateField,
    BooleanInput,
    Datagrid,
    ImageField,
    FilterLiveSearch,
} from 'react-admin';
import {
    List,
    ListItem,
    ListItemAvatar,
    ListItemIcon,
    ListItemSecondaryAction,
    ListItemText,
    Checkbox,
    Typography,
    Box,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { formatDistance } from 'date-fns';
import { ru } from 'date-fns/locale';
import '../crm.css';
import Button from '@mui/material/Button';
import * as XLSX from 'xlsx';

const ContragentListContent = () => {
    const {
        data: contragents,
        isLoading,
        onToggleItem,
        selectedIds,
    } = useListContext();
    if (isLoading) {
        return <SimpleListLoading hasLeftAvatarOrIcon hasSecondaryText />;
    }
    const now = Date.now();

    return (
        <>
            {/*<BulkActionsToolbar>
                <BulkDeleteButton />
            </BulkActionsToolbar>*/}
            <List>
                {contragents.map(contragent => (
                    <RecordContextProvider key={contragent.id} value={contragent}>
                        <ListItem
                            button
                            component={ localStorage.getItem('role_id') <= 3 ? Link : null}
                            to={`/contragents/${contragent.id}`}
                        >
                            <ListItemText
                                primary={contragent.name}
                                secondary={`${contragent.phone ? contragent.phone : ''} ${contragent.email ? contragent.email : ''}`}
                            />
                             {localStorage.getItem('role_id') <= 2 ?  <EditButton /> : null}
                        </ListItem>
                    </RecordContextProvider>
                ))}
            </List>
        </>
    );
};

const ContragentListActions = () => (
    <TopToolbar>
        <CreateButton
            variant="contained"
            label="Новый контрагент"
            sx={{ marginLeft: 2 }}
        />
    </TopToolbar>
);

const ContragentList = () => {
    const { identity } = useGetIdentity();
    return identity ? (
        <RaList
            actions={ localStorage.getItem('role_id') <= 2 ? <ContragentListActions /> : null }
            filters={[<FilterLiveSearch alwaysOn />]}
            //aside={<ContactListFilter className="adaptive-1" />}
            perPage={25}
            pagination={<Pagination rowsPerPageOptions={[25, 50, 100, 500, 1000]} />}
            //filterDefaultValues={{ sales_id: identity?.id }}
            sort={{ field: 'updated_at', order: 'DESC' }}
        >
            <ContragentListContent />
        </RaList>
    ) : null;
};

export default ContragentList;
