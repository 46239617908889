import {
    defaultTheme, 
    nanoLightTheme, 
    nanoDarkTheme,
    radiantLightTheme, 
    radiantDarkTheme,
    houseLightTheme, 
    houseDarkTheme,
  } from 'react-admin';

export const themes = [
    {id: 0, name: "Стандартная", lightTheme: defaultTheme, darkTheme: { ...defaultTheme, palette: { mode: 'dark' } }},
    {id: 1, name: "Минимализм", lightTheme: nanoLightTheme, darkTheme: nanoDarkTheme},
    {id: 2, name: "Современная", lightTheme: radiantLightTheme, darkTheme: radiantDarkTheme},
    {id: 3, name: "Яркая", lightTheme: houseLightTheme, darkTheme: houseDarkTheme}
]