import * as React from 'react';
import { Box } from '@mui/material';
import { useListContext, Loading } from 'react-admin';

import { HistoryComment } from './HistoryComment';
import { NewHistoryComment } from './NewHistoryComment';

export const HistoryIterator = ({reference}) => {
    const { data, isLoading } = useListContext();
    if (isLoading) return null; // психологически быстрее выглядит без <Loading />
    return (
        <> 
            <Box sx={{
                overflowY: 'auto',
                display: 'flex',
                flexDirection: 'column-reverse', // Reverse direction
            }}>
                {data && data.map((note) => (
                    <HistoryComment
                        note={note}
                        reference={reference}
                        key={note.id}
                    />
                ))}
            </Box>
        </>
    );
};
