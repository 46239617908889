import * as React from 'react';
import { useEffect, useState } from 'react';
import {
    EditBase,
    Form,
    Toolbar,
    useEditContext,
    useDataProvider,
    ShowButton,
    DeleteButton,
    SaveButton,
    useRedirect,
    ReferenceField,
    TextField,
    Datagrid
} from 'react-admin';
import { Card, CardContent, Box, Button } from '@mui/material';

import { Avatar } from './Avatar';
import { ContactInputs } from './ContactInputs';
import { ContactAside } from './ContactAside';
import { Contact } from '../types';
import '../crm.css';

const ContactEdit = () => {
    const dataProvider = useDataProvider();
    const redirect = useRedirect();
   
    const [currentManager, setCurrentManager] = useState();
    const handleManagerChange = (value) => {
        setCurrentManager(value);
    };

    const handleDelete = async (id) => {
        // Fetch the existing record to get the data before delete
        const existingRecord = await dataProvider.getOne('contacts', { id });

        // Modify the data or perform additional actions
        const modifiedData = {
            ...existingRecord.data,
             ap_id: localStorage.getItem('id'),
             ap_name: localStorage.getItem('first_name') + " " + localStorage.getItem('last_name'),
             table: 'contacts'
        };

        // Use dataProvider to send the delete request with modified data
        await dataProvider.create('delete', { data: modifiedData });

        // You can also perform additional actions after delete if needed
        //console.log(`Deleted record with ID: ${id}`);
        redirect(`/contacts`);
    };
  return (
    <EditBase redirect="edit" 
             mutationMode="optimistic"
             transform={(data) => ({
                ...data,
                ap_id: localStorage.getItem('id'),
                ap_name: localStorage.getItem('first_name') + " " + localStorage.getItem('last_name'),
                manager_name: `${currentManager.first_name} ${currentManager.last_name}`,
                manager_account_id: currentManager.id,
            })}
    >
        <ContactEditContent onDelete={handleDelete} handleManagerChange={handleManagerChange} />
    </EditBase>
  );
}

const ContactEditContent = ({ onDelete, handleManagerChange }) => {
    const { isLoading, record } = useEditContext();
    if (isLoading || !record) return null;
    return (
        <Box mt={2} display="flex">
            <Box flex="1" mb={10}>
            <ReferenceField reference="requests" target="contact_id">
        <Datagrid>
            <TextField source="id" />
        </Datagrid>
    </ReferenceField>
                
                <Form>
                    <Card>
                        <CardContent>
                            <Box>
                                <Box display="flex">
                                    <ContactInputs onManagerChange={handleManagerChange} />
                                </Box>
                            </Box>
                        </CardContent>
                        <CustomToolbar onDelete={() => onDelete(record.id)}/>
                    </Card>
                </Form>
            </Box>
           
        </Box>
    );
};

const CustomToolbar = ({ onDelete }) => {
    return (
        <Toolbar>
            <SaveButton />
            <Box width={20} component="span" />
            {localStorage.getItem('role_id') < 2 ? <Button style={{ color: 'red' }} onClick={onDelete} >Удалить</Button> : null}
        </Toolbar>
    );
};

export default ContactEdit;
