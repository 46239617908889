import React from 'react';
import { CompanyForm } from './CompanyForm';
import { CompanyAside } from './CompanyAside';
import { Edit, Form, Toolbar } from 'react-admin';
import { Box, CardContent, Stack, Avatar } from '@mui/material';
import { CompanyAvatar } from './CompanyAvatar';
import '../crm.css';

const CompaniesEdit = () => {
 return (
     <Box display="flex" mb={10}>
    <Edit aside={<CompanyAside link="show" className="adaptive-1" />} actions={false} redirect="show">
        <Form>
            <CardContent>
                <Stack direction="row">
                    <Avatar sx={{ mt: 1 }}>
                          <CompanyAvatar />
                    </Avatar>
                    <Box ml={2} flex="1" maxWidth={796}>
                        <CompanyForm />
                    </Box>
                </Stack>
            </CardContent>
             
            <Toolbar />
        </Form>
    </Edit>
    </Box>
 );
}

export default CompaniesEdit;
