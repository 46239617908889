import * as React from 'react';
import { useEffect, useState } from 'react';
import {
    EditBase,
    Form,
    Toolbar,
    useEditContext,
    useDataProvider,
    ShowButton,
    DeleteButton,
    SaveButton,
    useRedirect,
    ReferenceManyField,
} from 'react-admin';
import { Card, CardContent, Box, Button, Tab, Tabs, Typography, useMediaQuery } from '@mui/material';
import { DealInputs } from './DealInputs';
import '../crm.css';
import { HistoryIterator } from '../requestsHistory/HistoryIterator';
import { NewHistoryComment } from '../requestsHistory/NewHistoryComment';
import { DocumentsIterator } from '../documents/DocumentsIterator';
import { NewDocument } from '../documents/NewDocument';
import { CallsWithContact } from '../calls/CallsWithContact';

const DealEdit = () => {
    const dataProvider = useDataProvider();
    const redirect = useRedirect();
    const [currentRequest, setCurrentRequest] = useState();
    const handleRequestChange = (value) => {
        setCurrentRequest(value);
    };

    const handleDelete = async (id) => {
        // Fetch the existing record to get the data before delete
        const existingRecord = await dataProvider.getOne('deals', { id });

        // Modify the data or perform additional actions
        const modifiedData = {
            ...existingRecord.data,
             ap_id: localStorage.getItem('id'),
             ap_name: localStorage.getItem('first_name') + " " + localStorage.getItem('last_name'),
             table: 'deals'
        };

        // Use dataProvider to send the delete request with modified data
        await dataProvider.create('delete', { data: modifiedData });

        // You can also perform additional actions after delete if needed
        //console.log(`Deleted record with ID: ${id}`);
        redirect(`/deals`);
    };

    return (
        <EditBase redirect="list"
             mutationMode="optimistic"
             transform={(data) => ({
                ...data,
                ap_id: localStorage.getItem('id'),
                ap_name: localStorage.getItem('first_name') + " " + localStorage.getItem('last_name'),
                name: currentRequest.name,
                manager_name: currentRequest.manager_name,
                manager_account_id: currentRequest.manager_account_id,
                request_id: currentRequest.id,
                request_number: currentRequest.number,
                contact_id: currentRequest.contact_id
            })}
        >
            <DealEditContent onDelete={handleDelete} onRequestChange={handleRequestChange} />
        </EditBase>
    );
}

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (children)}
      </div>
    );
}

const DealEditContent = ({ onDelete, onRequestChange }) => {
    const { isLoading, record } = useEditContext();
    const [value, setValue] = useState(0);
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    if (isLoading || !record) return null;

    return (
        <Box display="flex" flexDirection={isSmall ? "column" : "row"} sx={{ overflowY: 'hidden' }}>
            <Box flex="1">
                <Form>
                    <Card>
                        <CardContent>
                            <Box>
                                <Box display="flex">
                                    <DealInputs onRequestChange={onRequestChange} />
                                </Box>
                            </Box>
                        </CardContent>
                        <CustomToolbar onDelete={() => onDelete(record.id)}/>
                    </Card>
                </Form>
            </Box>
            <Box flex="2" display="flex" flexDirection="column">
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                    <Tab label="Комментарии и задачи" />
                    <Tab label="Документы" />
                    <Tab label="Звонки" />
                </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
            <Box flex="1" sx={{display: 'flex', flexDirection: 'column-reverse', paddingLeft: '1rem', height: '85vh', }}>
                    <ReferenceManyField
                     source="request_id"
                     target="request_id"
                     reference="requestsHistory"
                     perPage={10000}
                     filter={{ 'request_id': record.request_id }}
                     sort={{ field: 'created_at', order: 'DESC' }}
                     sx={{ overflowY: 'auto', display: 'flex', flexDirection: 'column-reverse',}}
                    >
                        <NewHistoryComment reference="deals" />
                        <HistoryIterator reference="deals" />
                    </ReferenceManyField>
            </Box>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
            <Box flex="1" sx={{display: 'flex', flexDirection: 'column-reverse', paddingLeft: '1rem', height: '85vh', }}>
                <ReferenceManyField
                     //source="request_id"
                     target="deal_id"
                     reference="documents"
                     perPage={10000}
                     filter={{ 'deal_id': record.id }}
                     sort={{ field: 'created_at', order: 'DESC' }}
                     sx={{ overflowY: 'auto', display: 'flex', flexDirection: 'column-reverse',}}
                    >
                        <NewDocument reference="deals" />
                        <DocumentsIterator reference="deals" />
                </ReferenceManyField>
            </Box>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
                <CallsWithContact contact_id={record.contact_id} />
            </CustomTabPanel>
            </Box>
        </Box>
    );
};

const CustomToolbar = ({ onDelete }) => {
    return (
        <Toolbar>
            <SaveButton />
            <Box width={20} component="span" />
            {localStorage.getItem('role_id') <= 2 ? <Button style={{ color: 'red' }} onClick={onDelete} >Удалить</Button> : null}
        </Toolbar>
    );
};

export default DealEdit;