import * as React from 'react';
import { useState } from 'react';
import {
    TextInput,
    ReferenceInput,
    ReferenceArrayInput,
    AutocompleteInput,
    AutocompleteArrayInput,
    BooleanInput,
    SelectInput, 
    useInput, required, ImageInput, ImageField,
    useRecordContext,
    useListContext,
    useEditContext
} from 'react-admin';
import { Divider, Box, Grid, IconButton, Typography  } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import { statuses } from './statuses';
import { useNavigate } from 'react-router-dom';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

export const SanctionsInputs = () => {
    const record = useRecordContext();
    const navigate = useNavigate();

    return (<Box flex="1" mt={-1}>

        <Box display="flex" flexDirection="row">
			<IconButton
				  onClick={() => navigate(-1)}
				  size="small"
				>
				  <KeyboardBackspaceIcon />
			</IconButton>
            <h3>Статус штрафа (санкции)</h3>
		</Box>
        <Box mb={2}>
        <Typography variant="body1">{record.history}</Typography>
        <Typography variant="body2">{record.manager_name}</Typography>
        </Box>

        <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
                <SelectInput
                label="Выберите статус"
                source="status"
                choices={statuses.slice(2)}
                validate={required()} 
                fullWidth 
                />
            </Grid>
        </Grid>

    </Box>);
};
