export const types = [
    //{id: 'comment', name: 'Написать комментарий к сделке'},
    
    {id: 'db', name: 'Договор бронирования (ДБ) ИП Кучерук-Наместникова'},
    {id: 'db_oz', name: 'Договор бронирования (ДБ) ИП Зулаева'},
    
    {id: 'invoice', name: 'Счет на оплату'},

    {id: 'dkp', name: 'Договор купли-продажи (ДКП)'},

    //{id: 'act', name: 'Акт приема-передачи'},
];