import * as React from 'react';
import { Menu, useRedirect, useGetList, } from 'react-admin';
import { Divider, Box, Grid, Paper, Button } from '@mui/material';
import BookIcon from '@mui/icons-material/Book';
import PeopleIcon from '@mui/icons-material/People';
import LabelIcon from '@mui/icons-material/Label';
import StoreIcon from '@mui/icons-material/Store';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import FunnelIcon from '@mui/icons-material/FilterAlt';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import EmailIcon from '@mui/icons-material/Email';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import HolidayVillageIcon from '@mui/icons-material/HolidayVillage';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import DraftsIcon from '@mui/icons-material/Drafts';
import NotificationsIcon from '@mui/icons-material/Notifications';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import AddLinkIcon from '@mui/icons-material/AddLink';
import TaskAltIcon from '@mui/icons-material/TaskAlt';

const MyMenu = (props) => {
    const [isHovered, setIsHovered] = React.useState(false);
    const redirect = useRedirect();

    const { data: funnels,  isLoading: isLoadingFunnels } = useGetList(
        'funnels',
        { 
            pagination: { page: 1, perPage: 5000 },
            sort: { field: 'updated_at', order: 'DESC' },
        }
    );

    const handleMenuItemClick = (id) => {
        redirect(`/requests?funnel_id=${id}`);
        //window.location.reload();
    };

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    return (
    <Menu {...props}>
         {/* <Menu.DashboardItem /> */}
        <Box onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        <Menu.Item to={`/requests`} primaryText="Заявки" leftIcon={<ShoppingCartIcon />}/>
        {document.querySelector('.MuiList-root') && !document.querySelector('.MuiList-root').classList.contains('RaMenu-closed') && isHovered && (
                <Paper elevation={3} style={{ position: 'relative', top: '0', padding: '10px' }} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                    {funnels && funnels.map(funnel => (
                        <Button variant="text" color="primary" style={{ marginBottom: '10px', width: '100%' }} onClick={() => handleMenuItemClick(funnel.id)}>
                            <Box sx={{ textAlign: 'start', width: '100%'}}>{funnel.name}</Box>
                        </Button>
                    ))}
                </Paper>
        )}
        </Box>
        <Menu.Item to="/requestsHistory" primaryText="Задачи" leftIcon={<TaskAltIcon />}/>

        <Menu.Item to="/deals" primaryText="Сделки" leftIcon={<BusinessCenterIcon />}/>

        {/*<Menu.Item to="/contacts" primaryText="Клиенты" leftIcon={<PeopleIcon />}/>*/}
        <Menu.Item to="/all_requests" primaryText="Клиенты" leftIcon={<PeopleIcon />}/>
        <Menu.Item to="/villages" primaryText="Поселки (участки)" leftIcon={<HolidayVillageIcon />} />
        <Menu.Item to="/sales" primaryText="Пользователи" leftIcon={<SupervisedUserCircleIcon />}/>

        {/* <Box height={20} component="span" />*/}

        {/* <Menu.Item to="/companies" primaryText="Компании" leftIcon={<StoreIcon />}/> */}
        {/* localStorage.getItem('role_id') <= 2 ? <Menu.Item to="/tags" primaryText="Редактор тегов" leftIcon={<LocalOfferIcon />}/> : null*/}

        
    </Menu>
)
};

export default MyMenu;