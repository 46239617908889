import * as React from 'react';
import { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import {
    List as RaList,
    SimpleListLoading,
    ReferenceField,
    TextField,
    EditButton,
    ShowButton,
    useListContext,
    ExportButton,
    SortButton,
    TopToolbar,
    CreateButton,
    Pagination,
    useGetIdentity,
    BulkActionsToolbar,
    BulkDeleteButton,
    BulkExportButton,
    RecordContextProvider,
    BooleanField,
    DateField,
    BooleanInput,
    Datagrid,
    ImageField,
    FilterLiveSearch,
    SearchInput,
    Form,
    SelectInput,
    required,
    useRefresh,
    SimpleList,
    Loading
} from 'react-admin';
import {
    List,
    ListItem,
    ListItemAvatar,
    ListItemIcon,
    ListItemSecondaryAction,
    ListItemText,
    Checkbox,
    Typography,
    Box,
    useMediaQuery
} from '@mui/material';
import { Link } from 'react-router-dom';
import { formatDistance } from 'date-fns';
import { ru } from 'date-fns/locale';
import { Avatar } from '../contacts/Avatar';
import '../crm.css';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import Button from '@mui/material/Button';
import * as XLSX from 'xlsx';
import { stages } from './stages';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { OnlyMineInput } from '../requests/OnlyMineInput';
import FileUploadIcon from '@mui/icons-material/FileUpload';

// Custom export button component
const CustomExportButton = ({ resource, ...props }) => {
    const {
        data: deals,
        isLoading,
        onToggleItem,
        selectedIds,
    } = useListContext();

  const handleClick = () => {

    const fieldOrder = [
      'id',
      'name',
      'description',
      'address',
      'cadastre',
      'size',
    ];

    const reorderedDataToExport = deals.map((account) => {
      const reorderedAccount = {};
      fieldOrder.forEach((field) => {
       // reorderedAccount[field] = account[field];
           reorderedAccount[field] = Array.isArray(account[field]) ? account[field].join(', ') : account[field];
      });
      return reorderedAccount;
    });

    const ws = XLSX.utils.json_to_sheet(reorderedDataToExport);

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Лист 1');
    XLSX.writeFile(wb, "Сделки - Выгрузка из Владелец CRM.xlsx");
  };

  return (
    <Button onClick={handleClick} label="Выгрузить в Excel" variant="outlined" size="small" color="success" startIcon={<FileUploadIcon />}>
      Выгрузить в Excel
    </Button>
  );
};

const DealListContent = forwardRef((props, ref) => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const { setSort } = useListContext();
    const setSort1 = (field1, order1) => {
        setSort({ field: field1, order: order1 });
    };

    // Expose the setSort function using useImperativeHandle
    useImperativeHandle(ref, () => ({
        setSort1: (field1, order1) => setSort1(field1, order1),
    }));

    const {
        data: deals,
        isLoading, isFetching,
        onToggleItem,
        selectedIds,
    } = useListContext();
    if (isLoading || isFetching) {
        return <Loading />
    }
    /*if (isLoading) {
        return <SimpleListLoading hasLeftAvatarOrIcon hasSecondaryText />;
    }*/
    const now = Date.now();

    const getStatusColor = (stageId) => {
        switch (stageId) {
            case 'sale':
                return 'green'; // Green circle for 'sale'
            case 'reserved':
            case 'dealing':
                return 'yellow'; // Yellow circle for 'reserved' or 'dealing'
            case 'sold':
                return 'red'; // Red circle for 'sold'
            default:
                return 'grey'; // Default color for unknown status
        }
    };

    return (
        <>
           {/*<BulkActionsToolbar>
                
                <BulkDeleteButton />
            </BulkActionsToolbar>*/}
            {isSmall ? 
                         <SimpleList
                            primaryText={deal => `Сделка №${deal.document_name}`}
                            secondaryText={deal => `Клиент: ${deal.name}`}
                            tertiaryText={deal => `Менеджер: ${deal.manager_name}`}
                            linkType={"edit"}   
                         /> :
            <List>
                {deals.map(deal => (
                    <RecordContextProvider key={deal.id} value={deal}>
                        <ListItem
                            button
                            component={Link}
                            to={`/deals/${deal.id}`}
                        >
                            <Box display="flex" flex={1} justifyContent="start" alignItems="center" sx={{marginRight: '1rem'}}>
                            <ListItemIcon>
                                        <FiberManualRecordIcon style={{ color: getStatusColor(deal.stage_id) }} />
                            </ListItemIcon>
                            <ListItemText
                                primary={deal.document_name ? `Сделка №${deal.document_name}` : `Сделка №${deal.number}`}
                                secondary={deal.updated_at ? `Обновлена ${new Date(deal.updated_at).toLocaleString('ru-RU')}` :  `Создана ${new Date(deal.created_at).toLocaleString('ru-RU')}`}
                            />
                            </Box>
                            <Box display="flex" flex={2} justifyContent="start" alignItems="center" sx={{marginRight: '1rem'}}>
                            <ListItemText
                                primary={`Клиент: ${deal.name}`}
                                secondary={`Менеджер: ${deal.manager_name}`}
                            />
                            </Box>
                            <Box display="flex" flex={2} justifyContent="start" alignItems="center" sx={{marginRight: '1rem'}}>
                            <ListItemText
                                primary={`Стоимость сделки: ${deal.deal_price} руб.`}
                                secondary={<ReferenceField source="village_id" reference="villages" link="show">
                                <TextField source="name" />
                            </ReferenceField>}
                            />
                            </Box>
                        </ListItem>
                    </RecordContextProvider>
                ))}
            </List>}
        </>
    );
});

const DealListActions = ({filters, currentFilter, handleFilterChange}) => (
    <TopToolbar sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
        <Form className='filterForm'>
            <SelectInput label="Отображение" source="view" choices={filters} defaultValue={currentFilter.id} onChange={handleFilterChange} validate={required()} helperText={null} />
        </Form>  
        {localStorage.getItem('role_id') < 2 ? <CustomExportButton /> : null}
        <CreateButton
            variant="outlined"
            label="Новая сделка"
            sx={{ marginLeft: 2 }}
        />
    </TopToolbar>
);

const DealList = () => {
    const { identity } = useGetIdentity();
    const filters = [
        {id: 1, name: 'По дате (от новых к старым)', order: 'DESC', field: 'updated_at'}, 
        {id: 2, name: 'По дате (от старых к новым)', order: 'ASC', field: 'updated_at'},
    ];
    const [currentFilter, setCurrentFilter] = useState(filters[0]);
    const dealListContentRef = useRef();

    const handleFilterChange = (value) => {
        setCurrentFilter(filters.find((item) => item.id === value.target.value));
        if (dealListContentRef.current) {
            dealListContentRef.current.setSort1(filters.find((item) => item.id === value.target.value).field, filters.find((item) => item.id === value.target.value).order);
        }
    };

    return identity ? (
        <RaList
            actions={<DealListActions filters={filters} currentFilter={currentFilter} handleFilterChange={handleFilterChange} />}
            perPage={50}
            sort={{ field: 'updated_at', order: 'DESC' }}
            filter={{'sales_id_for_requests': localStorage.getItem('id')}} 
            filters={localStorage.getItem('role_id') <= 3 ? requestsFilters : requestsFiltersForManagers}
        >
             <div style={{ marginBottom: '2rem' }}>
             <div style={{ position: 'absolute', top: 0, left: 0 }}>
             <Pagination rowsPerPageOptions={[50, 100, 500, 1000]} />
             </div>
             </div>
            <DealListContent ref={dealListContentRef} />
        </RaList>
    ) : null;
};

const requestsFilters = [
    <SearchInput source="q" alwaysOn />,
    <OnlyMineInput alwaysOn />,
];
const requestsFiltersForManagers = [
     <SearchInput source="q" alwaysOn />,
];

export default DealList;


