import * as React from 'react';
import { CreateBase, Form, Toolbar } from 'react-admin';
import { Card, CardContent, Box, Avatar } from '@mui/material';

import { VillageInputs } from './VillageInputs';

const VillageCreate = () => (
    <CreateBase
        redirect="show"
        mutationMode="optimistic"
        transform={(data) => {
            const estates = Array.from({ length: data.amount }, (_, index) => ({
                number: index + 1,
                price: data.estates_price,
                size: data.estates_size,
                stage_id: 'sale'
            }));

            // Remove data.amount, data.estates_price, and data.estates_size
            const { amount, estates_price, estates_size, ...transformedData } = data;

            return {
                ...transformedData,
                updated_at: new Date(),
                ap_id: localStorage.getItem('id'),
                ap_name: localStorage.getItem('first_name') + " " + localStorage.getItem('last_name'),
                estates: estates
            };
        }}
    >
        <Box mt={2} display="flex">
            <Box flex="1" mb={10}>
                <Form>
                    <Card>
                        <CardContent>
                            <Box>
                                <Box display="flex">
                                    <VillageInputs />
                                </Box>
                            </Box>
                        </CardContent>
                        <Toolbar />
                    </Card>
                </Form>
            </Box>
        </Box>
    </CreateBase>
);

export default VillageCreate;
