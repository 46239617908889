import * as React from 'react';
import { Box, Typography } from '@mui/material';
import { Droppable } from 'react-beautiful-dnd';
import { Identifier } from 'react-admin';

import { DealCard } from './DealCard';
import { stageNames } from './stages';
import { RecordMap } from './DealListContent';

export const DealColumn = ({
    stage,
    dealIds,
    data,
}) => (
    <Box
        sx={{
            flex: 1,
            paddingTop: '8px',
            minWidth: '15rem',
            paddingBottom: '16px',
            //bgcolor: stage.color,
            '&:first-child': {
                paddingLeft: '35px',
                borderTopLeftRadius: 5,
            },
            '&:last-child': {
                paddingRight: '35px',
                borderTopRightRadius: 5,
            },
        }}
    >
        <Box display="flex" justifyContent="center" alignItems="center" textAlign="center" sx={{
                position: 'relative',
                marginBottom: '15px', 
                height: '3rem',
                fontWeight: 'bold', 
                '&::after': {
                    content: '""',
                    position: 'absolute',
                    left: '10%',
                    right: '10%', 
                    bottom: '-10px',
                    height: '6px',
                    borderRadius: '3px',
                    backgroundColor: stage.color, 
                },
            }}>
        <Typography variant="body1"
            sx={{
                fontWeight: 'bold', 
            }}
        >
                {stage.name} ({dealIds.length})
        </Typography>
        </Box>
        <Droppable droppableId={stage.id}> 
            {(droppableProvided, snapshot) => (
                <Box
                    ref={droppableProvided.innerRef}
                    {...droppableProvided.droppableProps}
                    className={snapshot.isDraggingOver ? ' isDraggingOver' : ''}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        borderRadius: 5,
                        padding: '5px',
                        '&.isDraggingOver': {
                            bgcolor: '#dadadf',
                        },
                    }}
                >
                    {dealIds.map((id, index) => (
                        <DealCard key={id} index={index} request={data[id]} inactive_stage={stage.request_done} />
                    ))}
                    {droppableProvided.placeholder}
                </Box>
            )}
        </Droppable>
    </Box>
);
