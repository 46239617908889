import * as React from 'react';
import { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import {
    List,
    SimpleListLoading,
    ReferenceField,
    TextField,
    EditButton,
    ShowButton,
    useListContext,
    ExportButton,
    SortButton,
    TopToolbar,
    CreateButton,
    Pagination,
    useGetIdentity,
    BulkActionsToolbar,
    BulkDeleteButton,
    BulkExportButton,
    RecordContextProvider,
    BooleanField,
    DateField,
    BooleanInput,
    Datagrid,
    ImageField,
    SearchInput,
    SelectInput,
    Form,
    required,
    Filter,
    SimpleList,
    InfiniteList,
    ReferenceInput,
    AutocompleteInput,
} from 'react-admin';
import { List as MUIList,ListItem, ListItemText, Box,Card, Dialog, DialogContent, Typography, Divider, Link as MUILink, useMediaQuery} from '@mui/material';
import { Link } from 'react-router-dom';
import { formatDistance } from 'date-fns';
import { ru } from 'date-fns/locale';
import { Avatar } from '../contacts/Avatar';
import '../crm.css';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import Button from '@mui/material/Button';
import * as XLSX from 'xlsx';
import { TaskListContent } from './TaskListContent';
import FileUploadIcon from '@mui/icons-material/FileUpload';

// Custom export button component
const CustomExportButton = ({ resource, ...props }) => {
    const {
        data: tasks,
        isLoading,
        onToggleItem,
        selectedIds,
    } = useListContext();

  const handleClick = () => {

    /*const fieldOrder = [
      'id',
      'name',
      'description',
      'address',
      'cadastre',
      'size',
    ];

    const reorderedDataToExport = tasks.map((account) => {
      const reorderedAccount = {};
      fieldOrder.forEach((field) => {
       // reorderedAccount[field] = account[field];
           reorderedAccount[field] = Array.isArray(account[field]) ? account[field].join(', ') : account[field];
      });
      return reorderedAccount;
    });*/

    const ws = XLSX.utils.json_to_sheet(tasks);

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Лист 1');
    XLSX.writeFile(wb, "Задачи - Выгрузка из Владелец CRM.xlsx");
  };

  return (
    <Button onClick={handleClick} label="Выгрузить в Excel" variant="outlined" size="small" color="success" startIcon={<FileUploadIcon />}>
      Выгрузить в Excel
    </Button>
  );
};

const Clock = () => {
    const [currentDateTime, setCurrentDateTime] = useState(new Date());

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentDateTime(new Date());
        }, 1000);

        return () => clearInterval(intervalId);
    }, []);

    const formatDate = (date) => {
        const options = {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        };
        return date.toLocaleDateString('ru-RU', options);
    };

    const formatTime = (date) => {
        const options = {
            hour: 'numeric',
            minute: 'numeric',
        };
        return date.toLocaleTimeString('ru-RU', options);
    };

    return (
        <Box style={{ display: 'flex', width: 'fit-content', flexDirection: 'column', /*marginLeft: '1rem'*/}} mt={1} ml={3}>
            <Typography variant="h4">
                 {formatTime(currentDateTime)}
            </Typography>
            <Typography variant="body2">
                {formatDate(currentDateTime)}
            </Typography>
        </Box>
    );
};

const TaskListActions = ({views, currentView, handleViewChange, currentManagerFilter, handleSelectedManagerFilterChange}) => (
    <TopToolbar sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>

        {localStorage.getItem('role_id') <= 3 ?
            <Form className="tasks-views">
              <ReferenceInput source="manager_account_id" reference="sales" filter={{ 'sales_id_for_requests': localStorage.getItem('id') }}>
                  <AutocompleteInput label="Менеджер" defaultValue={currentManagerFilter} onChange={handleSelectedManagerFilterChange} optionText={(choice) => `${choice.first_name} ${choice.last_name}`} />
              </ReferenceInput>
        </Form> : null}
        
        {localStorage.getItem('role_id') <= 3 ?
        <Form className="tasks-views">
            <Box display="flex" flexDirection="row" >
            <SelectInput label="Отображение" source="view" choices={views} defaultValue={currentView.id} onChange={handleViewChange} validate={required()} helperText={null} />
            </Box>
        </Form> : null}

        {localStorage.getItem('role_id') < 2 ?
        <CustomExportButton /> : null}

        <CreateButton
            variant="outlined"
            label="Новая задача"
            sx={{ marginLeft: 2 }}
        />
        <Clock />
    </TopToolbar>
);

const tasksFilters = [
    <SearchInput source="q" alwaysOn />,
   // <SelectInput source="status" choices={typeChoices} />,
];

const TaskList = () => {
    const { identity } = useGetIdentity();
    const views = [{id: 1, name: 'Текущие'}, {id: 2, name: 'Выполненные (архив)'}];
    const [currentView, setCurrentView] = useState(views[0]);
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const [currentManagerFilter, setCurrentManagerFilter] = useState();
    const listContentRef = useRef();
    const handleViewChange = (value) => {
        console.log('Selected value:', value.target.value);
        setCurrentView(views.find((item) => item.id === value.target.value));
    };

    useEffect(() => {
        const storedManagerFilter= localStorage.getItem('currentManagerFilter');
        if(storedManagerFilter){
            setCurrentManagerFilter(storedManagerFilter);
            if (listContentRef.current) {
                listContentRef.current.setListFilter('task_manager_id', storedManagerFilter);
            }
        }
        if(storedManagerFilter == ""){
            setCurrentManagerFilter("");
            if (listContentRef.current) {
                listContentRef.current.setListFilter('task_manager_id', "");
            }
        }
    }, [currentManagerFilter]);

    const handleSelectedManagerFilterChange = (value) => {
        setCurrentManagerFilter(value);
        localStorage.setItem('currentManagerFilter', value);
        if (listContentRef.current) {
            listContentRef.current.setListFilter('task_manager_id', value);
        }
    };


    return identity ? (
        <Box flex={1} flexDirection="column">
        { currentView.id === 1 && identity ?
        <List
            resource="requestsHistory"
            filter={{'sales_id_for_tasks':  localStorage.getItem('id'), 'comment_type': 'task', 'task_done': false }}
            actions={ <TaskListActions 
                views={views} 
                currentView={currentView} 
            handleViewChange={handleViewChange} 
            handleSelectedManagerFilterChange={handleSelectedManagerFilterChange}
            currentManagerFilter={currentManagerFilter} 
            /> }
            filters={tasksFilters}
            //pagination={false}
            perPage={500}
            //pagination={<Pagination rowsPerPageOptions={[100, 500, 1000]} />}
            component="div"
        >
            <div style={{ marginBottom: '2rem' }}>
            <div style={{ position: 'absolute', top: 0, left: 0 }}>
            <Pagination rowsPerPageOptions={[100, 500, 1000]} />
            </div>
            </div>
            {isSmall ? <SmallTasksListContent ref={listContentRef} /> :
            <TaskListContent ref={listContentRef} />}
        </List> : null}
        { currentView.id === 2 ? 
            <Box display="flex" flexDirection="column" >
            <List
            resource="requestsHistory"
            filter={{'sales_id_for_tasks':  localStorage.getItem('id'), 'comment_type': 'task', 'task_done': true }}
            actions={ <TaskListActions 
                views={views} 
                currentView={currentView} 
                handleViewChange={handleViewChange} 
                handleSelectedManagerFilterChange={handleSelectedManagerFilterChange}
                currentManagerFilter={currentManagerFilter} 
            /> }
            filters={tasksFilters}
            pagination={<Pagination rowsPerPageOptions={[50, 100, 500, 1000]} />}
            perPage={50}
            >
                <CompletedTasksListContent ref={listContentRef} />
            </List></Box>  : null}
            
        </Box>
    ) : null;
};

const CompletedTasksListContent = forwardRef(( props, ref) => {
    const {
        data: doneTasks,
        isLoading,
        onToggleItem,
        selectedIds,
        setFilters
    } = useListContext();

    const setListFilter = (field, value) => {
        var dynamicFilter = {};
        dynamicFilter[field] = value;
        setFilters(dynamicFilter);
    };

    useImperativeHandle(ref, () => ({
        setListFilter: (field, value) => setListFilter(field, value),
    }));

    if (isLoading) {
        return <SimpleListLoading hasLeftAvatarOrIcon hasSecondaryText />;
    }

    return (
        <>
            <MUIList>
                {doneTasks.map(request => (
                    <RecordContextProvider key={request.id} value={request}>
                        <ListItem
                            button
                            component={Link}
                            to={request.request_id ? `/requests/${request.request_id}` : `/requestsHistory/${request.id}/show`}
                             sx={{
                                borderLeft: `4px solid green`,
                                padding: '0 16px',
                                marginRight: '16px', 
                            }}
                        >
                            <ListItemText
                                primary={request.request_number ? `К заявке #${request.request_number}` : ''}
                                secondary={<Box>
                                    <Typography variant="body2" style={{ color: 'green'}}>{new Date(request.date_time).toLocaleDateString('ru-RU')}</Typography>
                                    <Typography variant="body2">{new Date(request.created_at).toLocaleDateString('ru-RU')}</Typography>
                                </Box>}
                            />
                            <ListItemText
                                primary={request.comment}
                                secondary={request.ap_name}
                            />
                            <ListItemText
                                primary={request.task_answer}
                                secondary={request.manager_name}
                            />
                            <ListItemText
                                primary={<MUILink href={`tel:${request.phone}`} target="_blank">{request.phone}</MUILink>}
                                secondary={<ReferenceField source="requestIncomeSource_id" reference="requestIncomeSources">
                                <TextField source="name" />
                            </ReferenceField>}
                            />
                          
                            {/*localStorage.getItem('role_id') <= 2 ?  <EditButton /> : null*/}
                        </ListItem>
                    </RecordContextProvider>
                ))}
            </MUIList>
        </>
    );
});

const SmallTasksListContent = forwardRef(( props, ref) => {
    const {
        data: tasks,
        isLoading,
        onToggleItem,
        selectedIds,
        setFilters
    } = useListContext();

    const setListFilter = (field, value) => {
        var dynamicFilter = {};
        dynamicFilter[field] = value;
        setFilters(dynamicFilter);
    };

    useImperativeHandle(ref, () => ({
        setListFilter: (field, value) => setListFilter(field, value),
    }));


    if (isLoading) {
        return <SimpleListLoading hasLeftAvatarOrIcon hasSecondaryText />;
    }
    
    const sortedTasks = tasks.sort((taskA, taskB) => {
        const dateA = new Date(taskA.date_time);
        const dateB = new Date(taskB.date_time);
        return dateA - dateB;
    });

    return (
        <Card>
          <SimpleList
            data={sortedTasks}
            primaryText={request => request.comment}
            secondaryText={request => `${new Date(request.date_time).toLocaleString('ru-RU')}`}
            tertiaryText={request => `Менеджер: ${request.manager_name}`}
            linkType={request => request.request_id ? `/requests/${request.request_id}` : `/requestsHistory/${request.id}/show`}   
           />
        </Card>
    );
});

export default TaskList;


