import * as React from 'react';
import { useListFilterContext, useGetIdentity } from 'react-admin';
import { Box, Switch, FormControlLabel } from '@mui/material';

export const OnlyMineInput = ({ alwaysOn }) => {
    const {
        filterValues,
        displayedFilters,
        setFilters,
    } = useListFilterContext();
    const { identity } = useGetIdentity();

    const handleChange = () => {
        const newFilterValues = { ...filterValues };
        if (typeof filterValues.manager_account_id !== 'undefined') {
            delete newFilterValues.manager_account_id;
        } else {
            newFilterValues.manager_account_id = identity && identity?.id;
        }
        setFilters(newFilterValues, displayedFilters);
    };
    return (
        <Box sx={{ marginBottom: 1, marginLeft: 1 }}>
            <FormControlLabel
                control={
                    <Switch
                        checked={typeof filterValues.manager_account_id !== 'undefined'}
                        onChange={handleChange}
                        color="primary"
                        name="checkedC"
                    />
                }
                label={typeof filterValues.manager_account_id !== 'undefined' ? "Только мои" : "Общие"}
            />
        </Box>
    );
};

OnlyMineInput.defaultProps = { source: 'manager_account_id' };
