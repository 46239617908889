import * as React from 'react';
import { useState, useEffect } from 'react';
import {
    EditBase,
    Form,
    Toolbar,
    useEditContext,
    useDataProvider,
    ShowButton,
    DeleteButton,
    SaveButton,
    useRedirect,
} from 'react-admin';
import { Card, CardContent, Box, Button, useMediaQuery } from '@mui/material';

import { Avatar } from '../contacts/Avatar';
import { TaskInputs } from './TaskInputs';
import '../crm.css';

const TaskEdit = () => {
    const dataProvider = useDataProvider();
    const redirect = useRedirect();

    const handleDelete = async (id) => {
        // Fetch the existing record to get the data before delete
        const existingRecord = await dataProvider.getOne('requestsHistory', { id });

        // Modify the data or perform additional actions
        const modifiedData = {
            ...existingRecord.data,
             ap_id: localStorage.getItem('id'),
             ap_name: localStorage.getItem('first_name') + " " + localStorage.getItem('last_name'),
             table: 'requestsHistory'
        };

        // Use dataProvider to send the delete request with modified data
        await dataProvider.create('delete', { data: modifiedData });

        // You can also perform additional actions after delete if needed
        //console.log(`Deleted record with ID: ${id}`);
        redirect(`/requestsHistory`);
    };
 
    return (
        <EditBase redirect="show" 
             mutationMode="optimistic"
             transform={(data) => ({
                ...data,
                ap_id: localStorage.getItem('id'),
                ap_name: localStorage.getItem('first_name') + " " + localStorage.getItem('last_name'),
            })}
        >
            <TaskEditContent onDelete={handleDelete} />
        </EditBase>
    );
}

const TaskEditContent = ({ onDelete }) => {
    const { isLoading, record } = useEditContext();
    const dataProvider = useDataProvider();
    const redirect = useRedirect();

    const [selectedManager, setSelectedManager] = useState();

     useEffect(() => {
        handleManagerChange(record.task_manager_id);
    }, [record]); 

	const handleManagerChange = async(id) => {
		if(id){
			var recordFromDatabase = await dataProvider.getOne('sales', { id });
			setSelectedManager(recordFromDatabase.data);
		}else{
			setSelectedManager({});
		}
	};

    if (isLoading || !record || !selectedManager) return null;

    return (
        <Box mt={2} display="flex">
            <Box flex="1" mb={10}>
                <ShowButton label="Показать задачу" />
                <Form>
                    <Card>
                        <CardContent>
                            <Box>
                                <Box display="flex">
                                     <TaskInputs selectedManager={selectedManager} handleManagerChange={handleManagerChange} />
                                </Box>
                            </Box>
                        </CardContent>
                        <CustomToolbar onDelete={() => onDelete(record.id)}/>
                    </Card>
                </Form>
            </Box>
        </Box>
    );
};

const CustomToolbar = ({ onDelete }) => {
    return (
        <Toolbar>
            <SaveButton />
            <Box width={20} component="span" />
            {localStorage.getItem('role_id') <= 2 ? <Button style={{ color: 'red' }} onClick={onDelete} >Удалить</Button> : null}
        </Toolbar>
    );
};

export default TaskEdit;