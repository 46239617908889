import * as React from 'react';
import { useEffect, useState } from 'react';
import {
    Create,
    SimpleForm,
    TextInput,
    SelectInput,
    NumberInput,
    ReferenceInput,
    AutocompleteInput,
    required,
    useRedirect,
    useDataProvider,
    DateTimeInput,
    useGetList,
    CreateBase, Form, Toolbar 
} from 'react-admin';
import { Dialog, Grid,  Card, CardContent, Box, Avatar } from '@mui/material';
import { DealInputs } from './DealInputs';

const DealCreate = () => {
    const [currentRequest, setCurrentRequest] = useState();
    const handleRequestChange = (value) => {
        setCurrentRequest(value);
    };

    return (
        <CreateBase
        redirect="list"
        mutationMode="optimistic"
        transform={(data) => ({
            ...data,
            created_at: new Date(),
            ap_id: localStorage.getItem('id'),
            ap_name: localStorage.getItem('first_name') + " " + localStorage.getItem('last_name'),
            name: currentRequest.name,
            manager_name: currentRequest.manager_name,
            manager_account_id: currentRequest.manager_account_id,
            request_number: currentRequest.number,
            contact_id: currentRequest.contact_id
        })}
        >
        <Box mt={2} display="flex">
            <Box flex="1" mb={10}>
                <Form>
                    <Card>
                        <CardContent>
                            <Box>
                                <Box display="flex">
                                    <DealInputs onRequestChange={handleRequestChange} />
                                </Box>
                            </Box>
                        </CardContent>
                        <Toolbar />
                    </Card>
                </Form>
            </Box>
        </Box>
        </CreateBase>
    );
};

export default DealCreate;
