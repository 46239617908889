import * as React from 'react';
import {
    List as RaList,
    SimpleListLoading,
    ReferenceField,
    TextField,
    EditButton,
    ShowButton,
    useListContext,
    ExportButton,
    SortButton,
    TopToolbar,
    CreateButton,
    Pagination,
    useGetIdentity,
    BulkActionsToolbar,
    BulkDeleteButton,
    BulkExportButton,
    RecordContextProvider,
    BooleanField,
    DateField,
    BooleanInput,
    Datagrid,
    ImageField,
    SimpleList,
    FilterLiveSearch,
} from 'react-admin';
import {
    List,
    ListItem,
    ListItemAvatar,
    ListItemIcon,
    ListItemSecondaryAction,
    ListItemText,
    Checkbox,
    Typography,
    Box, 
    useMediaQuery,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { formatDistance } from 'date-fns';
import { ru } from 'date-fns/locale';
import { Avatar } from '../contacts/Avatar';
import { roles } from './roles';
import '../crm.css';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import Button from '@mui/material/Button';
import * as XLSX from 'xlsx';
import FileUploadIcon from '@mui/icons-material/FileUpload';

// Custom export button component
const CustomExportButton = ({ resource, ...props }) => {
    const {
        data: accounts,
        isLoading,
        onToggleItem,
        selectedIds,
    } = useListContext();

  const handleClick = () => {

    const filteredDataToExport = accounts.map(({ avatar, password, connectedToLeads, picture, ...rest }) => rest);

    const fieldOrder = [
      'id',
      'username',
      'first_name',
      'last_name',
      'email',
      'phone',
      'phone_extension',
      'telegram_username',
      'position',
      'role',
      'subordinates_ids',
      'updated_at',
    ];

    const reorderedDataToExport = filteredDataToExport.map((account) => {
      const reorderedAccount = {};
      fieldOrder.forEach((field) => {
       // reorderedAccount[field] = account[field];
           reorderedAccount[field] = Array.isArray(account[field]) ? account[field].join(', ') : account[field];
      });
      return reorderedAccount;
    });

    const ws = XLSX.utils.json_to_sheet(reorderedDataToExport);

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Лист 1');
    XLSX.writeFile(wb, "Пользователи - Выгрузка из Владелец CRM.xlsx");
  };

  return (
    <Button onClick={handleClick} label="Выгрузить в Excel" variant="outlined" size="small" color="success" startIcon={<FileUploadIcon />}>
      Выгрузить в Excel
    </Button>
  );
};

const AccountListContent = () => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const {
        data: accounts,
        isLoading,
        onToggleItem,
        selectedIds,
    } = useListContext();
    if (isLoading) {
        return <SimpleListLoading hasLeftAvatarOrIcon hasSecondaryText />;
    }
    const now = Date.now();

    return (
        <>
           {/*<BulkActionsToolbar>
                
                <BulkDeleteButton />
            </BulkActionsToolbar>*/}
            {isSmall ? 
             <SimpleList
             primaryText={account => `${account.first_name} ${account.last_name}`}
             secondaryText={account => account.position}
             tertiaryText={account => roles.find(role => role.id === account.role).name}
             linkType={"show"}   
            /> :
            <List>
                {accounts.map(account => (
                    <RecordContextProvider key={account.id} value={account}>
                        <ListItem
                            button
                            component={Link}
                            to={`/sales/${account.id}/show`}
                        >
                            
                            
                            {/*<BooleanField source="commentable" /> <ListItemIcon>
                                {localStorage.getItem('username').includes("admin") && account.username !== "super_admin" ? <Checkbox
                                    edge="start"
                                    checked={selectedIds.includes(account.id)}
                                    tabIndex={-1}
                                    disableRipple
                                    onClick={e => {
                                        e.stopPropagation();
                                        onToggleItem(account.id);
                                    }}
                                /> : null}
                            </ListItemIcon>*/}
                            <Box display="flex" flex={3} justifyContent="start" alignItems="center" sx={{marginRight: '1rem'}}>
                            <ListItemAvatar>
                                <Avatar />
                            </ListItemAvatar>
                            
                            <ListItemText
                                primary={`${account.first_name} ${account.last_name}`}
                                secondary={account.email}
                            />
                            </Box>
                            <Box display="flex" flex={2} justifyContent="start" alignItems="center" sx={{marginRight: '1rem'}}>
                            <ListItemText
                                primary={account.position}
                                secondary={roles.find(role => role.id === account.role).name}
                            />
                            </Box>
                            <Box display="flex" flex={1} justifyContent="start" alignItems="center" sx={{marginRight: '1rem'}}>
                             <ListItemText
                                primary={account.phone}
                                secondary={account.phone_extension ? `доб.: ` + account.phone_extension : null}
                            />
                            </Box>
                            <Box display="flex" flex={3} justifyContent="start" alignItems="center" sx={{marginRight: '1rem'}}>
                            <Box mr={2}>
                            <h5>Подключен к лидам: <BooleanField label="Подключен к лидам" source="connectedToLeads" TrueIcon={CheckCircleIcon} FalseIcon={PauseCircleOutlineIcon} /></h5>
                            </Box>
                            {localStorage.getItem('role_id') <= 2 && account.role >= localStorage.getItem('role_id') ?  
                            <EditButton /> 
                            : null}
                            </Box>
                        </ListItem>
                    </RecordContextProvider>
                ))}
            </List>}
        </>
    );
};



/*const AccountListContent = () => {
    const {
        data: accounts,
        isLoading,
        onToggleItem,
        selectedIds,
    } = useListContext();

    if (isLoading) {
        return <SimpleListLoading hasLeftAvatarOrIcon hasSecondaryText />;
    }

    return (
        <>
            <BulkActionsToolbar>
                <BulkDeleteButton />
            </BulkActionsToolbar>

            <Datagrid>

                <ImageField source="avatar" label="Фото" />

                <TextField source="first_name" label="Имя" />
                <TextField source="last_name" label="Фамилия" />
                <TextField source="email" label="Email" />
                <TextField source="position" label="Название должности" />
                <TextField source="role" label="Роль в системе" />
                <TextField source="phone" label="Номер телефона" />
                <TextField source="phone_extension" label="Добавочный номер" />

                <BooleanField label="Подключен к лидам" source="connectedToLeads" />

                {localStorage.getItem('username').includes("admin") && <EditButton />}
            </Datagrid>
        </>
    );
};*/

const AccountListActions = () => (
    <TopToolbar sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
        <Link to={`/workshift`}>
            <Button variant="contained" color="primary">
             График работы
            </Button>
        </Link>
        {localStorage.getItem('role_id') < 2 ? <CustomExportButton /> : null}
        <CreateButton
            variant="outlined"
            label="Новый пользователь"
            sx={{ marginLeft: 2 }}
        />
    </TopToolbar>
);

const AccountList = () => {
    const { identity } = useGetIdentity();
    return identity ? (
        <RaList
            actions={localStorage.getItem('role_id') <= 2 ? <AccountListActions /> : null}
            perPage={50}
            pagination={<Pagination rowsPerPageOptions={[50, 100, 500, 1000]} />}
            filters={[<FilterLiveSearch alwaysOn />]}
        >
            <AccountListContent />
        </RaList>
    ) : null;
};

export default AccountList;



/*import * as React from 'react';
import {
    List as RaList,
    Datagrid,
    TextField,
    BooleanField,
    BulkActionsToolbar,
    BulkDeleteButton,
    EditButton,
    SimpleListLoading,
    TopToolbar,
    CreateButton,
    ExportButton,
    Pagination,
    useGetIdentity
} from 'react-admin';
import {
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    ListItemAvatar,
    Checkbox,
    Switch,
} from '@mui/material';
import { useListContext, useRecordContext } from 'react-admin';
import { Link } from 'react-router-dom';
import { formatDistance } from 'date-fns';
import { ru } from 'date-fns/locale';
import { Avatar } from '../contacts/Avatar';
import '../crm.css';

const SwitchableBooleanField = ({ record, source }) => {
    const { onToggleItem, selectedIds } = useListContext();
    const isChecked = selectedIds.includes(record.id);

    return (
        <ListItemIcon>
            <Switch
                checked={isChecked}
                onClick={(e) => {
                    e.stopPropagation();
                    onToggleItem(record.id);
                }}
            />
        </ListItemIcon>
    );
};

const CustomList = () => {
    const { data: accounts, isLoading } = useListContext();

    if (isLoading) {
        return <SimpleListLoading hasLeftAvatarOrIcon hasSecondaryText />;
    }

    return (
        <List>
            <Datagrid>
                {accounts.map(account => (
                    <ListItem
                        key={account.id}
                        button
                        component={Link}
                        to={`/sales/${account.id}/show`}
                    >
    
                        <ListItemText
                            primary={`${account.first_name} ${account.last_name}`}
                            secondary={account.email}
                        />
                        <SwitchableBooleanField record={account} source="connectedToLeads" />
                    </ListItem>
                ))}
                
                <EditButton />
            </Datagrid>
        </List>
    );
};

const AccountListActions = () => (
    <TopToolbar>
        <ExportButton />
        <CreateButton
            variant="contained"
            label="Новый пользователь"
            sx={{ marginLeft: 2 }}
        />
    </TopToolbar>
);

const AccountList = () => {
    const { identity } = useGetIdentity();
    return identity ? (
        <RaList
            actions={localStorage.getItem('username').includes("admin") ? <AccountListActions /> : null}
            perPage={1000}
            pagination={<Pagination rowsPerPageOptions={[1000, 3000, 5000, 10000]} />}
        >
            <CustomList />
        </RaList>
    ) : null;
};

export default AccountList;*/


