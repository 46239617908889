import * as React from 'react';
import { useState } from 'react';
import {
    TextInput,
    ReferenceInput,
    ReferenceArrayInput,
    AutocompleteInput,
    AutocompleteArrayInput,
    BooleanInput,
    SelectInput, 
    useInput, required, ImageInput, ImageField,
    useRecordContext,
    useListContext
} from 'react-admin';
import { Divider, Box, Grid  } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';

export const RequestIncomeSourceInputs = () => {

    return (<Box flex="1" mt={-1}>

        <h3>Канал получения заявок</h3>

        <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
                <TextInput label="Название канала" source="name" fullWidth validate={required()} />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextInput label="id канала (для интеграций, латиницей)" source="id" helperText="Оставьте пустым чтобы содержимое сгенерировалось автоматически" fullWidth />
            </Grid>
        </Grid>

    </Box>);
};
