import * as React from 'react';
import { Box, Typography } from '@mui/material';
import { Droppable } from 'react-beautiful-dnd';
import { Identifier } from 'react-admin';

import { TaskCard } from './TaskCard';

export const TaskColumn = ({
    stage,
    taskIds,
    data,
}) => (
    <Box
        sx={{
            flex: 1,
            paddingTop: '8px',
            paddingBottom: '16px',
        }}
    >
        <Typography
            align="center"
            variant="subtitle1"
            sx={{
                position: 'relative',
                marginBottom: '15px', 
                '&::after': {
                    content: '""',
                    position: 'absolute',
                    left: '10%',
                    right: '10%', 
                    bottom: '-10px',
                    height: '6px',
                    borderRadius: '3px',
                    backgroundColor: stage.color, 
                },
            }}
        >
            {stage.name} ({taskIds.length})
        </Typography>
        <Droppable droppableId={stage.id}> 
            {(droppableProvided, snapshot) => (
                <Box
                    ref={droppableProvided.innerRef}
                    {...droppableProvided.droppableProps}
                    className={snapshot.isDraggingOver ? ' isDraggingOver' : ''}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        borderRadius: 5,
                        padding: '5px',
                        '&.isDraggingOver': {
                            bgcolor: '#dadadf',
                        },
                    }}
                >
                    {taskIds.map((id, index) => (
                        <TaskCard key={id} index={index} task={data[id]} stageColor={stage.color} />
                    ))}
                    {droppableProvided.placeholder}
                </Box>
            )}
        </Droppable>
    </Box>
);
