/*import React from 'react';
import { Create, SimpleForm, TextInput, DateInput } from 'react-admin';

const CompaniesCreate = (props) => {
	return <Create title='Create a Post' {...props}>
	<SimpleForm>
		<TextInput source='title'/>
        <TextInput multiline source='body'/>
        <DateInput label='Published' source='publishedAt'/>
	</SimpleForm>
	</Create>;
}

export default CompaniesCreate;*/

import * as React from 'react';
import { Create, Form, Toolbar } from 'react-admin';
import BusinessIcon from '@mui/icons-material/Business';
import { CardContent, Stack, Avatar, Box } from '@mui/material';

import { CompanyForm } from './CompanyForm';

const CompaniesCreate = () => (
    <Create actions={false} redirect="show">
        <Form>
            <CardContent>
                <Stack direction="row">
                    <Avatar sx={{ mt: 1 }}>
                        <BusinessIcon />
                    </Avatar>
                    <Box ml={2} flex="1" maxWidth={796} mb={10}>
                        <CompanyForm />
                    </Box>
                </Stack>
            </CardContent>
            <Toolbar />
        </Form>
    </Create>
);

export default CompaniesCreate;

