import * as React from 'react';
import {
    List as RaList,
    TopToolbar,
    ExportButton,
    CreateButton,
    Pagination,
    useGetIdentity,
} from 'react-admin';

import { ImageList } from './GridList';
import { CompanyListFilter } from './CompanyListFilter';
import '../crm.css';

const CompanyList = () => {
    const { identity } = useGetIdentity();
    if (!identity) return null;
    return (
        <RaList
            actions={<CompanyListActions />}
            aside={<CompanyListFilter className="adaptive-1"/>}
            filterDefaultValues={{ sales_id: identity?.id }}
            pagination={<Pagination rowsPerPageOptions={[1000, 3000, 5000, 10000]} />}
            perPage={1000}
            sort={{ field: 'updated_at', order: 'ASC' }}
            component="div"
        >
            <ImageList />
        </RaList>
    );
};

const CompanyListActions = () => {
    return (
        <TopToolbar>
            <ExportButton />
            <CreateButton
                variant="contained"
                label="Новая компания"
                sx={{ marginLeft: 2 }}
            />
        </TopToolbar>
    );
};

export default CompanyList;