import * as React from 'react';
import { useState, useEffect } from 'react';
import {
    TextInput,
    ReferenceInput,
    AutocompleteInput,
    BooleanInput,
    useInput, required, ImageInput, ImageField,
    SelectInput,
    DateInput,
    SimpleFormIterator,
    ArrayInput,
    useEditContext,
    useDataProvider
} from 'react-admin';
import { Divider, Box, Grid, Typography  } from '@mui/material';
import { types } from '../contacts/types';

export const ContragentInputs = () => {
    const editContext = useEditContext();
    const dataProvider = useDataProvider();
    const [selectedType, setSelectedType] = useState();
    const [selectedManager, setSelectedManager] = useState();

    const handleTypeChange = (event) => {
        setSelectedType(event.target.value);
    };

    useEffect(() => {
        const initialType = editContext && editContext.record ? editContext.record.type : null;
        setSelectedType(initialType);
    }, [editContext.record]); 

    return (<Box flex="1" mt={-1}>

        <h3>Данные контрагента</h3>

        <SelectInput
                label="Тип контрагента"
                source="type"
                choices={types}
                validate={required()} 
                onChange={handleTypeChange}
                fullWidth 
                />

        {/* ФОРМА ДЛЯ ФИЗЛИЦА */}

        {selectedType === 'Физическое лицо' ?
         
        <Box flex="1">

         <h4>Основная информация</h4>

         <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
                <TextInput label="ФИО (полностью)" source="name" fullWidth validate={required()} />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextInput label="Гражданство" source="citizenship" helperText="Пример: Гражданин РФ" fullWidth />
            </Grid>
        </Grid>
        <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
                 <DateInput label="Дата рождения" source="born_date" fullWidth />
            </Grid>
            <Grid item xs={12} sm={6}>
               <TextInput label="Место рождения" source="born_place" helperText="Пример: г. Москва"  fullWidth />
            </Grid>
        </Grid>
        
        <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
                <TextInput label="Номер телефона (основной)" source="phone" fullWidth />
            </Grid>
            <Grid item xs={12} sm={6}>
                 <TextInput label="E-mail" source="email" fullWidth />
            </Grid>
        </Grid>

        <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
                <TextInput label="Номер телефона (дополнительный)" source="phone_dop" fullWidth />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextInput label="Описание доп. номера" source="phone_dop_description" fullWidth />
            </Grid>
        </Grid>

        <TextInput label="Дополнительные сведения (если требуются)" source="additional_info" fullWidth multiline rows={2} />


        <Divider />

        <h4>Дополнительные контакты</h4>

         <ArrayInput label="Доп. контактные лица клиента" source="dop_contacts">
                    <SimpleFormIterator inline disableClear>

                <TextInput label="ФИО (не обязательно полностью)" source="name_dop_contact"  />

                <TextInput label="Номер телефона" source="phone_dop_contact"  />

                 <TextInput label="Электронная почта" source="email_dop_contact" />

                <TextInput label="Описание доп. контакта" source="description_dop_contact" />

                        </SimpleFormIterator>
                </ArrayInput>

        <Divider />

        <h3>Данные документа</h3>

        <Grid container spacing={2}>
           <Grid item xs={12} sm={3}>
           <SelectInput
                label="Тип документа"
                source="document_type"
                choices={["Паспорт гражданина РФ", "Свидетельство о рождении гражданина РФ", "Временная регистрация"].map(type => ({ id: type, name: type }))}
                fullWidth 
            />
            </Grid>
            <Grid item xs={12} sm={3}>
               <TextInput label="Серия" source="document_series"  fullWidth />
            </Grid>
            <Grid item xs={12} sm={3}>
               <TextInput label="Номер" source="document_number"  fullWidth />
            </Grid>
            <Grid item xs={12} sm={3}>
                 <DateInput label="Дата выдачи" source="give_date" fullWidth />
            </Grid>
        </Grid>

        <Grid container spacing={2}>
            <Grid item xs={12} sm={8}>
                <TextInput label="Кем выдан" source="document_given_by" fullWidth />
            </Grid>
            <Grid item xs={12} sm={4}>
                <TextInput label="Код подразделения" source="document_department_code" fullWidth />
            </Grid>
        </Grid>

        <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
                <TextInput label="ИНН" source="document_inn" fullWidth />
            </Grid>
            <Grid item xs={12} sm={4}>
                <TextInput label="СНИЛС" source="document_snils" fullWidth />
            </Grid>
            <Grid item xs={12} sm={4}>
                <TextInput label="Дополнительно" source="document_additional_info" fullWidth multiline />
            </Grid>
        </Grid>

        <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
                <TextInput label="Адрес регистрации" source="document_address" fullWidth multiline />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextInput label="Фактический адрес" source="fact_address" fullWidth multiline />
            </Grid>
        </Grid>

        </Box> : null}



        {/* ФОРМА ДЛЯ ИП */}

        {selectedType === 'ИП' ?
         
        <Box flex="1">

         <h4>Основная информация</h4>

         <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
                <TextInput label="ФИО (полностью)" source="name" fullWidth validate={required()} />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextInput label="Гражданство" source="citizenship" helperText="Пример: Гражданин РФ" fullWidth />
            </Grid>
        </Grid>
        <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
                 <DateInput label="Дата рождения" source="born_date" fullWidth />
            </Grid>
            <Grid item xs={12} sm={6}>
               <TextInput label="Место рождения" source="born_place" helperText="Пример: г. Москва"  fullWidth />
            </Grid>
        </Grid>

        <TextInput label="ИП Действующий на основании" source="act_basis" helperText="Пример: Свидетельства о государственной регистрации физического лица в качестве индивидуального предпринимателя от 12.05.2022 г."  fullWidth />
        
        <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
                <TextInput label="Номер телефона (основной)" source="phone" fullWidth />
            </Grid>
            <Grid item xs={12} sm={6}>
                 <TextInput label="E-mail" source="email" fullWidth />
            </Grid>
        </Grid>

        <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
                <TextInput label="Номер телефона (дополнительный)" source="phone_dop" fullWidth />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextInput label="Описание доп. номера" source="phone_dop_description" fullWidth />
            </Grid>
        </Grid>

        <TextInput label="Дополнительные сведения (если требуются)" source="additional_info" fullWidth multiline rows={2} />


        <Divider />

        <h4>Дополнительные контакты</h4>

         <ArrayInput label="Доп. контактные лица клиента" source="dop_contacts">
                    <SimpleFormIterator inline disableClear>

                <TextInput label="ФИО (не обязательно полностью)" source="name_dop_contact"  />

                <TextInput label="Номер телефона" source="phone_dop_contact"  />

                 <TextInput label="Электронная почта" source="email_dop_contact" />

                 <TextInput label="Описание доп. контакта" source="description_dop_contact" />

                        </SimpleFormIterator>
                </ArrayInput>

        <Divider />

        <h3>Данные документа</h3>

        <Grid container spacing={2}>
           <Grid item xs={12} sm={3}>
           <SelectInput
                label="Тип документа"
                source="document_type"
                choices={["Паспорт гражданина РФ", "Свидетельство о рождении гражданина РФ", "Временная регистрация"].map(type => ({ id: type, name: type }))}
                fullWidth 
            />
            </Grid>
            <Grid item xs={12} sm={3}>
               <TextInput label="Серия" source="document_series"  fullWidth />
            </Grid>
            <Grid item xs={12} sm={3}>
               <TextInput label="Номер" source="document_number"  fullWidth />
            </Grid>
            <Grid item xs={12} sm={3}>
                 <DateInput label="Дата выдачи" source="give_date" fullWidth />
            </Grid>
        </Grid>

        <Grid container spacing={2}>
            <Grid item xs={12} sm={8}>
                <TextInput label="Кем выдан" source="document_given_by" fullWidth />
            </Grid>
            <Grid item xs={12} sm={4}>
                <TextInput label="Код подразделения" source="document_department_code" fullWidth />
            </Grid>
        </Grid>

        <Grid container spacing={2}>
            <Grid item xs={12} sm={3}>
                <TextInput label="ОГРНИП" source="document_ogrnip" fullWidth />
            </Grid>
            <Grid item xs={12} sm={3}>
                <TextInput label="ИНН" source="document_inn" fullWidth />
            </Grid>
            <Grid item xs={12} sm={3}>
                <TextInput label="СНИЛС" source="document_snils" fullWidth />
            </Grid>
            <Grid item xs={12} sm={3}>
                <TextInput label="Дополнительно" source="document_additional_info" fullWidth multiline />
            </Grid>
        </Grid>

        <Grid container spacing={2}>
            <Grid item xs={12} sm={3}>
                <TextInput label="БИК" source="document_bik" fullWidth />
            </Grid>
            <Grid item xs={12} sm={3}>
                <TextInput label="В банке" source="document_bank" fullWidth />
            </Grid>
            <Grid item xs={12} sm={3}>
                <TextInput label="Расчетный счет" source="document_payment_account" fullWidth />
            </Grid>
            <Grid item xs={12} sm={3}>
                <TextInput label="Кор. счет" source="document_correspondent_account" fullWidth multiline />
            </Grid>
        </Grid>

        <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
                <TextInput label="Адрес регистрации" source="document_address" fullWidth multiline />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextInput label="Фактический адрес" source="fact_address" fullWidth multiline />
            </Grid>
        </Grid>

        </Box> : null}
        


        {/* ФОРМА ДЛЯ ЮРЛИЦА */}

        {selectedType === 'Юридическое лицо' ?
         
        <Box flex="1">

         <h4>Основная информация</h4>

         <TextInput label="Полное наименование" source="name" fullWidth validate={required()} />

         <TextInput label="Сокращенное наименование (по уставу)" source="short_name" fullWidth validate={required()} />

         <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
                <TextInput label="ФИО руководителя" source="director_name" fullWidth validate={required()} />
            </Grid>
            <Grid item xs={12} sm={4}>
                <TextInput label="Должность руководителя" source="director_post" fullWidth validate={required()} />
            </Grid>
            <Grid item xs={12} sm={4}>
                <TextInput label="На основании" source="act_basis" helperText="Пример: Устава предприятия" fullWidth />
            </Grid>
        </Grid>

        <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
               <TextInput label="В лице ФИО" source="in_face_of_name" fullWidth />
            </Grid>
            <Grid item xs={12} sm={6}>
               <TextInput label="В лице должность" source="in_face_of_post" fullWidth />
            </Grid>
        </Grid>

        <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
               <TextInput label="В лице ФИО род. падеж" source="in_face_of_name_rod" fullWidth />
            </Grid>
            <Grid item xs={12} sm={6}>
               <TextInput label="В лице должность род. падеж" source="in_face_of_post_rod" fullWidth />
            </Grid>
        </Grid>

        <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
                <TextInput label="Номер телефона (основной)" source="phone" fullWidth />
            </Grid>
            <Grid item xs={12} sm={4}>
                 <TextInput label="E-mail" source="email" fullWidth />
            </Grid>
            <Grid item xs={12} sm={4}>
                 <TextInput label="Сайт" source="website" fullWidth />
            </Grid>
        </Grid>

        <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
                <TextInput label="Номер телефона (дополнительный)" source="phone_dop" fullWidth />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextInput label="Описание доп. номера" source="phone_dop_description" fullWidth />
            </Grid>
        </Grid>

        <TextInput label="Дополнительные сведения (если требуются)" source="additional_info" fullWidth multiline rows={2} />


        <Divider />

        <h4>Дополнительные контакты</h4>

         <ArrayInput label="Доп. контактные лица клиента" source="dop_contacts">
                    <SimpleFormIterator inline disableClear>

                <TextInput label="ФИО (не обязательно полностью)" source="name_dop_contact"  />

                <TextInput label="Номер телефона" source="phone_dop_contact"  />

                 <TextInput label="Электронная почта" source="email_dop_contact" />

                  <TextInput label="Описание доп. контакта" source="description_dop_contact" />

                        </SimpleFormIterator>
                </ArrayInput>

        <Divider />

        <h3>Реквизиты юрлица</h3>

        <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
                <TextInput label="ИНН" source="document_inn" fullWidth />
            </Grid>
            <Grid item xs={12} sm={4}>
                <TextInput label="КПП" source="document_kpp" fullWidth />
            </Grid>
            <Grid item xs={12} sm={4}>
                <TextInput label="ОГРН" source="document_ogrn" fullWidth />
            </Grid>
        </Grid>

        <Grid container spacing={2}>
            <Grid item xs={12} sm={3}>
                <TextInput label="БИК" source="document_bik" fullWidth />
            </Grid>
            <Grid item xs={12} sm={3}>
                <TextInput label="В банке" source="document_bank" fullWidth />
            </Grid>
            <Grid item xs={12} sm={3}>
                <TextInput label="Расчетный счет" source="document_payment_account" fullWidth />
            </Grid>
            <Grid item xs={12} sm={3}>
                <TextInput label="Кор. счет" source="document_correspondent_account" fullWidth multiline />
            </Grid>
        </Grid>

        <TextInput label="Юридический адрес" source="document_address" fullWidth multiline />
        <TextInput label="Фактический адрес" source="fact_address" fullWidth multiline />

        </Box> : null}

    </Box>);
};

const Spacer = () => <Box width={20} component="span" />;
