import * as React from 'react';
import { useState, FormEvent, ChangeEvent } from 'react';
import {
    TextField,
    ReferenceField,
    DateField,
    useResourceContext,
    useDelete,
    useUpdate,
    useCreate,
    useNotify,
    useRedirect,
    useRecordContext,
    useListContext
} from 'react-admin';
import {
    Box,
    Typography,
    Tooltip,
    IconButton,
    FilledInput,
    Button,
    Link,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import TrashIcon from '@mui/icons-material/Delete';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { Status } from '../misc/Status';
import { endpoint } from '../App';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';

export const Document = ({
    note,
    reference,
}) => {
    const [isHover, setHover] = useState(false);
    const [isEditing, setEditing] = useState(false);
    const [noteText, setNoteText] = useState(note.comment);
    const [taskAnswer, setTaskAnswer] = useState(note.task_answer);
    const { refetch } = useListContext();
    const redirect = useRedirect();
    const resource = useResourceContext();
    const record = useRecordContext();
    const notify = useNotify();

    const [update, { isLoading }] = useUpdate();

    const [deleteNote] = useCreate(
        'delete',
        { id: note.id, 
          data:{ id: note.id, table: resource, 
          document_number: note.document_number,
          document_type: note.document_type,
          file_name: note.file_name, 
          file_path: note.file_path,
          deal_id: record.id, 
          deal_number: record.number,
          ap_id: localStorage.getItem('id'),
          ap_name: localStorage.getItem('first_name') + " " + localStorage.getItem('last_name'),
          },
          previousData: note
        },
        {
            mutationMode: 'pessimistic',
            onSuccess: () => {
                notify('Документ удален', { type: 'info', undoable: false });
                refetch();
            },
        }
    );

    const handleDelete = () => {
        deleteNote();
    };

    const redirectToDocumentEdit = () => {
        window.open(`${window.location.origin}/#/document_edit?doc_path=${note.file_path}`, '_blank');
    };

    return (
        <Box
            mb={2}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
        >
            <Box mb={1} color="text.secondary" flex="1" display="flex" flexDirection="row" alignItems="center">
                <ReferenceField
                    record={note}
                    resource="documents"
                    source="ap_id"
                    reference="sales"
                    link={(record, reference) => `/${reference}/${record.id}/show`}
                >
                {' '}<TextField source="first_name" />{' '}
                <TextField source="last_name" />
                </ReferenceField>{', '}
                <DateField
                    source="created_at"
                    record={note}
                    variant="body1"
                    showTime
                    locales="ru"
                    options={{
                        dateStyle: 'full',
                        timeStyle: 'short',
                    }}
                />
            </Box>
                <Box
                    sx={{
                        //bgcolor: '#edf3f0',
                        padding: '0 1em',
                        borderRadius: '10px',
                        display: 'flex',
                        alignItems: 'stretch',
                        marginBottom: 1,
                    }}
                >
                <InsertDriveFileIcon color="primary" />
                <Box flex={1} flexDirection="row">
                    { note.file_name ? 
                        <Box flex={1} ml={1}>
                            <Link href={`${endpoint}/${note.file_path}`} target="_blank" rel="noopener">{note.file_name}</Link>
                        </Box> : null }
                    </Box>
                    <Box
                        sx={{
                            marginLeft: 2,
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-around',
                            visibility: isHover ? 'visible' : 'hidden',
                        }}
                    >
                        {/* note.ap_id === localStorage.getItem('id') || localStorage.getItem('role_id') <= 2 ? <Tooltip title={"Редактировать документ"}>
                            <IconButton
                                size="small"
                                onClick={redirectToDocumentEdit}
                            >
                                <EditIcon />
                            </IconButton>
                        </Tooltip> : null*/}
                        { localStorage.getItem('role_id') < 2 ? <Tooltip title="Удалить документ" >
                            <IconButton size="small" onClick={handleDelete}>
                                <TrashIcon />
                            </IconButton>
                        </Tooltip> : null}
                    </Box>
                </Box>
            
        </Box>
    );
};
