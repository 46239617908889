import * as React from 'react';

import { ReferenceInput, TextInput, SelectInput, required, ImageInput, ImageField } from 'react-admin';
import { Divider, Stack, Grid } from '@mui/material';

import { sectors } from './sectors';
import { sizes } from './sizes';

export const CompanyForm = () => (
    <>
        <TextInput label="Название" source="name" validate={required()} fullWidth />

        <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
                <SelectInput
                label="Сектор"
                source="sector"
                choices={sectors}
                fullWidth 
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <SelectInput
                label="Размер"
                source="size"
                choices={sizes}
                fullWidth 
                />
            </Grid>
        </Grid>
        <Divider sx={{ mb: 2, width: '100%' }} />

        <TextInput label="Реквизиты" source="requisites" fullWidth multiline />

        <TextInput label="Адрес" source="address" fullWidth helperText={false} />
        <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
                <TextInput label="Город" fullWidth source="city" />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextInput label="Почтовый индекс" fullWidth source="zipcode" />
            </Grid>
        </Grid>
        <Divider sx={{ mb: 2, width: '100%' }} />

        <TextInput label="Веб-сайт" source="website" fullWidth helperText={false} />
        <TextInput label="Ссылка на соцсеть" source="social" fullWidth helperText={false} />
        <ImageInput source="picture" label="Логотип" accept="image/*">
            <ImageField source="src" title="title" />
        </ImageInput>
        <Divider sx={{ mb: 2, width: '100%' }} />

         <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
                <TextInput
                    label="Номер телефона"
                    source="phone_number"
                    helperText={false}
                    fullwidth
                />
            </Grid>
            <Grid item xs={12} sm={6}>
            <ReferenceInput source="sales_id" reference="sales" fullwidth>
                <SelectInput
                    label="Менеджер"
                    helperText={false}
                    optionText={(sales: any) =>
                        `${sales.first_name} ${sales.last_name} ${sales.username}`
                    }
                    fullwidth
                />
            </ReferenceInput>
            </Grid>
        </Grid>
    </>
);
