import * as React from 'react';
import { useState } from 'react';
import {
    ShowBase,
    TextField,
    ReferenceField,
    ReferenceManyField,
    ReferenceArrayField,
    SingleFieldList,
    ChipField,
    useShowContext,
    EditButton,
    DeleteButton,
    Datagrid,
    DateField,
    SimpleList,
    Pagination,
    useDataProvider,
    useCreate,
    useRedirect,
    useNotify,
    useUpdate
} from 'react-admin';
import { Box, Card, CardContent, Typography, Link, Button,  FilledInput, } from '@mui/material';
import '../crm.css';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import UpdateIcon from '@mui/icons-material/Update';
import DeleteIcon from '@mui/icons-material/Delete';

const TaskShow = () => (
     <ShowBase>
        <TaskShowContent />
     </ShowBase>
);

const TaskShowContent = () => {
    const { record, isLoading } = useShowContext();
    const redirect = useRedirect();
    const notify = useNotify();
    const [taskAnswer, setTaskAnswer] = useState();
    const [update] = useUpdate();

    const [deleteNote] = useCreate(
        'delete',
        { id: record.id, 
          data:{ id: record.id, 
          table: 'requestsHistory', 
          comment: record.comment,
          comment_type: record.comment_type,
          request_id: record.request_id, 
          request_number: record.request_number,
          ap_id: localStorage.getItem('id'),
          ap_name: localStorage.getItem('first_name') + " " + localStorage.getItem('last_name'),
          },
          previousData: record
        },
        {
            mutationMode: 'undoable',
            onSuccess: () => {
                notify('Задача удалена', { type: 'info', undoable: true });
                redirect(`/requestsHistory`);
            },
        }
    );

    const handleDelete = () => {
        deleteNote();
    };

    const handleAnswerChange = (event) => {
        setTaskAnswer(event.target.value);
    };

    const handleAddTaskAnswer = (event) => {
        event.preventDefault();
        update(
            'requestsHistory',
            { id: record.id, 
              data: { id: record.id, created_at: record.created_at, request_id: record.request_id,
          request_number: record.request_number, comment: record.comment, comment_type: record.comment_type, task_answer: taskAnswer, task_done: true, ap_id: localStorage.getItem('id'), ap_name: localStorage.getItem('first_name') + " " + localStorage.getItem('last_name'), }, previousData: record },
            {
                onSuccess: () => {
                   notify('Задача завершена', { type: 'info', undoable: true });
                   redirect(`/requestsHistory`);
                },
            }
        );
    };

    if (isLoading || !record) return null;
    return (
        <Box mt={2} display="flex" flexDirection="column" >
            <Box flex="1">
                { !record.task_done && (localStorage.getItem('id') === record.ap_id || localStorage.getItem('id') === record.task_manager_id ||  localStorage.getItem('role_id') < 3 ) ? 
                <EditButton label="Редактировать задачу" /> : null}
                <Card>
                    <CardContent>
                        <Box display="flex">
                            <Box ml={2} flex="1">
                                <Typography variant="h5">
                                    {record.comment}
                                </Typography>
                            </Box>
                        </Box>

                        { record.task_done && record.task_answer ? <Box display="flex" style={{alignItems: 'center'}} flex="1" mt={2} >
                            <Box>
                                 <Typography variant="body2" style={{color: 'green'}}>Ответ на задачу:</Typography>
                            </Box>
                            <Box ml={1}>
								<Typography variant="h5">{record.task_answer}</Typography>
                            </Box>
                         </Box> : null}

                        {record.contact_name ? <Box display="flex" style={{alignItems: 'center'}} flex="1" mt={2} >
                            <Box>
                                <Typography variant="body2">Клиент:</Typography>
                            </Box>
                            <Box ml={1}>
								<Typography variant="body1">{record.contact_name}</Typography>
                            </Box>
                         </Box> : null}

						 {record.request_number ? <Box display="flex" style={{alignItems: 'center'}} flex="1" mt={2} >
                            <Box>
                                <Typography variant="body2">Номер заявки:</Typography>
                            </Box>
                            <Box ml={1}>
								<Typography variant="body1">{record.request_number}</Typography>
                            </Box>
                         </Box> : null}

                         {record.manager_name ? <Box display="flex" style={{alignItems: 'center'}} flex="1" mt={2} >
                            <Box>
                                <Typography variant="body2">Менеджер:</Typography>
                            </Box>
                            <Box ml={1}>
								<Typography variant="body1">{record.manager_name}</Typography>
                            </Box>
                         </Box> : null}

						 {record.task_type ? <Box display="flex" style={{alignItems: 'center'}} flex="1" mt={2} >
                            <Box>
                                 <Typography variant="body2">Тип задачи:</Typography>
                            </Box>
                            <Box ml={1}>
								<Typography variant="body1">{record.task_type}</Typography>
                            </Box>
                         </Box> : null}

                         {record.date_time ? <Box display="flex" style={{alignItems: 'center'}} flex="1" mt={2} >
                            <Box>
                                 <Typography variant="body2">Назначена на:</Typography>
                            </Box>
                            <Box ml={1}>
								<Typography variant="body1">{new Date(record.date_time).toLocaleString('ru-RU')}</Typography>
                            </Box>
                         </Box> : null}

                         {record.ap_name ? <Box display="flex" style={{alignItems: 'center'}} flex="1" mt={2} >
                            <Box>
                                 <Typography variant="body2">Кто назначил:</Typography>
                            </Box>
                            <Box ml={1}>
								<Typography variant="body1">{record.ap_name}</Typography>
                            </Box>
                         </Box> : null}

                    </CardContent>
                </Card>
            </Box>
            
            { !record.task_done && localStorage.getItem('id') === record.task_manager_id ? 
            <Box flex={1} mt={1}>
                <form onSubmit={handleAddTaskAnswer}>
                    <FilledInput
                        value={taskAnswer}
                        onChange={handleAnswerChange}
                        fullWidth
                        multiline
                        placeholder={"Ответ на задачу (если требуется)"}
                        sx={{
                            paddingTop: '16px',
                            paddingLeft: '14px',
                            paddingRight: '60px',
                            paddingBottom: '14px',
                            lineHeight: 1.3,
                        }}
                        autoFocus
                    />
                    <Box display="flex" justifyContent="flex-start" mt={1}>
                        <Button
                            type="submit"
                            color="primary"
                            variant="contained"
                            disabled={isLoading}
                        >
                            Завершить задачу
                        </Button>
                    </Box>
                </form>
                </Box> : null}
        </Box>
    );
};

export default TaskShow;