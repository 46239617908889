/*import * as React from 'react';
import { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import {
    CreateButton,
    ExportButton,
    FilterButton,
    List,
    SearchInput,
    SelectInput,
    TopToolbar,
    useGetIdentity,
    useRefresh,
    useGetList,
    Form,
    required,
    useListContext,
    RecordContextProvider,
    EditButton,
    Pagination,
    SimpleListLoading,
    ReferenceField,
    TextField,
    SimpleList,
    Loading,
    InfiniteList,
    ReferenceInput,
    AutocompleteInput,
    ReferenceArrayInput,
    ReferenceOneField,
    FunctionField
} from 'react-admin';
import { Link } from 'react-router-dom';
import { matchPath, useLocation } from 'react-router';
import { List as MUIList,ListItem, ListItemText, Box, Dialog, DialogContent, Typography, Divider, Link as MUILink, IconButton, useMediaQuery} from '@mui/material';
import { OnlyMineInput } from '../requests/OnlyMineInput';
import Button from '@mui/material/Button';
import * as XLSX from 'xlsx';
import { CallButton} from '../calls/CallButton';
import TableRowsIcon from '@mui/icons-material/TableRows';
import ViewWeekIcon from '@mui/icons-material/ViewWeek';
import FileUploadIcon from '@mui/icons-material/FileUpload';

// Custom export button component
const CustomExportButton = ({ resource, ...props }) => {
    const {
        data: requests,
        isLoading,
        onToggleItem,
        selectedIds,
    } = useListContext();

  const handleClick = () => {

    const ws = XLSX.utils.json_to_sheet(requests);

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Лист 1');
    XLSX.writeFile(wb, "Заявки - Выгрузка из Владелец CRM.xlsx");
  };

  return (
    <Button onClick={handleClick} label="Выгрузить в Excel" variant="outlined" size="small" color="success" startIcon={<FileUploadIcon />} sx={{ marginLeft: 2 }}>
      Выгрузить в Excel
    </Button>
  );
};

const SanctionsList = () => {
    const { identity } = useGetIdentity();
    const location = useLocation();
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const [currentManagerFilter, setCurrentManagerFilter] = useState();
    const [currentStageFilter, setCurrentStageFilter] = useState();
    var filtersObject = {manager_account_id: '', stage: ''};
    const listContentRef = useRef();
    const filters = [
        {id: 1, name: 'Обычное', order: 'DESC', field: 'updated_at'}, 
        {id: 2, name: 'С конца', order: 'ASC', field: 'updated_at'},
    ];
    const [currentFilter, setCurrentFilter] = useState(filters[0]);

    const handleFilterChange = (value) => {
        setCurrentFilter(filters.find((item) => item.id === value.target.value));
        if (listContentRef.current) {
            listContentRef.current.setSort1(filters.find((item) => item.id === value.target.value).field, filters.find((item) => item.id === value.target.value).order);
        }
    };

    useEffect(() => {
        const storedManagerFilter= localStorage.getItem('currentManagerFilter');
        if(storedManagerFilter){
            setCurrentManagerFilter(storedManagerFilter);
            filtersObject.manager_account_id = storedManagerFilter;
            filtersObject.stage = currentStageFilter;
            if (listContentRef.current) {
                listContentRef.current.setListFilter(filtersObject);
            }
        }
        if(storedManagerFilter == ""){
            setCurrentManagerFilter("");
            filtersObject.manager_account_id = "";
            filtersObject.stage = currentStageFilter;
            if (listContentRef.current) {
                listContentRef.current.setListFilter(filtersObject);
            }
        }
    }, [currentManagerFilter]);

    if(!identity) return null;

    const handleSelectedManagerFilterChange = (value) => {
        //setCurrentFilter(filters.find((item) => item.id === value.target.value));
        setCurrentManagerFilter(value);
        localStorage.setItem('currentManagerFilter', value);
        filtersObject.manager_account_id = value;
        filtersObject.stage = currentStageFilter;
        if (listContentRef.current) {
            listContentRef.current.setListFilter(filtersObject);
        }
    };

    return (
        <>
            <Box display="flex" flexDirection="column" >
            <List
            resource='sanctions'
            actions={<RequestsActions 
                isSmall={isSmall} 
                handleSelectedManagerFilterChange={handleSelectedManagerFilterChange}
                currentManagerFilter={currentManagerFilter} 
                filters={filters} 
                currentFilter={currentFilter} 
                handleFilterChange={handleFilterChange}
                />}
            perPage={50}
            filter={{'sales_id_for_requests': localStorage.getItem('id')}} 
            filters={ requestsFiltersForManagers}
            sort={{ field: 'updated_at', order: 'DESC' }}
            pagination={<Pagination rowsPerPageOptions={[25, 50, 100]} />}
            >
             <div style={{ marginBottom: '2rem' }}>
             <div style={{ position: 'absolute', top: 0, left: 0 }}>
             <Pagination rowsPerPageOptions={[25, 50, 100]} />
             </div>
             </div>
                <RequestListContent ref={listContentRef} />
            </List></Box> 
        </>
    );
};

const requestsFilters = [
    <SearchInput source="q" alwaysOn />,
    <OnlyMineInput alwaysOn />,
];
const requestsFiltersForManagers = [
     <SearchInput source="q" alwaysOn />,
];

const RequestsActions = ({isSmall, handleSelectedManagerFilterChange, currentManagerFilter, filters, currentFilter, handleFilterChange }) => {
    return (
        <TopToolbar sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
        
            <Form className='filterForm'>
            <SelectInput label="Отображение" source="view" choices={filters} defaultValue={currentFilter.id} onChange={handleFilterChange} validate={required()} helperText={null} />
            </Form>  

            {localStorage.getItem('role_id') <= 3 ?
            <Form className="tasks-views">
              <ReferenceInput source="manager_account_id" reference="sales" filter={{ 'sales_id_for_requests': localStorage.getItem('id') }}>
                  <AutocompleteInput label="Менеджер" defaultValue={currentManagerFilter} onChange={handleSelectedManagerFilterChange} optionText={(choice) => `${choice.first_name} ${choice.last_name}`} />
              </ReferenceInput>
            </Form> : null}

            {localStorage.getItem('role_id') < 2 ? <CustomExportButton /> : null}
        </TopToolbar>
    );
};

const RequestListContent = forwardRef(( props, ref) => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const {
        data: requests,
        isLoading, isFetching,
        onToggleItem,
        selectedIds,
        setFilters,
        setSort
    } = useListContext();

    const setListFilter = (filtersObject) => {
        setFilters(filtersObject, false);
    };

    useImperativeHandle(ref, () => ({
        setListFilter: (filtersObject) => setListFilter(filtersObject),
        setSort1: (field1, order1) => setSort1(field1, order1),
    }));

    const setSort1 = (field1, order1) => {
        setSort({ field: field1, order: order1 });
    };

    if (isLoading || isFetching) {
        return <Loading />
    }

    const now = Date.now();

    return (
        <>
             {isSmall ? 
                         <SimpleList
                            primaryText={request => request.task_time && request.task_time !== '' ? new Date(request.task_time).toLocaleString('ru-RU') : ''}
                            secondaryText={request => `Клиент: ${request.name}`}
                            tertiaryText={request => `Менеджер: ${request.manager_name}`}
                            linkType={"edit"}   
                         /> :
            <MUIList>
                {requests.map(request => { 

                    return(
                    <RecordContextProvider key={request.id} value={request}>
                        <ListItem
                            button
                            component={Link}
                            to={`/requests/${request.id}`}
                        >
                            <Box display="flex" flex={1} justifyContent="start" sx={{marginRight: '1rem'}}>
                            <ListItemText
                                primary={<Typography variant="body2">{`Дата создания: ${new Date(request.created_at).toLocaleString('ru-RU')}`}</Typography>}
                                secondary={request.updated_at ? `Дата обновления: ${new Date(request.updated_at).toLocaleString('ru-RU')}` : null}
                            />
                            </Box>
                            <Box display="flex" flex={3} justifyContent="start" sx={{marginRight: '1rem'}}>
                            <ListItemText
                                primary={<Typography sx={{ 
                                    border: `1.5px solid ${props.funnels.find((item) => item.id === request.funnel_id).stages.find((item) => item.id === request.stage)?.color}`,
                                    borderRadius: '0.5rem',
                                    padding: '0 0.5rem',
                                    width: 'fit-content'
                                }}>{props.funnels.find((item) => item.id === request.funnel_id).stages.find((item) => item.id === request.stage)?.name || ''}</Typography>}
                                secondary={props.funnels.find((item) => item.id === request.funnel_id)?.name || ''}
                            />
                            </Box>
                            <Box display="flex" flex={3} justifyContent="start" sx={{marginRight: '1rem'}}>
                            <ListItemText
                                primary={request.name}
                                secondary={request.manager_name}
                            />
                            </Box>
                            <Box display="flex" flex={3} justifyContent="start" sx={{marginRight: '1rem'}}>
                            <ListItemText
                                primary={<CallButton contact_phone={request.phone} type='link' confirmPopup={true} />}
                                secondary={<ReferenceOneField reference="deals" target="request_id">
                                    <FunctionField
                                        source="document_name"
                                        render={record => (
                                       
                                            <Link to={`/deals/${record.id}`}>
                                                <Button variant="contained" color="primary">
                                                К сделке №{record.document_name}
                                                </Button>
                                            </Link>
                                        )}
                                    />
                                </ReferenceOneField>}
                            />
                            </Box>
                        </ListItem>
                    </RecordContextProvider>
                );
                })}
            </MUIList>}
        </>
    );
});

export default SanctionsList;*/

import * as React from 'react';
import { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import {
    CreateButton,
    ExportButton,
    FilterButton,
    List as RaList,
    SearchInput,
    SelectInput,
    TopToolbar,
    useGetIdentity,
    useRefresh,
    useGetList,
    Form,
    required,
    useListContext,
    RecordContextProvider,
    EditButton,
    Pagination,
    SimpleListLoading,
    ReferenceField,
    TextField,
    SimpleList,
    Loading,
    InfiniteList,
    ReferenceInput,
    AutocompleteInput,
    ReferenceArrayInput,
    ReferenceOneField,
    FunctionField
} from 'react-admin';
import {
    List,
    ListItem,
    ListItemAvatar,
    ListItemIcon,
    ListItemSecondaryAction,
    ListItemText,
    Checkbox,
    Typography,
    Box
} from '@mui/material';
import { Link } from 'react-router-dom';
import '../crm.css';
import { ColorField, ColorInput } from 'react-admin-color-picker';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import UpdateIcon from '@mui/icons-material/Update';
import DeleteIcon from '@mui/icons-material/Delete';
import WatchOffIcon from '@mui/icons-material/WatchOff';
import RunningWithErrorsIcon from '@mui/icons-material/RunningWithErrors';
import InfoIcon from '@mui/icons-material/Info';
import Button from '@mui/material/Button';
import * as XLSX from 'xlsx';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { statuses } from './statuses';

// Custom export button component
const CustomExportButton = ({ resource, ...props }) => {
    const {
        data: requests,
        isLoading,
        onToggleItem,
        selectedIds,
    } = useListContext();

  const handleClick = () => {

    const ws = XLSX.utils.json_to_sheet(requests);

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Лист 1');
    XLSX.writeFile(wb, "Заявки - Выгрузка из Владелец CRM.xlsx");
  };

  return (
    <Button onClick={handleClick} label="Выгрузить в Excel" variant="outlined" size="small" color="success" startIcon={<FileUploadIcon />} sx={{ marginLeft: 2 }}>
      Выгрузить в Excel
    </Button>
  );
};

export const getIconByAction = (action) => {
    switch (action) {
        case 'Create':
            return <AddCircleIcon style={{ color: 'green' }} />;
        case 'Update':
            return <UpdateIcon style={{ color: '#FF8100' }} />;
        case 'Delete':
            return <DeleteIcon style={{ color: 'red' }} />;
        case 'Attention':
            return <RunningWithErrorsIcon style={{ color: '#FF8100' }} />;
        case 'Expired':
            return <WatchOffIcon style={{ color: 'red' }} />;
        default:
            return <InfoIcon style={{ color: 'grey' }} />;
    }
};

export const getGraphicsByStatus = (status) => {
    switch (status) {
        case 'unseen':
            return <Typography variant="body1">Неподтвержденный</Typography>;
        case 'accepted':
            return <Typography variant="body1">Подтверждено системой</Typography>;
        case 'Delete':
            return <DeleteIcon style={{ color: 'red' }} />;
        case 'Attention':
            return <RunningWithErrorsIcon style={{ color: '#FF8100' }} />;
        case 'Expired':
            return <WatchOffIcon style={{ color: 'red' }} />;
        default:
            return <InfoIcon style={{ color: 'grey' }} />;
    }
};

const TopFilters = [
    <SearchInput source="q" alwaysOn />,
];

const TopActions = ({handleSelectedManagerFilterChange, currentManagerFilter, filters, currentFilter, handleFilterChange }) => {
    return (
        <TopToolbar sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
        
            <Form className='filterForm'>
            <SelectInput label="Отображение" source="view" choices={filters} defaultValue={currentFilter.id} onChange={handleFilterChange} validate={required()} helperText={null} />
            </Form>  

            {localStorage.getItem('role_id') <= 3 ?
            <Form className="tasks-views">
              <ReferenceInput source="manager_account_id" reference="sales" filter={{ 'sales_id_for_requests': localStorage.getItem('id') }}>
                  <AutocompleteInput label="Менеджер" defaultValue={currentManagerFilter} onChange={handleSelectedManagerFilterChange} optionText={(choice) => `${choice.first_name} ${choice.last_name}`} />
              </ReferenceInput>
            </Form> : null}

            {localStorage.getItem('role_id') < 2 ? <CustomExportButton /> : null}
        </TopToolbar>
    );
};

const NotificationsListContent  = forwardRef(( props, ref) => {
    const {
        data: notifications,
        isLoading, isFetching,
        onToggleItem,
        selectedIds,
        setFilters,
        setSort
    } = useListContext();
    const setListFilter = (filtersObject) => {
        setFilters(filtersObject, false);
    };

    useImperativeHandle(ref, () => ({
        setListFilter: (filtersObject) => setListFilter(filtersObject),
        setSort1: (field1, order1) => setSort1(field1, order1),
    }));

    const setSort1 = (field1, order1) => {
        setSort({ field: field1, order: order1 });
    };

    if (isLoading || isFetching) {
        return <Loading />
    }

    return(
                <List>
                {notifications.map(notification => (
                    <RecordContextProvider key={notification.id} value={notification}>
                        <ListItem
                            button
                            component={Link}
                            to={`/requests/${notification.relation_id}`}
                        >

                            <Box display="flex" flex={5} justifyContent="start" alignItems="center" sx={{marginRight: '1rem'}}>
                            <ListItemIcon>{getIconByAction(notification.action)}</ListItemIcon>
                            <ListItemText
                                primary={notification.history}
                                secondary={`${notification.manager_name}, ${new Date(notification.date_time).toLocaleString()}`}
                            />
                            </Box>

                            <Box display="flex" flex={1} justifyContent="start" alignItems="center" sx={{marginRight: '1rem'}}>
                             <ListItemText
                                primary={<Typography variant="body2">Статус</Typography>}
                                secondary={new Date(notification.date_time) > new Date() ? 'Будущий (возможный)' : statuses.find(status => status.id === notification.status).name}
                            />
                            </Box>

                            <Box display="flex" flex={1} justifyContent="start" alignItems="center" sx={{marginRight: '1rem'}}>
                            <EditButton />
                            </Box>
                        </ListItem>
                    </RecordContextProvider>
                ))}
            </List>    
    );
});

const SanctionsList = () => {
    const { identity } = useGetIdentity();

    const [currentManagerFilter, setCurrentManagerFilter] = useState();
    const [currentStageFilter, setCurrentStageFilter] = useState();
    var filtersObject = {manager_account_id: '', stage: ''};
    const listContentRef = useRef();
    const filters = [
        {id: 1, name: 'Обычное', order: 'DESC', field: 'updated_at'}, 
        {id: 2, name: 'С конца', order: 'ASC', field: 'updated_at'},
    ];
    const [currentFilter, setCurrentFilter] = useState(filters[0]);

    const handleFilterChange = (value) => {
        setCurrentFilter(filters.find((item) => item.id === value.target.value));
        if (listContentRef.current) {
            listContentRef.current.setSort1(filters.find((item) => item.id === value.target.value).field, filters.find((item) => item.id === value.target.value).order);
        }
    };

    useEffect(() => {
        const storedManagerFilter= localStorage.getItem('currentManagerFilter');
        if(storedManagerFilter){
            setCurrentManagerFilter(storedManagerFilter);
            filtersObject.manager_account_id = storedManagerFilter;
            filtersObject.stage = currentStageFilter;
            if (listContentRef.current) {
                listContentRef.current.setListFilter(filtersObject);
            }
        }
        if(storedManagerFilter == ""){
            setCurrentManagerFilter("");
            filtersObject.manager_account_id = "";
            filtersObject.stage = currentStageFilter;
            if (listContentRef.current) {
                listContentRef.current.setListFilter(filtersObject);
            }
        }
    }, [currentManagerFilter]);

    if(!identity) return null;

    const handleSelectedManagerFilterChange = (value) => {
        //setCurrentFilter(filters.find((item) => item.id === value.target.value));
        setCurrentManagerFilter(value);
        localStorage.setItem('currentManagerFilter', value);
        filtersObject.manager_account_id = value;
        filtersObject.stage = currentStageFilter;
        if (listContentRef.current) {
            listContentRef.current.setListFilter(filtersObject);
        }
    };

    return identity ? (
     <RaList  
        title='Штрафы и санкции'
        perPage={50}
        pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100, 1000]} />}
        actions={<TopActions 
            handleSelectedManagerFilterChange={handleSelectedManagerFilterChange}
            currentManagerFilter={currentManagerFilter} 
            filters={filters} 
            currentFilter={currentFilter} 
            handleFilterChange={handleFilterChange}
            />}
        filters={TopFilters}
        sort={{ field: 'date_time', order: 'DESC' }}>
          <NotificationsListContent ref={listContentRef} /> 
     </RaList>     
   ) : null;
};

export default SanctionsList;