import * as React from 'react';
import {
    ShowBase,
    TextField,
    ReferenceField,
    ReferenceManyField,
    ReferenceArrayField,
    SingleFieldList,
    ChipField,
    useShowContext,
    EditButton,
    Datagrid,
    DateField,
    SimpleList,
    Pagination,
    FilterLiveSearch,
    List as RaList,
    useListContext,
    SimpleListLoading,
    RecordContextProvider,
    TopToolbar,
    ExportButton,
    FunctionField
} from 'react-admin';
import { Box, Card, CardContent, Typography, Link, ListItem, List, ListItemIcon, ListItemText, IconButton } from '@mui/material';
import { roles } from './roles';
import { Avatar } from '../contacts/Avatar';
import '../crm.css';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import UpdateIcon from '@mui/icons-material/Update';
import DeleteIcon from '@mui/icons-material/Delete';
import { Link as RouterLink } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

const AccountShow = () => (
    <ShowBase>
        <AccountShowContent />
    </ShowBase>
);

const AccountShowContent = () => {
    const { record, isLoading } = useShowContext();
    const navigate = useNavigate();
    if (isLoading || !record) return null;
    return (
        <Box mt={2} display="flex">
            <Box flex="1">
                 { localStorage.getItem('role_id') <= 2 && record.role >= localStorage.getItem('role_id') ?  <EditButton label="Редактировать пользователя" /> : null}
                <Card>
                    <CardContent>

                        <Box display="flex" flexDirection="row">
                                    <IconButton
                                        onClick={() => navigate(-1)}
                                        size="small"
                                        >
                                        <KeyboardBackspaceIcon />
                                    </IconButton>
                                    <Box display="flex">
                                        <Avatar />
                                        <Box ml={2} flex="1">
                                            <Typography variant="h5">
                                                {record.first_name} {record.last_name}
                                            </Typography>
                                            <Typography variant="body2">
                                                {record.username}
                                            </Typography>
                                        </Box>
                                    </Box>
                         </Box>

                         {record.email ? <Box display="flex" style={{alignItems: 'center'}} flex="1" mt={2} >
                            <Box>
                                 <Typography variant="body2">Email:</Typography>
                            </Box>
                            <Box ml={1}>
                                <Link href={`mailto:${record.email}`} target="_blank">{record.email}</Link>
                            </Box>
                         </Box> : null}

                         {record.telegram_username && record.telegram_username.includes('@') ? <Box display="flex" style={{alignItems: 'center'}} flex="1" mt={1}>
                            <Box>
                                 <Typography variant="body2">Telegram-аккаунт:</Typography>
                            </Box>
                            <Box ml={1}>
                                <Link href={`https://t.me/${record.telegram_username.replace(/@/g, '')}`} target="_blank">{record.telegram_username}</Link>
                            </Box>
                         </Box> : null}

                         {record.phone ? <Box display="flex" style={{alignItems: 'center'}} flex="1" mt={1} >
                            <Box>
                                 <Typography variant="body2">Номер телефона:</Typography>
                            </Box>
                            <Box ml={1}>
                                <Link href={`tel:${record.phone}`} target="_blank">{record.phone}</Link>
                            </Box>
                         </Box> : null}

                         {record.phone_extension ? <Box display="flex" style={{alignItems: 'center'}} flex="1" mt={1} >
                            <Box>
                                 <Typography variant="body2">Добавочный номер:</Typography>
                            </Box>
                            <Box ml={1}>
                                <Typography variant="body1">{record.phone_extension}</Typography>
                            </Box>
                         </Box> : null}

                         {record.role ? <Box display="flex" style={{alignItems: 'center'}} flex="1" mt={1} >
                            <Box>
                                 <Typography variant="body2">Роль в системе:</Typography>
                            </Box>
                            <Box ml={1}>
                                <Typography variant="body1">{roles.find(role => role.id === record.role).name}</Typography>
                            </Box>
                         </Box> : null}

                         {record.position ? <Box display="flex" style={{alignItems: 'center'}} flex="1" mt={1} >
                            <Box>
                                 <Typography variant="body2">Название должности:</Typography>
                            </Box>
                            <Box ml={1}>
                                <Typography variant="body1">{record.position}</Typography>
                            </Box>
                         </Box> : null}

                        {record.subordinates_ids && record.subordinates_ids.length > 0 ? <Box display="flex" style={{alignItems: 'center'}} flex="1" mt={1} >
                            <Box>
                                 <Typography variant="body2">Кем руководит:</Typography>
                            </Box>
                            <Box ml={1}>
                                    <ReferenceArrayField label="Кем руководит" reference="sales" source="subordinates_ids">
                                        <SingleFieldList linkType='show' >
                                            <FunctionField
                                                ml={1}
                                                render={record => (
                                                    `${record.first_name} ${record.last_name},`
                                                )}
                                            />
                                        </SingleFieldList>{'  '}
                                    </ReferenceArrayField>   
                            </Box>
                         </Box>: null}

                         {record.team_tasks_ids && record.team_tasks_ids.length > 0 ? <Box display="flex" style={{alignItems: 'center'}} flex="1" mt={1} >
                            <Box>
                                 <Typography variant="body2">Связан по задачам:</Typography>
                            </Box>
                            <Box ml={1}>
                                    <ReferenceArrayField label="Связан по задачам" reference="sales" source="team_tasks_ids">
                                        <SingleFieldList linkType='show' >
                                            <FunctionField
                                                ml={1}
                                                render={record => (
                                                    `${record.first_name} ${record.last_name},`
                                                )}
                                            />
                                        </SingleFieldList>{'  '}
                                    </ReferenceArrayField>   
                            </Box>
                         </Box>: null}

                         <Box display="flex" style={{alignItems: 'center'}} flex="1" mt={1} >
                            <Box>
                                 <Typography variant="body2">График работы:</Typography>
                            </Box>
                            <WorkingHours record={record} />
                         </Box>

                         { localStorage.getItem('role_id') <= 2 && record.username !== 'emilen' ? <Box mt={2}>
                            <Typography variant="h6">История действий</Typography>
                            <ReferenceManyField
                                label="Logs"
                                reference="logs"
                                target="ap_id"
                            >
                                <RaList  
                                    filter={{ ap_id: record.id}} 
                                    filters={[<FilterLiveSearch alwaysOn />]}  
                                    sort={{ field: 'date_time', order: 'DESC' }}
                                    pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100, 1000]} />}
                                    perPage={10}
                                    actions={<LogsListActions />}
                                >
                                   < LogsListContent />
                                </RaList>
                            </ReferenceManyField>
                        </Box> : null}
                           

                    </CardContent>
                </Card>
            </Box>
        </Box>
    );
};

const LogsListContent = () => {
    const {
        data: logs,
        isLoading,
        onToggleItem,
        selectedIds,
    } = useListContext();
    if (isLoading) {
        return <SimpleListLoading hasSecondaryText />;
    }

    return(
                <List>
                {logs.map(log => (
                    <RecordContextProvider key={log.id} value={log}>
                        <ListItem
                            button
                            component={RouterLink}
                            to={`/logs/${log.id}/show`}
                        >
                            <ListItemIcon>{getIconByAction(log.action)}</ListItemIcon>
                            <ListItemText
                                primary={log.history ? `${log.history}` : `${log.action} ${log.table}`}
                                secondary={new Date(log.date_time).toLocaleString()}
                            />
                        </ListItem>

                    </RecordContextProvider>
                ))}
            </List>    
    );
}
const getIconByAction = (action) => {
    switch (action) {
        case 'Create':
            return <AddCircleIcon style={{ color: 'green' }} />;
        case 'Update':
            return <UpdateIcon style={{ color: '#FF8100' }} />;
        case 'Delete':
            return <DeleteIcon style={{ color: 'red' }} />;
        default:
            return null;
    }
};

const LogsListActions = () => (
    <TopToolbar>
        {/*<FilterLiveSearch />*/}
        {/*<ExportButton />*/}
    </TopToolbar>
);

const WorkingHours = ({ record }) => {
    const daysOfWeek = [
      { key: 'monday', label: 'пн' },
      { key: 'tuesday', label: 'вт' },
      { key: 'wednesday', label: 'ср' },
      { key: 'thursday', label: 'чт' },
      { key: 'friday', label: 'пт' },
      { key: 'saturday', label: 'сб' },
      { key: 'sunday', label: 'вс' },
    ];
  
    const formatTime = (isoString) => {
      const date = new Date(isoString);
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');
      return `${hours}:${minutes}`;
    };
  
    return (
        <Box display="flex" flexDirection="column" ml={2}>
        {record.connectedToLeads ? (
          <>
            <Box display="flex">
              <Box display="flex" flexDirection="column">
                {daysOfWeek.map(({ key, label }) => (
                  <Box key={key} mb={1}>
                    <Typography variant="body2">{label}</Typography>
                  </Box>
                ))}
              </Box>
              <Box display="flex" flexDirection="column" ml={1}>
                {daysOfWeek.map(({ key }) => (
                  <Box key={key} mb={1}>
                    <Typography variant="body2">
                      {record[key]
                        ? `${formatTime(record[`${key}_start_time`])} - ${formatTime(record[`${key}_end_time`])}`
                        : '- выходной'}
                    </Typography>
                  </Box>
                ))}
              </Box>
            </Box>
          </>
        ) : (
          <Typography variant="body1">Отключен от лидов</Typography>
        )}
      </Box>
    );
};

export default AccountShow;