import * as React from 'react';
import {
    List as RaList,
    SimpleListLoading,
    ReferenceField,
    TextField,
    EditButton,
    ShowButton,
    useListContext,
    ExportButton,
    SortButton,
    TopToolbar,
    CreateButton,
    Pagination,
    useGetIdentity,
    BulkActionsToolbar,
    BulkDeleteButton,
    BulkExportButton,
    RecordContextProvider,
    BooleanField,
    DateField,
    BooleanInput,
    Datagrid,
    ImageField,
    SimpleList,
    FilterLiveSearch
} from 'react-admin';
import {
    List,
    ListItem,
    ListItemAvatar,
    ListItemIcon,
    ListItemSecondaryAction,
    ListItemText,
    Checkbox,
    Typography,
    Box, 
    useMediaQuery,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { formatDistance } from 'date-fns';
import { ru } from 'date-fns/locale';
import { Avatar } from '../contacts/Avatar';
import '../crm.css';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import Button from '@mui/material/Button';
import * as XLSX from 'xlsx';
import FileUploadIcon from '@mui/icons-material/FileUpload';

// Custom export button component
const CustomExportButton = ({ resource, ...props }) => {
    const {
        data: villages,
        isLoading,
        onToggleItem,
        selectedIds,
    } = useListContext();

  const handleClick = () => {

   /* const fieldOrder = [
      'id',
      'name',
      'description',
      'address',
      'cadastre',
      'size',
      'number'
    ];

    const reorderedDataToExport = villages.map((account) => {
      const reorderedAccount = {};
      fieldOrder.forEach((field) => {
       // reorderedAccount[field] = account[field];
           reorderedAccount[field] = Array.isArray(account[field]) ? account[field].join(', ') : account[field];
      });
      return reorderedAccount;
    });*/

    const ws = XLSX.utils.json_to_sheet(villages);

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Лист 1');
    XLSX.writeFile(wb, "Поселки - Выгрузка из Владелец CRM.xlsx");
  };

  return (
    <Button onClick={handleClick} label="Выгрузить в Excel" variant="outlined" size="small" color="success" startIcon={<FileUploadIcon />}>
      Выгрузить в Excel
    </Button>
  );
};

const VillageListContent = () => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const {
        data: villages,
        isLoading,
        onToggleItem,
        selectedIds,
    } = useListContext();
    if (isLoading) {
        return <SimpleListLoading hasLeftAvatarOrIcon hasSecondaryText />;
    }
    const now = Date.now();

    return (
        <>
           {/*<BulkActionsToolbar>
                
                <BulkDeleteButton />
            </BulkActionsToolbar>*/}
            {isSmall ? 
             <SimpleList
             primaryText={village => village.name}
             secondaryText={village => `Всего участков: ${village.estates.length}`}
             tertiaryText={village => `К продаже: ${village.estates.filter(est => est.stage_id === 'sale' || est.stage_id === 'cancelled').length}`}
             linkType={"show"}   
            /> :
            <List>
                {villages.map(village => (
                    <RecordContextProvider key={village.id} value={village}>
                        <ListItem
                            button
                            component={Link}
                            to={`/villages/${village.id}/show`}
                        >
                            <Box display="flex" flex={2} justifyContent="start" alignItems="center" sx={{marginRight: '1rem'}}>
                            <ListItemText
                                primary={village.name}
                                secondary={'Номер для документов: ' + village.number}
                            />
                            </Box>
                            <Box display="flex" flex={1} justifyContent="start" alignItems="center" sx={{marginRight: '1rem'}}>
                            <ListItemText
                                primary={`К продаже: ${village.estates.filter(est => est.stage_id === 'sale' || est.stage_id === 'cancelled').length}`}
                                secondary={`Всего участков: ${village.estates.length}`}
                            />
                            </Box>
                            <Box display="flex" flex={1} justifyContent="end" alignItems="center" sx={{marginRight: '1rem'}}>
                            {localStorage.getItem('role_id') <= 2 ?  <EditButton /> : null}
                            </Box>
                        </ListItem>
                    </RecordContextProvider>
                ))}
            </List>}
        </>
    );
};

const VillageListActions = () => (
    <TopToolbar sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
        {localStorage.getItem('role_id') < 2 ? <CustomExportButton /> : null}
        <CreateButton
            variant="outlined"
            label="Новый поселок"
            sx={{ marginLeft: 2 }}
        />
    </TopToolbar>
);

const VillageList = () => {
    const { identity } = useGetIdentity();
    return identity ? (
        <RaList
            actions={localStorage.getItem('role_id') <= 2 ? <VillageListActions /> : null}
            perPage={50}
            pagination={<Pagination rowsPerPageOptions={[50, 100, 500, 1000]} />}
            filters={[<FilterLiveSearch alwaysOn />]}
        >
            <VillageListContent />
        </RaList>
    ) : null;
};

export default VillageList;


