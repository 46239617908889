import * as React from 'react';
import { useState, useEffect, useContext, useRef, forwardRef, useImperativeHandle } from 'react';
import { Identifier, useListContext, DataProviderContext, useGetList, useRecordContext} from 'react-admin';
import { Box } from '@mui/material';
import { DragDropContext, OnDragEndResponder } from 'react-beautiful-dnd';
import isEqual from 'lodash/isEqual';

import { TaskColumn } from './TaskColumn';
import { tasksColumns } from './tasksColumns';
import { Deal } from '../types';

//������ ������ � ������� ����� ��� stages � �������� ��� ������ id ������ ��� ���������������� stage
//columns = {"opportunity":[],"proposal-sent":["ba31563a-7652-49c1-a337-2d0b018b44b6"],"in-negociation":["e93939d3-0839-49fa-b3c1-4b2751f56aa7"],"won":[],"lost":[],"delayed":[]}
/*const getTasksByColumn = (data, stages) => {
  const currentDate = new Date();
  const todayDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
  
  // Define function to check date against current date
  const getDateComparison = (date) => {
    const taskDate = new Date(date);
    if (taskDate < currentDate) {
      return 'overdue';
    } else if (taskDate >= todayDate && taskDate < new Date(todayDate.getTime() + 24 * 60 * 60 * 1000)) {
      return 'today';
    } else if (taskDate >= new Date(todayDate.getTime() + 24 * 60 * 60 * 1000) && taskDate < new Date(todayDate.getTime() + 2 * 24 * 60 * 60 * 1000)) {
      return 'tomorrow';
    } else {
      return 'future';
    }
  };

  // group tasks by column
  const columns = stages.reduce((acc, stage) => {
    acc[stage.id] = [];
    return acc;
  }, {});

  data.forEach(task => {
    const columnId = getDateComparison(task.date_time);
    if (columns[columnId]) {
      columns[columnId].push(task.id);
    }
  });
  //console.log(JSON.stringify(columns));
  return columns;
};*/
const getTasksByColumn = (data, stages) => {
    const currentDate = new Date();
    const todayDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());

    // Define function to check date against current date
    const getDateComparison = (date) => {
        const taskDate = new Date(date);
        if (taskDate < currentDate) {
            return 'overdue';
        } else if (taskDate >= todayDate && taskDate < new Date(todayDate.getTime() + 24 * 60 * 60 * 1000)) {
            return 'today';
        } else if (taskDate >= new Date(todayDate.getTime() + 24 * 60 * 60 * 1000) && taskDate < new Date(todayDate.getTime() + 2 * 24 * 60 * 60 * 1000)) {
            return 'tomorrow';
        } else {
            return 'future';
        }
    };

    // Group tasks by column
    const columns = stages.reduce((acc, stage) => {
        acc[stage.id] = [];
        return acc;
    }, {});

    // Fill columns with tasks
    data.forEach(task => {
        const columnId = getDateComparison(task.date_time);
        if (columns[columnId]) {
            columns[columnId].push(task.id);
        }
    });

    // Sort tasks within each column in ascending order
    Object.keys(columns).forEach(columnId => {
        columns[columnId].sort((taskIdA, taskIdB) => {
            const taskA = data.find(task => task.id === taskIdA);
            const taskB = data.find(task => task.id === taskIdB);
            return new Date(taskA.date_time) - new Date(taskB.date_time);
        });
    });

    return columns;
};

export const TaskListContent = forwardRef(( props, ref) => {
    const { data: unorderedTasks, isLoading, setFilters, refetch } = useListContext();
    const setListFilter = (field, value) => {
        var dynamicFilter = {};
        dynamicFilter[field] = value;
        setFilters(dynamicFilter);
    };

    useImperativeHandle(ref, () => ({
        setListFilter: (field, value) => setListFilter(field, value),
    }));

    const [data, setData] = useState(
        isLoading ? {} : unorderedTasks.reduce((obj, record) => ({ ...obj, [record.id]: record }), {})
    );
    const [deals, setDeals] = useState(
        isLoading ? tasksColumns.reduce((obj, stage) => ({ ...obj, [stage.id]: [] }),{}) : getTasksByColumn(unorderedTasks, tasksColumns)
    );
    // we use the raw dataProvider to avoid too many updates (which would create junk)
    const dataProvider = useContext(DataProviderContext);

    // update deals by columns when the dataProvider response updates
    useEffect(() => {
        if (isLoading) return;
        const newDeals = getTasksByColumn(unorderedTasks, tasksColumns);
        
        if (isEqual(deals, newDeals)) {
            return;
        }
        setDeals(newDeals);
        setData(unorderedTasks.reduce((obj, record) => ({ ...obj, [record.id]: record }), {}));

        
    }, [unorderedTasks, isLoading, tasksColumns]); // eslint-disable-line react-hooks/exhaustive-deps

    if (isLoading) return null;

    //������ �� �������, ��� ������� ��������� �������� ����� ���������
    const onDragEnd = async result => {/*
        const { destination, source, draggableId } = result;

        if (!destination) {
            return;
        }

        if (
            destination.droppableId === source.droppableId &&
            destination.index === source.index
        ) {
            return;
        }

        if (source.droppableId !== destination.droppableId) {
            // moving deal across columns

            const sourceColumn = Array.from(deals[source.droppableId]); // [4, 7, 23, 5] array of ids
            const destinationColumn = Array.from(
                deals[destination.droppableId]
            ); // [4, 7, 23, 5] arrays of ids
            const sourceDeal = data[sourceColumn[source.index]];
            const destinationDeal = data[destinationColumn[destination.index]]; // may be undefined if dropping at the end of a column

            // update local state
            sourceColumn.splice(source.index, 1);
            destinationColumn.splice(destination.index, 0, draggableId);
            setDeals({
                ...deals,
                [source.droppableId]: sourceColumn,
                [destination.droppableId]: destinationColumn,
            });

            // update backend
            // Fetch all the deals in both stages (because the list may be filtered, but we need to update even non-filtered deals)
            const [
                { data: sourceDeals },
                { data: destinationDeals },
            ] = await Promise.all([
                dataProvider.getList('requestsHistory', {
                    sort: { field: 'index', order: 'ASC' },
                    pagination: { page: 1, perPage: 1000 },
                    filter: { stage: source.droppableId },
                }),
                dataProvider.getList('requestsHistory', {
                    sort: { field: 'index', order: 'ASC' },
                    pagination: { page: 1, perPage: 1000 },
                    filter: { stage: destination.droppableId },
                }),
            ]);

            await Promise.all([
                // change the dragged deal to take the destination index and column
                dataProvider.update('requestsHistory', {
                    id: sourceDeal.id,
                    data: {
                        ...sourceDeal,
                        ap_id: localStorage.getItem('id'),
                        ap_name: localStorage.getItem('first_name') + " " + localStorage.getItem('last_name'),
                        stage: destination.droppableId,
                        
                    },
                    previousData: sourceDeal,
                }),
            ]);

            refetch();
        }*/
    };

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Box display="flex">
                {tasksColumns.map(column => (
                    <TaskColumn
                        stage={column}
                        taskIds={deals[column.id] || []}
                        data={data}
                        key={column.id}
                    />
                ))}
            </Box>
        </DragDropContext>
    );
});
