import { 
    Edit,
    EditBase,
    SimpleForm,
    TextInput,
    DateInput,
    ArrayInput,
    NumberInput,
    SimpleFormIterator,
    required,
    Form,
    Toolbar,
    useEditContext,
    useDataProvider,
    ShowButton,
    DeleteButton,
    SaveButton,
    useRedirect,
    CreateBase,
    useCreateContext,
    BooleanInput,
} from 'react-admin';
import { Divider, Box, Grid, Button, Card, CardContent } from '@mui/material';
import { ColorField, ColorInput } from 'react-admin-color-picker';
import { v4 as uuidv4 } from 'uuid';

const FunnelCreate = () => {
    return (
        <CreateBase
            redirect="list"
            mutationMode="optimistic"
            transform={(data) => ({
            ...data,
            updated_at: new Date(),
            ap_id: localStorage.getItem('id'),
            ap_name: localStorage.getItem('first_name') + " " + localStorage.getItem('last_name'),
            stages: data.stages.map((stage) => ({
                    ...stage,
                    id: stage.id || uuidv4().slice(0, 18), // Set default value for id if not provided
                })),
            })}
        >
            <Form>
                <Card>
                <CardContent>
                <h3>Создание воронки продаж</h3>
                <TextInput label="Название воронки" source="name" fullWidth validate={required()} />
                <TextInput label="Описание" source="description" fullWidth />
                <Divider />
                <ArrayInput label="Этапы" source="stages">
                    <SimpleFormIterator disableClear>
                        <ColorInput label="Цвет" source="color" picker="Sketch" defaultValue="#ddd" validate={required()} />
                        <TextInput label="id этапа (латиницей)" source="id" helperText="Оставьте пустым чтобы содержимое сгенерировалось автоматически" fullwidth/>
                        <TextInput label="Название этапа" source="name" helperText={false} validate={required()} fullwidth/>
                        <BooleanInput label="Работа над заявкой на этом этапе завершена" source="request_done" />
                    </SimpleFormIterator>
                </ArrayInput>
                </CardContent>
                <Toolbar />
                </Card>
            </Form>
        </CreateBase>
    );
}

export default FunnelCreate;