import * as React from 'react';
import { useState, useEffect, useContext, useRef, forwardRef, useImperativeHandle } from 'react';
import { Identifier, useListContext, DataProviderContext, useGetList, useRecordContext, Loading} from 'react-admin';
import { Box } from '@mui/material';
import { DragDropContext, OnDragEndResponder } from 'react-beautiful-dnd';
import isEqual from 'lodash/isEqual';

import { DealColumn } from './DealColumn';
//import { stages } from './stages';
import { Deal } from '../types';

/*const initialDeals = stages.reduce(
    (obj, stage) => ({ ...obj, [stage]: [] }),
    {}
);

const getDealsByColumn = (data) => {
    // group deals by column
    const columns = data.reduce(
        (acc, record) => {
            if (acc[record.stage]) {
                acc[record.stage].push(record);
            }else{
                acc[record.stage] = [record];
            }
            return acc;
        },
        stages.reduce((obj, stage) => ({ ...obj, [stage]: [] }), {})
    );
    // order each column by index
    stages.forEach(stage => {
        columns[stage] = columns[stage]
            .sort(
                (recordA, recordB) => recordA.index - recordB.index
            )
            .map((deal) => deal.id);
    });
    return columns;
};

const indexById = (data) =>
    data.reduce((obj, record) => ({ ...obj, [record.id]: record }), {});

export const DealListContent = () => {
    const { data: unorderedDeals, isLoading, refetch } = useListContext();

    const [data, setData] = useState(
        isLoading ? {} : indexById(unorderedDeals)
    );
    const [deals, setDeals] = useState(
        isLoading ? initialDeals : getDealsByColumn(unorderedDeals)
    );
    // we use the raw dataProvider to avoid too many updates (which would create junk)
    const dataProvider = useContext(DataProviderContext);

    // update deals by columns when the dataProvider response updates
    useEffect(() => {
        if (isLoading) return;
        const newDeals = getDealsByColumn(unorderedDeals);
        if (isEqual(deals, newDeals)) {
            return;
        }
        setDeals(newDeals);
        setData(indexById(unorderedDeals));
    }, [unorderedDeals, isLoading]); // eslint-disable-line react-hooks/exhaustive-deps

    if (isLoading) return null;

    const onDragEnd = async result => {
        const { destination, source, draggableId } = result;

        if (!destination) {
            return;
        }

        if (
            destination.droppableId === source.droppableId &&
            destination.index === source.index
        ) {
            return;
        }

        if (source.droppableId === destination.droppableId) {
            // moving deal inside the same column

            const column = Array.from(deals[source.droppableId]); // [4, 7, 23, 5] array of ids
            const sourceDeal = data[column[source.index]];
            const destinationDeal = data[column[destination.index]];

            // update local state
            // remove source deal from column
            column.splice(source.index, 1);
            // read source deal at destination
            column.splice(destination.index, 0, Number(draggableId));
            setDeals({
                ...deals,
                [source.droppableId]: column,
            });

            // update backend
            // Fetch all the deals in this stage (because the list may be filtered, but we need to update even non-filtered deals)
            const { data: columnDeals } = await dataProvider.getList('deals', {
                sort: { field: 'index', order: 'ASC' },
                pagination: { page: 1, perPage: 100 },
                filter: { stage: source.droppableId },
            });

            if (source.index > destination.index) {
                // deal moved up, eg
                // dest   src
                //  <------
                // [4, 7, 23, 5]

                await Promise.all([
                    // for all deals between destination.index and source.index, increase the index
                    ...columnDeals
                        .filter(
                            deal =>
                                deal.index >= destinationDeal.index &&
                                deal.index < sourceDeal.index
                        )
                        .map(deal =>
                            dataProvider.update('deals', {
                                id: deal.id,
                                data: { index: deal.index + 1 },
                                previousData: deal,
                            })
                        ),
                    // for the deal that was moved, update its index
                    dataProvider.update('deals', {
                        id: sourceDeal.id,
                        data: { index: destinationDeal.index },
                        previousData: sourceDeal,
                    }),
                ]);

                refetch();
            } else {
                // deal moved down, e.g
                // src   dest
                //  ------>
                // [4, 7, 23, 5]

                await Promise.all([
                    // for all deals between source.index and destination.index, decrease the index
                    ...columnDeals
                        .filter(
                            deal =>
                                deal.index <= destinationDeal.index &&
                                deal.index > sourceDeal.index
                        )
                        .map(deal =>
                            dataProvider.update('deals', {
                                id: deal.id,
                                data: { index: deal.index - 1 },
                                previousData: deal,
                            })
                        ),
                    // for the deal that was moved, update its index
                    dataProvider.update('deals', {
                        id: sourceDeal.id,
                        data: { index: destinationDeal.index },
                        previousData: sourceDeal,
                    }),
                ]);

                refetch();
            }
        } else {
            // moving deal across columns

            const sourceColumn = Array.from(deals[source.droppableId]); // [4, 7, 23, 5] array of ids
            const destinationColumn = Array.from(
                deals[destination.droppableId]
            ); // [4, 7, 23, 5] arrays of ids
            const sourceDeal = data[sourceColumn[source.index]];
            const destinationDeal = data[destinationColumn[destination.index]]; // may be undefined if dropping at the end of a column

            // update local state
            sourceColumn.splice(source.index, 1);
            destinationColumn.splice(destination.index, 0, draggableId);
            setDeals({
                ...deals,
                [source.droppableId]: sourceColumn,
                [destination.droppableId]: destinationColumn,
            });

            // update backend
            // Fetch all the deals in both stages (because the list may be filtered, but we need to update even non-filtered deals)
            const [
                { data: sourceDeals },
                { data: destinationDeals },
            ] = await Promise.all([
                dataProvider.getList('deals', {
                    sort: { field: 'index', order: 'ASC' },
                    pagination: { page: 1, perPage: 100 },
                    filter: { stage: source.droppableId },
                }),
                dataProvider.getList('deals', {
                    sort: { field: 'index', order: 'ASC' },
                    pagination: { page: 1, perPage: 100 },
                    filter: { stage: destination.droppableId },
                }),
            ]);

            await Promise.all([
                // decrease index on the deals after the source index in the source columns
                ...sourceDeals
                    .filter(deal => deal.index > sourceDeal.index)
                    .map(deal =>
                        dataProvider.update('deals', {
                            id: deal.id,
                            data: { index: deal.index - 1 },
                            previousData: deal,
                        })
                    ),
                // increase index on the deals after the destination index in the destination columns
                ...destinationDeals
                    .filter(deal =>
                        destinationDeal
                            ? deal.index >= destinationDeal.index
                            : false
                    )
                    .map(deal =>
                        dataProvider.update('deals', {
                            id: deal.id,
                            data: { index: deal.index + 1 },
                            previousData: deal,
                        })
                    ),
                // change the dragged deal to take the destination index and column
                dataProvider.update('deals', {
                    id: sourceDeal.id,
                    data: {
                        index: destinationDeal
                            ? destinationDeal.index
                            : destinationDeals.pop().index + 1,
                        stage: destination.droppableId,
                    },
                    previousData: sourceDeal,
                }),
            ]);

            refetch();
        }
    };

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Box display="flex">
                {stages.map(stage => (
                    <DealColumn
                        stage={stage}
                        dealIds={deals[stage]}
                        data={data}
                        key={stage}
                    />
                ))}
            </Box>
        </DragDropContext>
    );
};*/

//������ ������ � ������� ����� ��� stages � �������� ��� ������ id ������ ��� ���������������� stage
//columns = {"opportunity":[],"proposal-sent":["ba31563a-7652-49c1-a337-2d0b018b44b6"],"in-negociation":["e93939d3-0839-49fa-b3c1-4b2751f56aa7"],"won":[],"lost":[],"delayed":[]}
const getDealsByColumn = (data, stages, opposite = false) => {
  // group deals by column
  const columns = data.reduce(
    (acc, record) => {
      if (stages.find((stage) => stage.id === record.stage)) {
        if (acc[record.stage]) {
          acc[record.stage].push(record);
        } else {
          acc[record.stage] = [record];
        }
      }
      return acc;
    },
    stages.reduce((obj, stage) => ({ ...obj, [stage.id]: [] }), {})
  );
  // order each column by index
  stages.forEach((stage) => {
    if (columns[stage.id]) {
      columns[stage.id] = columns[stage.id]
        .sort((recordA, recordB) => recordA.index - recordB.index)
        .map((deal) => deal.id);
    }
  });

  // Sort tasks within each column in ascending order
  /*Object.keys(columns).forEach(columnId => {
    columns[columnId].sort((taskIdA, taskIdB) => {
        const taskA = data.find(task => task.id === taskIdA);
        const taskB = data.find(task => task.id === taskIdB);
        
        // Convert task_time to Date object or use created_at if task_time is null or ""
        const dateA = taskA.task_time ? new Date(taskA.task_time) : new Date(taskA.created_at);
        const dateB = taskB.task_time ? new Date(taskB.task_time) : new Date(taskB.created_at);

        return dateA - dateB;
    });
  });*/
  if(!opposite){
  Object.keys(columns).forEach(columnId => {
    columns[columnId].sort((taskIdA, taskIdB) => {
        const taskA = data.find(task => task.id === taskIdA);
        const taskB = data.find(task => task.id === taskIdB);
        
        // Convert task_time to Date object or use created_at if task_time is null or ""
        //const dateA = taskA.task_time ? new Date(taskA.task_time) : new Date(taskA.created_at);
        //const dateB = taskB.task_time ? new Date(taskB.task_time) : new Date(taskB.created_at);
        const dateA = taskA.task_time ? new Date(taskA.task_time) : (taskA.updated_at ? new Date(taskA.updated_at) : new Date(taskA.created_at));
        const dateB = taskB.task_time ? new Date(taskB.task_time) : (taskB.updated_at ? new Date(taskB.updated_at) : new Date(taskB.created_at));

        // First condition: Sort by request.task_time null or "", then by created_at in ascending order
        if (!taskA.task_time || taskA.task_time === "") {
            if (!taskB.task_time || taskB.task_time === "") {
                return dateA - dateB; // Sort by created_at in ascending order
            }
            return -1; // a comes before b
        }
        if (!taskB.task_time || taskB.task_time === "") {
            return 1; // b comes before a
        }

        const now = new Date(); // Get current time

        // Second condition: Sort by task_time before current time
        if (dateA < now) {
            if (dateB < now) {
                return dateA - dateB; // Sort by task_time in ascending order
            }
            return -1; // a comes before b
        }
        if (dateB < now) {
            return 1; // b comes before a
        }

        // Third condition: Sort by task_time after current time
        return dateA - dateB; // Sort by task_time in ascending order
    });
  });
}else{
    Object.keys(columns).forEach(columnId => {
        columns[columnId].sort((taskIdA, taskIdB) => {
            const taskA = data.find(task => task.id === taskIdA);
            const taskB = data.find(task => task.id === taskIdB);
            const dateA = taskA.task_time ? new Date(taskA.task_time) : (taskA.updated_at ? new Date(taskA.updated_at) : new Date(taskA.created_at));
            const dateB = taskB.task_time ? new Date(taskB.task_time) : (taskB.updated_at ? new Date(taskB.updated_at) : new Date(taskB.created_at));
    
            // First condition: Sort by request.task_time null or "", then by created_at in descending order
            if (!taskA.task_time || taskA.task_time === "") {
                if (!taskB.task_time || taskB.task_time === "") {
                    return dateB - dateA; // Sort by created_at in descending order
                }
                return 1; // b comes before a
            }
            if (!taskB.task_time || taskB.task_time === "") {
                return -1; // a comes before b
            }
    
            const now = new Date(); // Get current time
    
            // Second condition: Sort by task_time after current time in descending order
            if (dateA < now) {
                if (dateB < now) {
                    return dateB - dateA; // Sort by task_time in descending order
                }
                return 1; // b comes before a
            }
            if (dateB < now) {
                return -1; // a comes before b
            }
    
            // Third condition: Sort by task_time before current time in descending order
            return dateB - dateA; // Sort by task_time in descending order
        });
    });
}

  //console.log(JSON.stringify(columns));
  return columns;
};

export const DealListContent = forwardRef(( props, ref) => {
    const { data: unorderedDeals, isLoading, isFetching, refetch, setFilters, setSort} = useListContext();
    const setListFilter = (filtersObject) => {
        setFilters(filtersObject, undefined, false);
    };
    const setSort1 = (field1, order1) => {
        //setSort({ field: field1, order: order1 });

        const newDeals = getDealsByColumn(unorderedDeals, props.stages, order1 === "ASC");
        if (isEqual(deals, newDeals)) {
            return;
        }
        setDeals(newDeals);
        setData(unorderedDeals.reduce((obj, record) => ({ ...obj, [record.id]: record }), {}));
    };

    useImperativeHandle(ref, () => ({
        setListFilter: (filtersObject) => setListFilter(filtersObject),
        setSort1: (field1, order1) => setSort1(field1, order1),
    }));


    const [data, setData] = useState(
        isLoading ? {} : unorderedDeals.reduce((obj, record) => ({ ...obj, [record.id]: record }), {})
    );
    const [deals, setDeals] = useState(
        isLoading ? props.stages.reduce((obj, stage) => ({ ...obj, [stage.id]: [] }),{}) : getDealsByColumn(unorderedDeals, props.stages)
    );
    // we use the raw dataProvider to avoid too many updates (which would create junk)
    const dataProvider = useContext(DataProviderContext);

    // update deals by columns when the dataProvider response updates
    useEffect(() => {
        if (isLoading) return;
        const newDeals = getDealsByColumn(unorderedDeals, props.stages);
        if (isEqual(deals, newDeals)) {
            return;
        }
        setDeals(newDeals);
        setData(unorderedDeals.reduce((obj, record) => ({ ...obj, [record.id]: record }), {}));
    }, [unorderedDeals, isLoading, props.stages]); // eslint-disable-line react-hooks/exhaustive-deps

    if (isLoading) return null;

    //������ �� �������, ��� ������� ��������� �������� ����� ���������
    const onDragEnd = async result => {
        const { destination, source, draggableId } = result;

        if (!destination) {
            return;
        }

        if (
            destination.droppableId === source.droppableId &&
            destination.index === source.index
        ) {
            return;
        }

        if (source.droppableId === destination.droppableId) {/*
            // moving deal inside the same column

            const column = Array.from(deals[source.droppableId]); // [4, 7, 23, 5] array of ids
            const sourceDeal = data[column[source.index]];
            const destinationDeal = data[column[destination.index]];

            // update local state
            // remove source deal from column
            column.splice(source.index, 1);
            // read source deal at destination
            column.splice(destination.index, 0, Number(draggableId));
            setDeals({
                ...deals,
                [source.droppableId]: column,
            });

            // update backend
            // Fetch all the deals in this stage (because the list may be filtered, but we need to update even non-filtered deals)
            const { data: columnDeals } = await dataProvider.getList('requests', {
                sort: { field: 'index', order: 'ASC' },
                pagination: { page: 1, perPage: 1000 },
                filter: { stage: source.droppableId },
            });

            if (source.index > destination.index) {
                // deal moved up, eg
                // dest   src
                //  <------
                // [4, 7, 23, 5]

                await Promise.all([
                    // for all deals between destination.index and source.index, increase the index
                    ...columnDeals
                        .filter(
                            deal =>
                                deal.index >= destinationDeal.index &&
                                deal.index < sourceDeal.index
                        )
                        .map(deal =>
                            dataProvider.update('requests', {
                                id: deal.id,
                                data: { index: deal.index + 1 },
                                previousData: deal,
                            })
                        ),
                    // for the deal that was moved, update its index
                    dataProvider.update('requests', {
                        id: sourceDeal.id,
                        data: { index: destinationDeal.index },
                        previousData: sourceDeal,
                    }),
                ]);

                refetch();
            } else {
                // deal moved down, e.g
                // src   dest
                //  ------>
                // [4, 7, 23, 5]

                await Promise.all([
                    // for all deals between source.index and destination.index, decrease the index
                    ...columnDeals
                        .filter(
                            deal =>
                                deal.index <= destinationDeal.index &&
                                deal.index > sourceDeal.index
                        )
                        .map(deal =>
                            dataProvider.update('requests', {
                                id: deal.id,
                                data: { index: deal.index - 1 },
                                previousData: deal,
                            })
                        ),
                    // for the deal that was moved, update its index
                    dataProvider.update('requests', {
                        id: sourceDeal.id,
                        data: { index: destinationDeal.index },
                        previousData: sourceDeal,
                    }),
                ]);

                refetch();
            }*/
        } else {
            // moving deal across columns

            const sourceColumn = Array.from(deals[source.droppableId]); // [4, 7, 23, 5] array of ids
            const destinationColumn = Array.from(
                deals[destination.droppableId]
            ); // [4, 7, 23, 5] arrays of ids
            const sourceDeal = data[sourceColumn[source.index]];
            const destinationDeal = data[destinationColumn[destination.index]]; // may be undefined if dropping at the end of a column

            // update local state
            sourceColumn.splice(source.index, 1);
            destinationColumn.splice(destination.index, 0, draggableId);
            setDeals({
                ...deals,
                [source.droppableId]: sourceColumn,
                [destination.droppableId]: destinationColumn,
            });

            // update backend
            // Fetch all the deals in both stages (because the list may be filtered, but we need to update even non-filtered deals)
            const [
                { data: sourceDeals },
                { data: destinationDeals },
            ] = await Promise.all([
                dataProvider.getList('requests', {
                    sort: { field: 'index', order: 'ASC' },
                    pagination: { page: 1, perPage: 1000 },
                    filter: { stage: source.droppableId },
                }),
                dataProvider.getList('requests', {
                    sort: { field: 'index', order: 'ASC' },
                    pagination: { page: 1, perPage: 1000 },
                    filter: { stage: destination.droppableId },
                }),
            ]);

            await Promise.all([
                // decrease index on the deals after the source index in the source columns
                /*...sourceDeals
                    .filter(deal => deal.index > sourceDeal.index)
                    .map(deal =>
                        dataProvider.update('requests', {
                            id: deal.id,
                            data: { index: deal.index - 1 },
                            previousData: deal,
                        })
                    ),
                // increase index on the deals after the destination index in the destination columns
                ...destinationDeals
                    .filter(deal =>
                        destinationDeal
                            ? deal.index >= destinationDeal.index
                            : false
                    )
                    .map(deal =>
                        dataProvider.update('requests', {
                            id: deal.id,
                            data: { index: deal.index + 1 },
                            previousData: deal,
                        })
                    ),*/
                // change the dragged deal to take the destination index and column
                dataProvider.update('requests', {
                    id: sourceDeal.id,
                    data: {
                        ...sourceDeal,
                        ap_id: localStorage.getItem('id'),
                        ap_name: localStorage.getItem('first_name') + " " + localStorage.getItem('last_name'),
                        stage: destination.droppableId,
                    },
                    previousData: sourceDeal,
                }),
            ]);

            refetch();
        }
    };

    /*if (isLoading || isFetching) {
        return <Loading />
    }*/
    if (isLoading ) {
        return <Loading />
    }

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Box display="flex">
                {props.stages.map(stage => (
                    <DealColumn
                        stage={stage}
                        dealIds={deals[stage.id] || []}
                        data={data}
                        key={stage.id}
                    />
                ))}
            </Box>
        </DragDropContext>
    );
});
