import * as React from 'react';
import { useState, useEffect, forwardRef, useImperativeHandle, useRef} from 'react';
import {
    useRecordContext,
    useListContext,
    useCreate,
    useUpdate,
    useNotify,
    useGetIdentity,
    Identifier,
    useResourceContext,
    FileInput, FileField,
    Form,
    CreateParams,
    UpdateParams,
    DataProvider,
    fetchUtils,
    CreateBase,
    SaveButton,
    SimpleForm,
    useCreateContext,
    Toolbar,
    TextInput,
    SelectInput,
    required, 
    DateTimeInput,
    ReferenceInput,
    AutocompleteInput,
    useDataProvider,
    useRedirect,
    useRefresh,
} from 'react-admin';
import { Box, TextField, Button, Grid, Typography  } from '@mui/material';
import { types } from './types';
import { StatusSelector } from '../misc/StatusSelector';
import {useFormContext} from "react-hook-form";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

export const NewHistoryComment = ({reference}) => {
    const resource = useResourceContext();
    const record = useRecordContext();
    const { refetch } = useListContext();
    const notify = useNotify();
    const { identity } = useGetIdentity();
    const dataProvider = useDataProvider();
    const redirect = useRedirect();
    const [selectedCommentType, setSelectedCommentType] = useState(types[0].id);
    const [selectedManager, setSelectedManager] = useState(localStorage.getItem('id'));
    const ref = useRef();

    const handleCommentTypeChange = (event) => {
        setSelectedCommentType(event.target.value);
    };

	const handleManagerChange = async(id) => {
		if(id){
			var recordFromDatabase = await dataProvider.getOne('sales', { id });
			setSelectedManager(recordFromDatabase.data);
		}else{
			setSelectedManager({});
		}
	};

    if (!record || !identity) return null;

    return (
        <Box>
            <CreateBase resource="requestsHistory"
                redirect={false}
                mutationMode="optimistic"
                transform={(data) => {
                    if(selectedCommentType === 'task'){
                        return ({
                            ...data,
                            ap_id: localStorage.getItem('id'),
                            ap_name: localStorage.getItem('first_name') + " " + localStorage.getItem('last_name'),
                            request_id: reference === "requests" ? record.id : record.request_id, 
                            request_number: reference === "requests" ? record.number : record.request_number,
                            created_at: new Date(),
                            task_done: false,
                            manager_name: selectedManager.first_name + " " + selectedManager.last_name,
                            contact_name: record.name,
                        });
                    }else{
                        return ({
                            ...data,
                            ap_id: localStorage.getItem('id'),
                            ap_name: localStorage.getItem('first_name') + " " + localStorage.getItem('last_name'),
                            request_id: reference === "requests" ? record.id : record.request_id, 
                            request_number: reference === "requests" ? record.number : record.request_number,
                            created_at: new Date(),
                            contact_name: record.name,
                        });
                    }
                }}
                mutationOptions={{
                    onSuccess: () => {
                        try{
                           refetch();
                           notify(selectedCommentType !== 'task' ? 'Комментарий добавлен' : 'Задача добавлена', { type: 'info', undoable: false });
                           ref.current.resetForm();
                        }catch(err){console.log(err)}
                    }}
                }
            > 
            <Form>
            <HistoryCommentForm 
                selectedCommentType={selectedCommentType} 
                handleCommentTypeChange={handleCommentTypeChange} 
                selectedManager={selectedManager} 
                handleManagerChange={handleManagerChange} 
                ref={ref}
            />
            </Form>
            </CreateBase>
        </Box>
    );
};

const HistoryCommentForm = forwardRef(({ selectedCommentType, handleCommentTypeChange, selectedManager, handleManagerChange }, ref) => {
    const { reset, setValue } = useFormContext();

    useImperativeHandle(ref, () => ({
        resetForm() {
            //reset();
            setValue('comment', '');
            setValue('file', '');
            setValue('date_time', '');
        }
    }));
    
    useEffect(() => {
        handleManagerChange(localStorage.getItem('id'));
    }, []); 

    return (
        <Box>
            <TextInput
                label={selectedCommentType !== 'task' ? "Введите текст комментария" : "Введите текст задачи"}
                fullWidth
                multiline
                source="comment"
                rows={2}
                validate={required()}
            />
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    {selectedCommentType !== 'task' ? 
                        <FileInput sx={{'& .RaFileInput-dropZone':{
                            //height: '2rem'
                        }}} source="file" label="Прикрепите файл (если требуется)" helperText="Не более 100мб" multiple={false} maxSize={100000000}>
                            <FileField source="src" title="title" />
                        </FileInput> :
                        <Box flex="1" flexDirection="column">
                            <DateTimeInput label="Назначить на" source="date_time" validate={required()} />
                            <ReferenceInput source="task_manager_id" reference="sales" filter={{ 'sales_id_for_tasks': localStorage.getItem('id') }}>
                                <AutocompleteInput label="Менеджер" onChange={handleManagerChange}  defaultValue={selectedManager.id} optionText={(choice) => `${choice.first_name} ${choice.last_name}`} fullWidth validate={required()} />
                            </ReferenceInput>
                        </Box>
                    }
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Box flex="1" flexDirection="column">
                        <SelectInput
                            label="Тип"
                            helperText={selectedCommentType === 'task' ? `Здесь можно выбрать "Комментарий"` : `Здесь можно выбрать "Задача"`}
                            source="comment_type"
                            choices={types}
                            defaultValue={types[0].id}
                            validate={required()}
                            fullWidth 
                            onChange={handleCommentTypeChange}
                        />
                        {selectedCommentType === 'task' ? 
                            <ReferenceInput source="task_type" reference="taskTypes" >
                                <SelectInput label="Тип задачи" optionText="name" optionValue="name" fullWidth validate={required()} />
                            </ReferenceInput> 
                            : null 
                        }
                    </Box>
                </Grid>
            </Grid>
            <Box display="flex">
                <SaveButton 
                    label={selectedCommentType !== 'task' ? "Добавить комментарий" : "Добавить задачу"} 
                    icon={false}
                    redirect={false} 
                    submitOnEnter={false}
                />
            </Box>     
        </Box>
    );
});